import Pusher from 'pusher-js/react-native';

class PusherSingleton {
  private static instance: PusherSingleton;
  public pusher: Pusher;

  private constructor() {
    this.pusher = new Pusher('cce07f3a4de11ee272f4', {
      cluster: 'ap3',
      authEndpoint: 'broadcasting/auth',
      auth: {
        headers: {
          Authorization: `Bearer 8|I7hw4uWUpWirMIScedmqOaqNz4YbOmw4Y5CVXGT34aed239d`,
        },
      },
    });
  }

  public static getInstance(): PusherSingleton {
    if (!PusherSingleton.instance) {
      PusherSingleton.instance = new PusherSingleton();
    }
    return PusherSingleton.instance;
  }
}

export default PusherSingleton.getInstance();
