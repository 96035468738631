import { StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 5,
    paddingTop: 15,
  },
  markedPeriod: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: 50,
  },
  arrowButton: {
    padding: 1,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowStyle: {
    height: 18,
    width: 10,
    // resizeMode: 'contain',
    tintColor: COLORS.BLACK,
  },
  arrowButtonVer: {
    padding: 2,
  },
  arrowStyleVer: {
    height: 14,
    width: 13,
    resizeMode: 'contain',
    tintColor: COLORS.BLACK,
  },
  monthButton: {
    fontSize: 20,
    fontWeight: 'bold',
    marginHorizontal: 10,
  },
  monthText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  calendarContainer: {
    flexDirection: 'column',
  },
  calendarRow: {
    flexDirection: 'row',
    gap: 3,
    marginBottom: 3,
  },
  dayButton: {
    flex: 1,
    height: 40,
    padding: 4,
    borderRadius: 1,
    backgroundColor: COLORS.LIGHT_GREY,
  },
  dayText: {
    fontSize: 13,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    color: COLORS.DARKER_GREY,
  },
  dayOfWeekText: {
    fontSize: 12,
  },
  emptyDay: {
    width: 40,
    height: 100,
    margin: 5,
  },
  dayTitle: {
    fontFamily: FONTS.AvenirMedium,
    fontSize: 13,
    textAlign: 'center',
    color: COLORS.BLACK,
  },
  selectWrapper: {
    flexDirection: 'column',
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  currentDate: {
    color: COLORS.BLACK,
    fontWeight: '700',
    fontFamily: FONTS.Arial,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: 2,
    textTransform: 'uppercase',
    paddingHorizontal: 10,
    width: 138,
    textAlign: 'center',
  },
  currentYear: {
    color: COLORS.BLACK,
    fontWeight: '700',
    fontFamily: FONTS.Arial,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: 2,
    textTransform: 'uppercase',
    paddingHorizontal: 10,
    width: 138,
    textAlign: 'center',
  },
});
