import { IEvent } from './../../../models/IEvents';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from '../../../models/IProduct';
import { IDish } from '../../../types/dishes.types';
import { ICamp } from '../../../types/camp.types';

interface CartState {
  extraDishes: IDish[];
  cartNumber: number;
  camp?: ICamp;
  isShow: boolean;
}

const initialState: CartState = {
  extraDishes: [],
  cartNumber: 0,
  isShow: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setInitCampBooking: (state, action: PayloadAction<ICamp>) => {
      state.extraDishes = [];
      state.cartNumber = 1;
      state.camp = action.payload;
    },
    resetCart: (state, action: PayloadAction) => {
      state.extraDishes = [];
      state.cartNumber = 0;
      state.camp = undefined;
    },
    setShowCart: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
    updateDishItems: (state, action: PayloadAction<IDish[]>) => {
      const dishAmount = action.payload.reduce((preAmount, dish) => {
        let amount = Number(dish.amount) ?? 0;
        return preAmount + amount;
      }, 0);

      state.extraDishes = action.payload;
      state.cartNumber = 1 + dishAmount;
    },
    addToCart: (state, action: PayloadAction<IDish>) => {
      // const currentAmout: number = state.itemNum ? state.itemNum : 0;
      // state.items = [...state.items, action.payload];
      // state.itemNum = currentAmout + action.payload.amount;
    },
    updateCart: (state, action: PayloadAction<IDish[]>) => {
      // state.items = action.payload;
      // state.itemNum = action.payload.reduce((previousItem, item) => {
      //   return previousItem + item.amount;
      // }, 0);
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      // const result = state.items.filter((item) => item.id !== action.payload);
      // state.items = result;
      // state.itemNum = result.reduce((previousItem, item) => {
      //   return previousItem + item.amount;
      // }, 0);
    },
  },
});

export const {
  addToCart,
  deleteItem,
  updateCart,
  setInitCampBooking,
  setShowCart,
  updateDishItems,
  resetCart,
} = cartSlice.actions;
export default cartSlice.reducer;
