import { IFormData } from '../../components/FormHelper/FormHelper.types';

export function removeEmptyValues(obj: IFormData): IFormData {
  // Create a new object to hold the filtered key-value pairs
  const cleanedObj: Record<string, any> = {};

  for (const key in obj) {
    // Check if the value is not empty (undefined, null, '', or empty array)
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      // If the value is an array, also check if it's not empty
      if (!Array.isArray(obj[key]) || obj[key].length > 0) {
        cleanedObj[key] = obj[key];
      }
    }
  }

  return cleanedObj;
}
