import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const isSameWeek = (date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean => {
  return date1.isoWeek() === date2.isoWeek() && date1.year() === date2.year();
};

export function convertToAmPm(time: string) {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours);

  const meridiem = hour >= 12 ? 'PM' : 'AM';
  const amPmHour = hour % 12 || 12;
  const amPmTime = `${amPmHour}:${minutes} ${meridiem}`;

  return amPmTime;
}

export function increaseHourByMinutes(time: string, minutes: number) {
  const [hours, originalMinutes] = time.split(':');

  let hour = parseInt(hours);
  let minute = parseInt(originalMinutes);

  // Tính toán tổng số phút mới
  const totalMinutes = hour * 60 + minute + minutes;

  // Tính toán giờ và phút mới
  hour = Math.floor(totalMinutes / 60) % 24;
  minute = totalMinutes % 60;

  // Định dạng lại chuỗi thời gian
  const increasedTime = `${String(hour).padStart(2, '0')}:${String(
    minute
  ).padStart(2, '0')}`;

  return increasedTime;
}

export function parseTime(timeString: string): Date {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12; // Convert PM hours to 24-hour format
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0; // Convert midnight to 0 hours
  }

  const now = new Date();
  now.setHours(hours, minutes, 0, 0); // Set the hours and minutes
  return now;
}

export function generateTimeArray(
  timeRange: { start: string; end: string },
  intervalMinutes: number
): Array<{ label: string; value: string }> {
  const startTime = parseTime(timeRange.start);
  const endTime = parseTime(timeRange.end);
  const timeArray: Array<{ label: string; value: string }> = [];

  let currentTime = startTime;

  while (currentTime <= endTime) {
    const label = currentTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const value = currentTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    timeArray.push({ label: label, value: value });
    currentTime = new Date(currentTime.getTime() + intervalMinutes * 60 * 1000); // Increment by the interval
  }

  return timeArray;
}

export const showLastSendTime = (time: string) => {
  const messageTime = dayjs(time);
  const now = dayjs();

  // Less than 5 minute ago
  if (now.diff(messageTime, 'minute') < 5) {
    return `just sent`;
  }

  // Less than 1 hour ago
  if (now.diff(messageTime, 'hour') < 1) {
    return `${now.diff(messageTime, 'minute')} min ago`;
  }

  // Same day, more than 1 hour ago
  if (messageTime.isToday()) {
    return messageTime.format('HH:mm A');
  }

  // Yesterday
  if (messageTime.isYesterday()) {
    return 'Yesterday';
  }

  // This week, not today or yesterday
  if (isSameWeek(messageTime, now)) {
    return messageTime.format('dddd'); // Shows day of the week (e.g., "Monday")
  }

  // Default: show date in "MM/DD/YYYY" format or any format you prefer
  return messageTime.format('MM/DD/YYYY');
};
