import { Text, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import { Image } from '../components/Image';
import { NavigationService } from '../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';
import { COLORS, FONTS } from '../constants';

const HeaderMobileRight: FC = () => {
  const auth = useSelector((redux: RootState) => redux.authReducer);
  const { cartNumber, isShow } = useSelector(
    (redux: RootState) => redux.cartReducer
  );

  if (!auth.loggedin) return <></>;

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        marginRight: 5,
      }}
    >
      <TouchableOpacity onPress={() => {}}>
        <Image
          source={require('../assets/cart.png')}
          style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            tintColor: COLORS.RED_PRIMARY,
          }}
        />
        <View
          style={{
            position: 'absolute',
            top: -5,
            right: -5,
            width: 20,
            height: 20,
            backgroundColor: 'red',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 12,
              color: COLORS.WHITE,
              textAlign: 'center',
            }}
          >
            {cartNumber}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default HeaderMobileRight;
