import { ScrollView, StyleSheet, Text, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import NoticeItem from './components/NoticeItem';
import { Footer } from '../../components/Footer';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { INotice } from '../../types/notices.types';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import { NoticesApi } from '../../services/api';
import { Alert } from '../../services/alert';
import { Loading } from '../../components';

const Notices: FC = () => {
  const isDesktop = useIsDesktop();
  const [notices, setNotices] = useState<INotice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getNotices = useCallback(async () => {
    setIsLoading(true);
    NoticesApi.getNotices()
      .then((res) => {
        setNotices(res.data.data);
      })
      .catch((error) => {
        Alert.alert('Error', error.meta.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getNotices();
  }, [getNotices]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Notices" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 30,
                }}
              >
                {isLoading === false && notices.length > 0 && (
                  <View style={{ flexDirection: 'column', gap: 30 }}>
                    {notices.map((item, index) => {
                      return (
                        <View>
                          <NoticeItem item={item} />
                        </View>
                      );
                    })}
                  </View>
                )}
                {isLoading === false && notices.length === 0 && (
                  <View style={styles.noDataWrapper}>
                    <Text style={[styles.noDataText, { fontSize: 20 }]}>
                      THERE ARE CURRENTLY NO NOTICES
                    </Text>
                  </View>
                )}
                {isLoading && <Loading />}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'NOTICES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 40,
          paddingVertical: 40,
          backgroundColor: '#F9F1F1',
        }}
      >
        {isLoading === false && notices.length > 0 && (
          <View style={{ flexDirection: 'column', gap: 30 }}>
            {notices.map((item, index) => {
              return (
                <View>
                  <NoticeItem item={item} />
                </View>
              );
            })}
          </View>
        )}
        {isLoading === false && notices.length === 0 && (
          <View style={styles.noDataWrapper}>
            <Text style={[styles.noDataText, { fontSize: 20 }]}>
              THERE ARE CURRENTLY NO NOTICES
            </Text>
          </View>
        )}
        {true && <Loading />}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default Notices;

const styles = StyleSheet.create({
  noDataWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  noDataText: {
    color: COLORS.BLACK,
    textTransform: 'uppercase',
    fontFamily: FONTS.AvenirRegular,
  },
});
