import { FC, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ImageStyle,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { TextInput } from '../TextInput/TextInput';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { STYLE_VARS } from '../../constants/style-vars';
import { IS_IOS, IS_WEB } from '../../constants/platform';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import { close_icon } from '../../assets/base64';
import phoneCode from '../../phone-code.json';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';

export type IPhoneCode = {
  name: string;
  dial_code: string;
  emoji: string;
  code: string;
};

export interface MobileInputProps extends ViewProps {
  placeholder?: string;
  title?: string;
  textStyle?: TextStyle;
  dropdownStyle?: ViewStyle;
  dropdownTextStyle?: TextStyle;
  zIndex?: number;
  dropDownContainerStyled?: ViewStyle;
  dropDownContainerTextStyle?: TextStyle;
  setValues?: (value: string) => void;
  textInputStyle?: TextStyle;
  initValue?: string;
  buttonStyle?: ImageStyle;
}

const MobileInput: FC<MobileInputProps> = ({
  setValues,
  textInputStyle,
  initValue,
  dropdownStyle,
  dropdownTextStyle,
  buttonStyle,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<IPhoneCode>({
    name: 'Hong Kong',
    dial_code: '+852',
    emoji: '🇭🇰',
    code: 'HK',
  });
  const [phone, setPhone] = useState<string>('');

  const isDesktop = useIsDesktop();

  const renderDropDown = useMemo(() => {
    return (
      <TouchableOpacity
        style={[
          styles.dropdown,
          {
            borderColor: '#DDDDE3',
            width: 95,
            borderRadius: 7,
            marginRight: 5,
            paddingLeft: 10,
            paddingTop: 12,
            paddingBottom: 12,
          },
          dropdownStyle,
        ]}
        onPress={() => {
          setOpen(!open);
        }}
      >
        <View>
          <Text
            style={[
              styles.textStyled,
              {
                color: COLORS.GREY,
                fontSize: 16,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
                marginRight: 5,
              },
              dropdownTextStyle,
            ]}
          >
            {value?.dial_code || ' '}
          </Text>
        </View>
        <Image
          source={
            open == true
              ? require('../../assets/up-button.png')
              : require('../../assets/down-button.png')
          }
          style={[
            {
              width: 15,
              height: 8,
              tintColor: COLORS.BLACK,
            },
            buttonStyle,
          ]}
        />
      </TouchableOpacity>
    );
  }, [open, value, dropdownStyle, dropdownTextStyle, buttonStyle]);

  const renderPicker = useMemo(() => {
    if (isDesktop()) {
      if (open) {
        return (
          <View style={[styles.dropDownContainer]}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              {phoneCode.map((item) => (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={item.code}
                  onPress={() => {
                    setValue(item);
                    setOpen(false);
                    if (setValues && phone.length != 0) {
                      setValues(item.code + ',' + phone);
                    }
                  }}
                >
                  <Text style={[styles.textStyled]}>
                    <Text style={{ marginRight: 5 }}>{item.emoji}</Text>

                    <Text style={{ marginRight: 5 }}>{item.name}</Text>
                    <Text style={{ color: COLORS.GREY }}>{item.dial_code}</Text>
                  </Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        );
      }
    } else {
      return (
        <Modal animationType="slide" visible={open}>
          <View
            style={[styles.headerModalWrapper, IS_IOS && { paddingTop: 60 }]}
          >
            <Text style={[styles.textStyled, { fontSize: 20 }]}>
              {'Select Country'}
            </Text>
            <TouchableOpacity
              onPress={() => {
                setOpen(false);
              }}
            >
              <Image
                source={
                  IS_WEB
                    ? addBase64Prefix(close_icon)
                    : require('../../assets/close-icon.png')
                }
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.RED_PRIMARY,
                }}
              />
            </TouchableOpacity>
          </View>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          >
            {phoneCode.map((item) => (
              <TouchableOpacity
                style={styles.itemWrapper}
                key={item.code}
                onPress={() => {
                  setValue(item);
                  setOpen(false);
                  if (setValues && phone.length != 0) {
                    setValues(item.code + ',' + phone);
                  }
                }}
              >
                <Text style={[styles.textStyled]}>
                  <Text style={{ marginRight: 5 }}>{item.emoji}</Text>

                  <Text style={{ marginRight: 5 }}>{item.name}</Text>
                  <Text style={{ color: COLORS.GREY }}>{item.dial_code}</Text>
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Modal>
      );
    }
  }, [open, isDesktop, setValues, phone]);

  useEffect(() => {
    if (initValue) {
      let arrayValue = initValue.split(',');

      if (arrayValue[1] != '') {
        var result = phoneCode.find((obj) => {
          return obj.code === arrayValue[0];
        });

        if (result) {
          setValue(result);
          setPhone(arrayValue[1]);
        }
      }
    }
  }, [initValue]);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renderDropDown}
      <View style={{ flex: 1 }}>
        <TextInput
          style={[
            (isDesktop()
              ? STYLE_VARS.textinputweb
              : STYLE_VARS.textinput) as TextStyle,
            {
              width: isDesktop() ? 300 : '100%',
              marginBottom: 0,
              backgroundColor: COLORS.WHITE,
            },
            textInputStyle,
          ]}
          placeholder={''}
          placeholderTextColor={COLORS.GREY}
          value={phone}
          onChangeText={(text) => {
            let phoneNumber = text.replace(/[^0-9]/g, '');
            setPhone(phoneNumber);
            if (setValues) {
              if (phoneNumber.length == 0) {
                setValues('');
              } else {
                setValues(value.code + ',' + phoneNumber);
              }
            }
          }}
        />
      </View>
      {renderPicker}
    </View>
  );
};

export default MobileInput;

export const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: 'white',
    flexDirection: 'row',
    borderColor: COLORS.RED_PRIMARY,
    borderWidth: 1,
    borderRadius: 0,
    paddingTop: 12,
    paddingBottom: 12,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  textStyled: {
    color: COLORS.BLACK,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 14,
    fontWeight: '500',
  },
  dropDownContainer: {
    flex: 1,
    position: 'absolute',
    top: 50,
    maxHeight: 150,
    backgroundColor: 'white',
    minHeight: 50,
    width: '100%',
    borderWidth: 1,
    borderColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  headerModalWrapper: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginBottom: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemWrapper: {
    paddingVertical: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 0.5,
    flexDirection: 'row',
  },
});
