import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { BaseApiResponse } from '../../types/api.types';
import instance from './axios';

const ENDPOINTS = {
  CONTACTS: '/contacts',
};

const getContactUs = () => {
  return instance.get<BaseApiResponse>(ENDPOINTS.CONTACTS + '/create');
};

const postContactUs = (data: IFormData) => {
  return instance.post<BaseApiResponse>(ENDPOINTS.CONTACTS, data);
};

const ContactUsApi = {
  getContactUs,
  postContactUs,
};

export default ContactUsApi;
