import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { IBookingState } from '../types';
import { CampPriceData, ICamp } from '../../../types/camp.types';
import dayjs from 'dayjs';

type RoomInforProps = {
  state: IBookingState;
  booking: ICamp;
};
interface ITableItem {
  product: string;
  desc: string;
}

const RoomInfor: FC<RoomInforProps> = ({ state, booking }) => {
  const isDesktop = useIsDesktop();

  const renderTableItemRow = useCallback((item: ITableItem) => {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 15 }}>
        <View style={{ width: '45%' }}>
          <Text style={styles.tableContentText}>{item.product.toString()}</Text>
        </View>
        <View style={{ width: '55%' }}>
          <Text style={styles.tableContentText}>{item.desc.toString()}</Text>
        </View>
      </View>
    );
  }, []);

  return (
    <View
      style={{
        backgroundColor: '#f9f2f1',
        padding: 16,
        borderRadius: 18,
        flexDirection: 'column',
        marginBottom: 25,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
          borderBottomWidth: 1,
          marginBottom: 8,
        }}
      >
        <View style={{ width: '45%' }}>
          <Text style={styles.tableHeaderText}>Room</Text>
        </View>
        <View style={{ width: '55%' }}>
          <Text style={styles.tableHeaderText}>Description</Text>
        </View>
      </View>
      <View>
        {state.rooms.map((room) => {
          const roomFind = booking.rooms.find(
            (item) => item.id === room.room_id
          );

          if (roomFind) {
            return (
              <>
                {renderTableItemRow({
                  product: roomFind.name.en,
                  desc: `No. Of Room: ${room.quantity}${
                    room.is_additional_bed ? '\nAddtional bed' : ''
                  }`,
                })}
              </>
            );
          }
        })}
      </View>
    </View>
  );
};

export default RoomInfor;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.BLACK,
  },
  tableContentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
