import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { RootNavigator } from './navigation';
import { persistor, store } from './services/redux';
import { PersistGate } from 'redux-persist/integration/react';
import NotificationProvider from './services/notification/NotificationProvider';
import { StripeProvider } from '@stripe/stripe-react-native';

const HeightContext = React.createContext<{
  height?: number;
  setHeight: Dispatch<SetStateAction<number | undefined>>;
}>({
  height: undefined,
  setHeight: () => {},
});

export const useAppHeight = () => useContext(HeightContext);

const Height: FC<PropsWithChildren> = ({ children }) => {
  const [height, setHeight] = useState<number>();
  return (
    <HeightContext.Provider value={{ height, setHeight }}>
      {children}
    </HeightContext.Provider>
  );
};

const App: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          {/* Disable on website */}
          {/* <NotificationProvider> */}
          {/* <StripeProvider publishableKey="pk_test_51M3tL9BegDGVD02DD79V7L0hpTMZjIlLPJRSBH9DCbLjwYmBylnr39e5d6qM4eBLUZRh2k7FUuHHUKMzY6kZ18NZ00TDoz3HH3"> */}
          <Height>
            <RootNavigator />
          </Height>
          {/* </StripeProvider> */}
          {/* </NotificationProvider> */}
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
