import { FC } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { NavigationService } from '../../../services/navigation';
import { ICamp } from '../../../types/camp.types';

interface BookingItemProps {
  item: ICamp;
}

const BookingItem: FC<BookingItemProps> = ({ item }) => {
  const isDesktop = useIsDesktop();

  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <TouchableOpacity
        onPress={() => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'BookingDetail',
              params: {
                id: item.id,
              },
            },
          });
        }}
      >
        <Image
          source={
            item.image
              ? { uri: item.image }
              : require('../../../assets/bookings_image/banner-1.png')
          }
          style={[
            {
              width: '100%',
              borderRadius: 18,
            },
            isDesktop()
              ? {
                  height: 200,
                  marginBottom: 10,
                }
              : {
                  height: 175,
                  marginBottom: 15,
                },
          ]}
        />
      </TouchableOpacity>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '800',
          fontSize: 21,
          color: COLORS.BLACK,
          textTransform: 'uppercase',
          letterSpacing: 1.5,
          textAlign: isDesktop() ? 'left' : 'center',
          marginBottom: isDesktop() ? 0 : 10,
        }}
        onPress={() => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'BookingDetail',
              params: {
                id: item.id,
              },
            },
          });
        }}
      >
        {item.name}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          marginBottom: 8,
          justifyContent: isDesktop() ? 'flex-start' : 'center',
        }}
      >
        <View
          style={{
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderRadius: 18,
            backgroundColor: COLORS.LIGHT_GREY,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 12,
              fontWeight: '500',
              color: COLORS.BLACK,
            }}
          >
            {`${item.start_time} ~ ${item.end_time}`}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: isDesktop() ? 'flex-start' : 'center',
        }}
      >
        <View
          style={{
            width: 58,
            height: 2,
            backgroundColor: COLORS.RED_PRIMARY,
            marginBottom: 12,
          }}
        />
      </View>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontSize: 16,
          fontWeight: '500',
          color: COLORS.GREY,
        }}
      >
        {item.description}
      </Text>
    </View>
  );
};

export default BookingItem;
