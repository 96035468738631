import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Alert, StyleSheet, Text, TextStyle, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button';
import DatetimeInfo from './DatetimeInfo';
import ProductTable from './ProductTable';
import { STYLE_VARS } from '../../../constants/style-vars';
import {
  CampPriceData,
  ICamp,
  IPaymentMethod,
} from '../../../types/camp.types';
import { IBookingState } from '../types';
import { IFormData } from '../../../components/FormHelper/FormHelper.types';
import CampApi from '../../../services/api/camp.api';
import { Loading } from '../../../components';
import { convertToFullPhone } from '../../../services/utils/phoneConvert';
import { usePaymentSheet } from '@stripe/stripe-react-native';
import { IS_WEB } from '../../../constants/platform';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import { resetCart } from '../../../services/redux/reducers/CartSlice';

type Step3Props = {
  onSubmit?: () => void;
  booking: ICamp;
  state: IBookingState;
  setState: (state: Partial<IBookingState>) => void;
  handleSetStep: (step: number) => void;
};

const Step3: FC<Step3Props> = ({
  onSubmit,
  booking,
  state,
  setState,
  handleSetStep,
}) => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [priceData, setPriceData] = useState<CampPriceData>({
    booking_price: 0,
    total: 0,
    room_price: 0,
    rooms: [],
    additional_bed_price: 0,
    dishes_price: 0,
    camp_price: 0,
  });
  // const { initPaymentSheet, presentPaymentSheet, loading } = usePaymentSheet();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [isLoadingPM, setIsLoadingPM] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { extraDishes } = useSelector((redux: RootState) => redux.cartReducer);

  const getCalculatePrice = useCallback(async () => {
    setIsLoading(true);
    const formData: IFormData = {
      camp_id: booking.id,
      rooms: state.rooms,
      start_time: `${state.start_date}`,
      end_time: `${state.end_date}`,
      additional_bed: 1,
      adult: state.adult,
      elderly: state.elderly,
      is_vegetarian: state.is_vegetarian ? 1 : 0,
      dinner_meal: {
        name: state.dinner_meal?.name,
      },
      dishes: state.extra_dishes.map((item) => {
        return {
          id: item.item.id,
          quantity: item.amount,
        };
      }),
    };

    if (!state.dinner_meal) {
      delete formData.dinner_meal;
    }

    CampApi.postCalculatePrice(formData)
      .then((res) => {
        setPriceData(res.data.data);
        setState({
          price_data: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    booking,
    setState,
    state.adult,
    state.dinner_meal,
    state.elderly,
    state.end_date,
    state.extra_dishes,
    state.is_vegetarian,
    state.start_date,
    state.rooms,
  ]);

  const fetchPaymentMethods = async () => {
    setIsLoadingPM(true);
    CampApi.getPaymentMethods()
      .then((res) => {
        setPaymentMethods(res.data.data);
      })
      .catch((error) => {
        Alert.alert('Error', error);
      })
      .finally(() => {
        setIsLoadingPM(false);
      });
  };

  const renderEmergencyContact = useMemo(() => {
    return (
      <View
        style={{
          backgroundColor: '#f9f2f1',
          padding: 16,
          borderRadius: 18,
          flexDirection: 'column',
          marginBottom: 25,
        }}
      >
        <Text style={[styles.tableHeaderText, { marginBottom: 17 }]}>
          Emergency Contact
        </Text>
        <View
          style={[
            isDesktop()
              ? { flexDirection: 'row' }
              : { flexDirection: 'column' },
          ]}
        >
          <View
            style={[
              isDesktop()
                ? { width: '30%' }
                : { width: '100%', marginBottom: 15 },
            ]}
          >
            <Text
              style={styles.tableContentText}
            >{`Name: ${state.emergency_first_name} ${state.emergency_last_name}`}</Text>
          </View>
          <View
            style={[
              isDesktop()
                ? { width: '45%' }
                : { width: '100%', marginBottom: 15 },
            ]}
          >
            <Text style={styles.tableContentText}>
              {`Phone Number: ${convertToFullPhone(
                state.emergency_phone_country,
                state.emergency_phone_number
              )}`}
            </Text>
          </View>
          <View style={[isDesktop() ? { width: '25%' } : { width: '100%' }]}>
            <Text
              style={styles.tableContentText}
            >{`Relationship: ${state.emergency_relationship}`}</Text>
          </View>
        </View>
      </View>
    );
  }, [
    isDesktop,
    state.emergency_first_name,
    state.emergency_last_name,
    state.emergency_phone_country,
    state.emergency_phone_number,
    state.emergency_relationship,
  ]);

  useEffect(() => {
    getCalculatePrice();
    fetchPaymentMethods();
  }, [getCalculatePrice]);

  const paymentMethodOptions = useMemo(() => {
    return paymentMethods.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [paymentMethods]);

  const onCreateBooking = useCallback(async () => {
    setIsSubmitting(true);
    const formData: IFormData = {
      camp_id: booking.id,
      payment_method_id: paymentMethod,
      rooms: state.rooms,
      start_time: `${state.start_date}`,
      end_time: `${state.end_date}`,
      adult: state.adult,
      elderly: state.elderly,
      is_vegetarian: state.is_vegetarian ? 1 : 0,
      dinner_meal: {
        name: state.dinner_meal?.name,
      },
      emergency_contact: {
        first_name: state.emergency_first_name,
        last_name: state.emergency_last_name,
        phone: state.emergency_phone_number,
        phone_country: state.emergency_phone_country,
        relationship: state.emergency_relationship,
      },
      dishes: extraDishes.map((item) => {
        return {
          id: item.id,
          quantity: item.amount,
        };
      }),
    };

    const response = await CampApi.postCreateCampBooking(formData);

    if (!response.data.meta.success) {
      Alert.alert('Error', response.data.meta.message);
    }

    setState({
      booking_id: response.data.data.id,
    });

    if (!IS_WEB && response.data.data.payment_intent) {
      // const initRes = await initPaymentSheet({
      //   merchantDisplayName: 'Booking camp',
      //   paymentIntentClientSecret:
      //     response.data.data.payment_intent.client_secret,
      //   customerId: response.data.data.payment_intent.customer,
      //   // returnURL: 'helpinghandapp://stripe-redirect',
      // });
      // if (initRes.error) {
      //   Alert.alert('Error', initRes.error.message);
      // } else {
      //   setIsSubmitting(false);
      //   const presentRes = await presentPaymentSheet();
      //   if (presentRes.error) {
      //     Alert.alert('Error', presentRes.error.message);
      //   } else {
      //     dispatch(resetCart());
      //     if (onSubmit) onSubmit();
      //   }
      // }
    } else {
      dispatch(resetCart());
      if (onSubmit) onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentMethod,
    booking,
    onSubmit,
    setState,
    state,
    extraDishes,
    setIsSubmitting,
    dispatch,
  ]);

  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 25,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      {!isLoading && (
        <>
          <DatetimeInfo state={state} booking={booking} />
          <ProductTable state={state} booking={booking} priceData={priceData} />
          {renderEmergencyContact}
          <View>
            <Text style={[styles.tableHeaderText, { marginBottom: 10 }]}>
              Payment
            </Text>
            <RadioButton
              options={paymentMethodOptions}
              selectedOption={paymentMethod}
              onSelect={(value) => setPaymentMethod(Number(value))}
              optionStyle={{
                marginRight: isDesktop() ? 100 : 0,
                marginBottom: isDesktop() ? 0 : 5,
              }}
              radioStyle={{
                marginBottom: isDesktop() ? 20 : 5,
                flexDirection: isDesktop() ? 'row' : 'column',
                alignItems: isDesktop() ? 'center' : 'flex-start',
              }}
            />
            {/* <View
              style={[
                { marginBottom: 15 },
                isDesktop()
                  ? { flexDirection: 'row', flexWrap: 'wrap', gap: 20 }
                  : { flexDirection: 'column' },
              ]}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  marginBottom: isDesktop() ? 0 : 15,
                }}
              >
                <Text
                  style={[
                    styles.inputText,
                    { width: isDesktop() ? '40%' : '50%' },
                  ]}
                >
                  Name of card:
                </Text>
                <View style={{ width: '50%' }}>
                  <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.inputText,
                    { width: isDesktop() ? '40%' : '50%' },
                  ]}
                >
                  Card Number:
                </Text>
                <View style={{ width: '50%' }}>
                  <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
                </View>
              </View>
            </View>
            <View style={{ marginBottom: 40 }}>
              <View
                style={{
                  flexDirection: 'row',
                  width: isDesktop() ? '50%' : '100%',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text style={[styles.inputText, { width: '30%' }]}>CSV:</Text>
                  <View style={{ width: '40%' }}>
                    <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text style={[styles.inputText, { width: '25%' }]}>EXP:</Text>
                  <View style={{ width: '25%' }}>
                    <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      marginHorizontal: 5,
                      fontWeight: '500',
                      color: COLORS.GREY,
                    }}
                  >
                    {'/'}
                  </Text>
                  <View style={{ width: '25%' }}>
                    <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
                  </View>
                </View>
              </View>
            </View> */}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {isSubmitting ? (
                <View style={{ marginVertical: 20 }}>
                  <Loading />
                </View>
              ) : (
                <Button
                  style={[
                    styles.buttonStyle,
                    !isDesktop() && { width: '100%', paddingVertical: 30 },
                    (isLoading ||
                      isLoadingPM ||
                      paymentMethod === undefined) && {
                      backgroundColor: '#de7e86',
                    },
                  ]}
                  className=""
                  arrowRight={false}
                  textStyle={[
                    styles.textButtonStyle,
                    !isDesktop() && { fontSize: 19 },
                  ]}
                  text="Pay now"
                  onPress={() => {
                    onCreateBooking();
                  }}
                  disabled={
                    isLoading || isLoadingPM || paymentMethod === undefined
                  }
                />
              )}
            </View>
          </View>
        </>
      )}
      {isLoading && (
        <View style={{ marginBottom: 25 }}>
          <Loading />
        </View>
      )}
      {/* {isSubmitting && <Loading isVisible={isSubmitting} isFullScreen />} */}
    </View>
  );
};

export default Step3;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.BLACK,
  },
  tableContentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
  inputText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  input: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    paddingHorizontal: 0,
    letterSpacing: 1.2,
    borderRadius: 4,
    fontSize: 16,
    paddingVertical: 5,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
