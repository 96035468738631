import React, { FC, useCallback, useEffect, useState } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { Text } from '../../../components/Text/Text';
import { COLORS, FONTS } from '../../../constants';
import { INotice } from '../../../types/notices.types';
import { NoticesApi } from '../../../services/api';
import { IS_WEB } from '../../../constants/platform';
import TextTicker from 'react-native-text-ticker';
import { NavigationService } from '../../../services/navigation';
import { TextScroll } from './TextScroll';

export const Notices: FC = () => {
  const [notice, setNotice] = useState<INotice>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getNotice = useCallback(async () => {
    setIsLoading(true);
    NoticesApi.getNotices()
      .then((res) => {
        const notices = res.data.data;
        if (notices && notices.length > 0) {
          setNotice(notices[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getNotice();
  }, [getNotice]);

  return (
    <>
      {!isLoading && notice && (
        <View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <Image
              source={require('../../../assets/notice-icon.png')}
              style={{
                width: 27,
                height: 25,
                marginRight: 10,
                resizeMode: 'contain',
              }}
            />
            {IS_WEB ? (
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 16,
                    color: COLORS.BLACK,
                    flex: 1,
                  }}
                  numberOfLines={1}
                  onPress={() => {
                    NavigationService.push('Root', {
                      screen: 'MenuTab',
                      params: {
                        screen: 'NoticeDetail',
                        params: {
                          id: notice.id,
                        },
                      },
                    });
                  }}
                >
                  {notice.title}
                </Text>
              </ScrollView>
            ) : (
              <>
                {/* Disable on website */}
                {/* <TextTicker
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    color: COLORS.BLACK,
                    flex: 1,
                  }}
                  duration={3000}
                  loop={true}
                  repeatSpacer={50}
                  bounce={false}
                  marqueeDelay={1000}
                  scrollSpeed={50}
                  onPress={() => {
                    NavigationService.push('Root', {
                      screen: 'MenuTab',
                      params: {
                        screen: 'NoticeDetail',
                        params: {
                          id: notice.id,
                        },
                      },
                    });
                  }}
                >
                  {notice.title}
                </TextTicker> */}
              </>
            )}
          </View>
        </View>
      )}
    </>
  );
};
