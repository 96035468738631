import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import MonthSwitcher from '../../../components/MonthSwitcher/MonthSwitcher';
import { IActivities, IActivity } from '../../../types/activities.types';
import dayjs from 'dayjs';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import ActivityApi from '../../../services/api/activity.api';
import { Loading } from '../../../components';
import { NavigationService } from '../../../services/navigation';
import { IS_WEB } from '../../../constants/platform';

const activities: IActivities[] = [
  {
    id: 1,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 2,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 3,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
  {
    id: 4,
    img: '../../../assets/activities-image.png',
    date: '05.11.2024',
    title: 'Bridge & Boccia Competition',
    hour: '10:00 am - 5.00 pm',
    place: 'Tseung Kwan O Sports Center',
  },
];

const ActivityItem: FC<{ index: number; activity: IActivity }> = ({
  index,
  activity,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <View
      style={{
        width: '50%',
        flexDirection: 'row',
        justifyContent: index % 2 == 0 ? 'flex-start' : 'flex-end',
        marginBottom: 40,
      }}
    >
      <View
        style={[
          {
            borderRadius: 10,
            overflow: 'hidden',
          },
          isDesktop()
            ? {
                width: '95%',
                shadowColor: COLORS.BLACK,
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.5,
                shadowRadius: 3,
                elevation: 3,
              }
            : {
                width: '90%',
              },
        ]}
      >
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'ActivitiesDetail',
                  params: {
                    id: activity.id,
                  },
                },
              });
            }}
          >
            <Image
              source={
                activity.image
                  ? {
                      uri: activity.image,
                    }
                  : require('../../../assets/activities-image.png')
              }
              style={[
                {
                  width: '100%',
                },
                isDesktop()
                  ? {
                      height: 250,
                    }
                  : {
                      height: 170,
                      borderRadius: 10,
                    },
              ]}
            />
          </TouchableOpacity>
          <View
            style={{
              padding: 7,
            }}
          >
            {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image
                source={require('../../../assets/heart-icon.png')}
                style={{
                  width: 14,
                  height: 12,
                  marginRight: 5,
                }}
              />
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: isDesktop() ? 16 : 14,
                  color: isDesktop() ? COLORS.GREY : COLORS.BLACK,
                }}
              >
                {dayjs(new Date(activity.start_time)).format('DD.MM.YYYY')}
              </Text>
            </View> */}
            <Text
              style={[
                {
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 19,
                  color: COLORS.BLACK,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                },
                !isDesktop() && {
                  lineHeight: 26,
                  marginBottom: 5,
                },
              ]}
            >
              {activity.name}
            </Text>
            <View
              style={{
                height: 2,
                width: 58,
                backgroundColor: COLORS.SWEET_RED,
                marginBottom: 4,
              }}
            ></View>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: isDesktop() ? 16 : 15,
                color: COLORS.GREY,
              }}
            >
              {`Start: ${dayjs(activity.start_time).format(
                'DD.MM.YYYY HH:mm A'
              )}`}
            </Text>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: isDesktop() ? 16 : 15,
                color: COLORS.GREY,
              }}
            >
              {`End: ${dayjs(activity.end_time).format('DD.MM.YYYY HH:mm A')}`}
            </Text>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: isDesktop() ? 16 : 15,
                color: COLORS.GREY,
              }}
            >
              {activity.venue}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const ActivityTabItem: FC<{
  active: boolean;
  onPress: () => void;
  title: string;
}> = ({ active, onPress, title }) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  if (isDesktop()) {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: '30%',
          padding: 10,
          backgroundColor:
            isHover || active
              ? COLORS.RED_PRIMARY
              : isDesktop()
              ? COLORS.LIGHT_GREY
              : COLORS.WHITE,
          borderRadius: 10,
        }}
        onClick={onPress}
      >
        <View>
          <Text
            style={{
              flex: 1,
              fontFamily: FONTS.AvenirMedium,
              fontSize: isDesktop() ? 19 : 15,
              color:
                isHover || active
                  ? COLORS.WHITE
                  : isDesktop()
                  ? COLORS.GREY
                  : COLORS.BLACK,
              textAlign: 'center',
            }}
          >
            {title}
          </Text>
        </View>
      </div>
    );
  }

  return (
    <TouchableOpacity
      style={{
        width: '28%',
        padding: 9,
        backgroundColor:
          isHover || active
            ? COLORS.RED_PRIMARY
            : isDesktop()
            ? COLORS.LIGHT_GREY
            : COLORS.WHITE,
        borderRadius: 10,
      }}
      onPress={onPress}
    >
      <View>
        <Text
          style={{
            flex: 1,
            fontFamily: FONTS.AvenirMedium,
            fontSize: isDesktop() ? 19 : 14,
            color:
              isHover || active
                ? COLORS.WHITE
                : isDesktop()
                ? COLORS.GREY
                : COLORS.BLACK,
            textAlign: 'center',
          }}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const Activities: FC = () => {
  const [activityTab, setActivityTab] = useState<string>('today');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activityList, setActivityList] = useState<IActivity[]>([]);
  const isDesktop = useIsDesktop();
  const [filterType, setFilterType] = useState<'month' | 'tab'>('tab');
  const [monthDate, setMonthDate] = useState<Date>(new Date());

  const getTodayStartEnd = () => {
    let today = new Date();

    return {
      start: `${dayjs(today).format('YYYY-MM-DD')} 00:00:00`,
      end: `${dayjs(today).format('YYYY-MM-DD')} 23:59:59`,
    };
  };

  const getThisWeekStartEnd = () => {
    let now = new Date();
    const dayOfWeek = now.getDay();

    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return {
      start: `${dayjs(startOfWeek).format('YYYY-MM-DD HH:mm:ss')}`,
      end: `${dayjs(endOfWeek).format('YYYY-MM-DD HH:mm:ss')}`,
    };
  };

  const getMonthStartEnd = useCallback(() => {
    const year = monthDate.getFullYear();
    const month = monthDate.getMonth();

    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);

    return {
      start: `${dayjs(start).format('YYYY-MM-DD')} 00:00:00`,
      end: `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`,
    };
  }, [monthDate]);

  const getActivities = async (start: string, end: string) => {
    setIsLoading(true);
    ActivityApi.getActivities({
      start_time: start,
      end_time: end,
    })
      .then((res) => {
        setActivityList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchActivities = useCallback(
    async (type: 'today' | 'thisWeek' | 'month') => {
      let dateFilter = {
        start: '',
        end: '',
      };

      switch (type) {
        case 'today':
          dateFilter = getTodayStartEnd();
          break;
        case 'thisWeek':
          dateFilter = getThisWeekStartEnd();
          break;
        case 'month':
          dateFilter = getMonthStartEnd();
          break;
        default:
      }
      getActivities(dateFilter.start, dateFilter.end);
    },
    [getMonthStartEnd]
  );

  const fetchActivitiesMonth = async (monthDateObj: Date) => {
    const year = monthDateObj.getFullYear();
    const month = monthDateObj.getMonth();

    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);

    let dateFilter = {
      start: `${dayjs(start).format('YYYY-MM-DD')} 00:00:00`,
      end: `${dayjs(end).format('YYYY-MM-DD')} 23:59:59`,
    };
    getActivities(dateFilter.start, dateFilter.end);
  };

  useEffect(() => {
    fetchActivities('today');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      style={[
        isDesktop()
          ? {}
          : {
              paddingHorizontal: 10,
              backgroundColor: '#F9F1F1',
            },
      ]}
    >
      <View>
        <Text
          style={[
            {
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
              letterSpacing: 1.5,
              marginBottom: 20,
              marginTop: 10,
            },
            isDesktop()
              ? {
                  fontSize: 24,
                }
              : {
                  fontSize: 18,
                  fontWeight: '700',
                },
          ]}
        >
          UPCOMING ACTIVITIES
        </Text>
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 10,
              overflow: 'hidden',
              backgroundColor: '#F5F5F5',
              marginBottom: 30,
            },
            isDesktop()
              ? {
                  backgroundColor: '#F5F5F5',
                }
              : {
                  backgroundColor: COLORS.WHITE,
                },
          ]}
        >
          <ActivityTabItem
            active={activityTab == 'today'}
            onPress={() => {
              setActivityTab('today');
              fetchActivities('today');
            }}
            title="Today"
          />
          <ActivityTabItem
            active={activityTab == 'thisWeek'}
            onPress={() => {
              setActivityTab('thisWeek');
              fetchActivities('thisWeek');
            }}
            title="This Week"
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '44%',
              height: isDesktop() ? 45 : '100%',
              backgroundColor:
                activityTab === ''
                  ? COLORS.RED_PRIMARY
                  : isDesktop()
                  ? COLORS.LIGHT_GREY
                  : COLORS.WHITE,
              borderRadius: 10,
            }}
          >
            <MonthSwitcher
              active={activityTab == ''}
              onChangeDate={(date) => {
                setActivityTab('');
                fetchActivitiesMonth(date);
              }}
            />
          </View>
        </View>

        {isLoading ? (
          <View
            style={{
              paddingVertical: 20,
            }}
          >
            <Loading />
          </View>
        ) : activityList.length > 0 ? (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {activityList.map((activity, index) => (
              <ActivityItem index={index} activity={activity} />
            ))}
          </View>
        ) : (
          <View style={styles.noDataWrapper}>
            <Text style={[styles.noDataText, { fontSize: 18 }]}>
              THERE ARE CURRENTLY NO ACTIVITIES
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  noDataWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
    width: '100%',
  },
  noDataText: {
    color: COLORS.BLACK,
    textTransform: 'uppercase',
    fontFamily: FONTS.AvenirRegular,
    textAlign: 'center',
  },
});
