import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import {
  BackHandler,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { User } from '../Home/components/User';
import { COLORS, FONTS } from '../../constants';
import { Footer } from '../../components/Footer';
import Step1 from './components/Step1';
import StepBar from './components/StepBar';
import Swiper from 'react-native-web-swiper';
import { Button } from '../../components/Button';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import { NavigationService } from '../../services/navigation';
import { BookingDetailScreenProps, IBookingState } from './types';
import { ICamp } from '../../types/camp.types';
import CampApi from '../../services/api/camp.api';
import { Loading } from '../../components';
import ProductTable from './components/ProductTable';
import ConfirmationBooking from './components/ConfirmationBooking';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import {
  resetCart,
  setInitCampBooking,
  setShowCart,
} from '../../services/redux/reducers/CartSlice';
import { useFocusEffect } from '@react-navigation/native';

const initialBookingState: IBookingState = {
  start_date: dayjs(new Date()).format('YYYY-MM-DD'),
  end_date: dayjs(new Date()).format('YYYY-MM-DD'),
  start_time: '',
  end_time: '',
  rooms: [],
  adult: undefined,
  elderly: undefined,
  total_guest: '',
  breakfast: '',
  lunch: '',
  dinner_meal: undefined,
  is_vegetarian: false,
  emergency_first_name: '',
  emergency_last_name: '',
  emergency_phone_number: '',
  emergency_phone_country: '',
  emergency_relationship: '',
  extra_dishes: [],
};

const BookingDetail: FC<BookingDetailScreenProps> = ({ navigation, route }) => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const bookingId = route.params.id;
  const bookingData = route.params.data;
  const [state, setState] = useState(initialBookingState);
  const [step, setStep] = useState<number>(1);
  const [booking, setBooking] = useState<ICamp>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const [bookingStatus, setBookingStatus] = useState<'create' | 'complete'>(
    'create'
  );

  useFocusEffect(
    useCallback(() => {
      // Screen is focused (user is on the screen)
      dispatch(setShowCart(true));

      return () => {
        // Screen is unfocused (user is leaving the screen)
        dispatch(resetCart());
      };
    }, [dispatch])
  );

  const updateState = useCallback(
    (state: Partial<IBookingState>) =>
      setState((prevState) => ({ ...prevState, ...state })),
    [setState]
  );

  const renderSwiper = useMemo(() => {
    const sliders = [
      {
        id: 1,
        img: require('../../assets/banner-home/banner-1.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 2,
        img: require('../../assets/banner-home/banner-2.png'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 3,
        img: require('../../assets/banner-home/banner-3.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
    ];

    return (
      <View style={{ flex: 1, position: 'relative' }}>
        <View>
          <Image
            source={
              booking?.image
                ? {
                    uri: booking.image,
                  }
                : require('../../assets/bookings_image/banner-1.png')
            }
            style={{
              width: '100%',
              height: isDesktop() ? 460 : 240,
              resizeMode: 'cover',
            }}
          />
        </View>
        <Text
          style={[
            {
              color: COLORS.WHITE,
              textTransform: 'uppercase',
              fontWeight: '700',
              fontFamily: FONTS.Arial,
              textAlign: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
              letterSpacing: 1.5,
              top: 15,
              paddingHorizontal: 30,
            },
            isDesktop()
              ? {
                  fontSize: 30,
                }
              : {
                  fontSize: 20,
                },
          ]}
        >
          {booking?.name}
        </Text>
        <Text
          style={[
            {
              color: COLORS.WHITE,
              fontFamily: FONTS.AvenirMedium,
              textAlign: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
            },
            isDesktop()
              ? {
                  top: 100,
                  fontSize: 24,
                  fontWeight: '800',
                }
              : {
                  top: 80,
                  fontSize: 16,
                  fontWeight: '500',
                },
          ]}
        >
          {booking?.description}
        </Text>
        {isDesktop() && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'absolute',
              top: 300,
              left: 0,
              right: 0,
            }}
          >
            <Button
              style={{
                backgroundColor: COLORS.RED_PRIMARY,
                paddingVertical: 15,
                borderRadius: 13,
                width: 270,
                marginBottom: 20,
                overflow: 'hidden',
              }}
              className=""
              textStyle={{
                color: COLORS.WHITE,
                fontSize: 19,
                fontWeight: '800',
                fontFamily: FONTS.AvenirMedium,
                textTransform: 'uppercase',
              }}
              text="Book Now"
            />
          </View>
        )}
      </View>
    );
  }, [isDesktop, booking]);

  const handleSetStep = useCallback((step: number) => {
    setStep(step);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: 0,
      });
    }
  }, []);

  const getCamp = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true);
        const res = await CampApi.getCamp(id);
        setBooking(res.data.data);
        dispatch(setInitCampBooking(res.data.data));
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getCamp(bookingId);
  }, [bookingId, getCamp]);

  useEffect(() => {
    if (bookingData) {
      updateState({
        start_date: bookingData.checkin,
        end_date: bookingData.checkout,
        adult: bookingData.adult,
        elderly: bookingData.elderly,
        is_redirect_from_home: true,
      });
    }
  }, [bookingData, updateState]);

  useEffect(() => {
    const handleBackPress = () => {
      if (step === 1) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        }
      } else {
        handleSetStep(step - 1);
      }
      return true;
    };

    BackHandler.addEventListener('hardwareBackPress', handleBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
    };
  }, [step, handleSetStep, navigation]);

  if (isDesktop()) {
    return (
      <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
              <User />
            </View>
            <View style={{ width: '70%' }}>
              {isLoading == false && booking ? (
                <>
                  {bookingStatus === 'create' && booking && (
                    <View>
                      <Text
                        style={{
                          textTransform: 'uppercase',
                          fontSize: 26,
                          fontFamily: FONTS.Arial,
                          fontWeight: '700',
                          letterSpacing: 3,
                          marginBottom: 30,
                        }}
                      >
                        {booking.name}
                      </Text>
                      <StepBar step={step} setStep={setStep} />
                      {step === 1 && (
                        <Step1
                          onSubmit={() => {
                            handleSetStep(2);
                          }}
                          booking={booking}
                          state={state}
                          setState={updateState}
                        />
                      )}
                      {step === 2 && (
                        <Step2
                          onSubmit={() => {
                            handleSetStep(3);
                          }}
                          booking={booking}
                          state={state}
                          setState={updateState}
                          handleSetStep={handleSetStep}
                        />
                      )}
                      {step === 3 && (
                        <Step3
                          onSubmit={() => {
                            setBookingStatus('complete');
                            if (scrollViewRef.current) {
                              scrollViewRef.current.scrollTo({
                                y: 0,
                                animated: true,
                              });
                            }
                          }}
                          booking={booking}
                          state={state}
                          setState={updateState}
                          handleSetStep={handleSetStep}
                        />
                      )}
                    </View>
                  )}
                  {bookingStatus === 'complete' && booking && (
                    <ConfirmationBooking booking={booking} state={state} />
                  )}
                </>
              ) : (
                isLoading && (
                  <View>
                    <Loading />
                  </View>
                )
              )}
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
      {bookingStatus === 'create' && (
        <>
          {isLoading == false && booking ? (
            <View>
              <View
                style={{
                  paddingHorizontal: 20,
                  paddingTop: 20,
                  backgroundColor: '#fdfdfd',
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 20,
                    color: COLORS.BLACK,
                    textTransform: 'uppercase',
                    letterSpacing: 1,
                    lineHeight: 23,
                    marginBottom: 20,
                  }}
                >
                  {`${booking?.name} Booking`}
                </Text>
                <StepBar step={step} setStep={setStep} />
              </View>
              <View style={{ width: '100%', height: 240 }}>{renderSwiper}</View>
              <View
                style={{
                  paddingHorizontal: 20,
                  paddingTop: 20,
                  backgroundColor: COLORS.LIGHT_LIGHT_GREY,
                  paddingBottom: 20,
                }}
              >
                {step === 1 && (
                  <Step1
                    onSubmit={() => {
                      handleSetStep(2);
                    }}
                    booking={booking}
                    state={state}
                    setState={updateState}
                  />
                )}
                {step === 2 && (
                  <Step2
                    onSubmit={() => {
                      handleSetStep(3);
                    }}
                    booking={booking}
                    state={state}
                    setState={updateState}
                    scrollRef={scrollViewRef}
                    handleSetStep={handleSetStep}
                  />
                )}
                {step === 3 && (
                  <Step3
                    onSubmit={() => {
                      setBookingStatus('complete');
                      if (scrollViewRef.current) {
                        scrollViewRef.current.scrollTo({
                          y: 0,
                          animated: true,
                        });
                      }
                    }}
                    booking={booking}
                    state={state}
                    setState={updateState}
                    handleSetStep={handleSetStep}
                  />
                )}
              </View>
            </View>
          ) : (
            isLoading && (
              <View>
                <Loading />
              </View>
            )
          )}
        </>
      )}
      {bookingStatus === 'complete' && booking && (
        <ConfirmationBooking booking={booking} state={state} />
      )}
      <Footer />
    </ScrollView>
  );
};

export default BookingDetail;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
  headerText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    letterSpacing: 1,
    marginBottom: 15,
  },
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
});
