import React, { FC } from 'react';
import { useIsDesktop } from '../hooks/useIsDeskTop';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RootStackParamList, RootTabParamList } from './types';
import { STYLE_VARS } from '../constants/style-vars';
import { Image } from '../components/Image';
import { MyBookings, MyAccount, Home, BookingList } from '../screens';
import { IS_WEB } from '../constants/platform';
import { addBase64Prefix } from '../services/addBase64Prefix';
import { orders_white, account_white } from '../assets/base64';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import MenuStack from './menu-stack';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';
import { COLORS, FONTS } from '../constants';
import Messages from '../screens/Messages/Messages';
import MyActivities from '../screens/MyActivities/MyActivities';

const BottomTab = createBottomTabNavigator<RootTabParamList>();

interface TabItemProps {
  props: {
    focused: boolean;
    color: string;
    size: number;
  };
  icon: any;
}

interface CustomTabItemProps {
  icon: any;
  onPress?: () => void;
}

const TabItem: FC<TabItemProps> = ({ props, icon }) => {
  return (
    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Image
        source={icon}
        style={{
          width: 25,
          height: 25,
          resizeMode: 'contain',
          tintColor: COLORS.WHITE,
        }}
      />
    </View>
  );
};

const CenterTabItem: FC<CustomTabItemProps> = ({ onPress }) => {
  return (
    <View>
      <View
        style={{
          backgroundColor: COLORS.WHITE,
          padding: 20,
          borderRadius: 35,
          shadowOffset: { width: 0, height: -4 },
          shadowOpacity: 0.6,
          shadowRadius: 6,
          shadowColor: '#000000',
          elevation: 3,
          marginTop: -25,
          width: 70,
          height: 70,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          source={require('../assets/events.png')}
          style={{
            width: 35,
            height: 35,
            resizeMode: 'contain',
            tintColor: COLORS.RED_PRIMARY,
          }}
        />
      </View>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '500',
          fontSize: 10,
          color: COLORS.WHITE,
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {'Make booking'}
      </Text>
    </View>
  );
};

type BottomTabsNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  'Root'
>;

export interface MyBookingsProps {
  navigation: BottomTabsNavigationProp;
}

const BottomTabs: FC<MyBookingsProps> = ({ navigation }) => {
  const isDesktop = useIsDesktop();
  const auth = useSelector((redux: RootState) => redux.authReducer);

  return (
    <BottomTab.Navigator
      sceneContainerStyle={[
        {
          backgroundColor: 'white',
        },
      ]}
      screenOptions={{
        tabBarStyle: {
          backgroundColor: COLORS.RED_PRIMARY,
          display: isDesktop() || auth.loggedin === false ? 'none' : 'flex',
          paddingBottom: 10,
          shadowOffset: { width: 0, height: -4 },
          shadowOpacity: 0.6,
          shadowRadius: 6,
          shadowColor: '#000000',
          elevation: 3,
          height: 70,
        },
      }}
      initialRouteName="HomeTab"
    >
      {auth.loggedin === true && (
        <>
          <BottomTab.Screen
            name="Messages"
            component={Messages}
            options={{
              tabBarInactiveTintColor: 'white',
              tabBarActiveTintColor: 'white',
              tabBarLabel: 'MESSAGES',
              tabBarLabelStyle: {
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 10,
                color: COLORS.WHITE,
                textAlign: 'center',
              },
              headerShown: false,
              tabBarIcon: (props) => (
                <TabItem
                  props={props}
                  icon={require('../assets/notice-icon.png')}
                />
              ),
            }}
          />
          <BottomTab.Screen
            name="MyActivities"
            component={MyActivities}
            options={{
              tabBarInactiveTintColor: 'white',
              tabBarActiveTintColor: 'white',
              tabBarLabel: 'ACTIVITIES',
              tabBarLabelStyle: {
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 10,
                color: COLORS.WHITE,
                textAlign: 'center',
              },
              headerShown: false,
              tabBarIcon: (props) => (
                <TabItem
                  props={props}
                  icon={require('../assets/activities-icon.png')}
                />
              ),
            }}
          />
          <BottomTab.Screen
            name="HomeTab"
            component={Home}
            options={{
              tabBarInactiveTintColor: 'white',
              tabBarActiveTintColor: 'white',
              tabBarLabel: '',
              headerShown: false,
              tabBarIconStyle: {
                width: 70,
              },
              tabBarIcon: (props) => (
                <CenterTabItem icon={require('../assets/events.png')} />
              ),
            }}
          />
          <BottomTab.Screen
            name="MyAccountTab"
            component={MyAccount}
            options={{
              tabBarInactiveTintColor: 'white',
              tabBarActiveTintColor: 'white',
              tabBarLabel: 'MY ACCOUNT',
              tabBarLabelStyle: {
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 10,
                color: COLORS.WHITE,
                textAlign: 'center',
              },
              headerShown: false,
              tabBarIcon: (props) => (
                <TabItem
                  props={props}
                  icon={require('../assets/account-icon.png')}
                />
              ),
            }}
          />
        </>
      )}

      <BottomTab.Screen
        name="MenuTab"
        component={MenuStack}
        listeners={{
          tabPress: () => {
            navigation.navigate('Root', {
              screen: 'MenuTab',
              params: {
                screen: 'Menu',
              },
            });
          },
        }}
        options={{
          tabBarInactiveTintColor: 'white',
          tabBarActiveTintColor: 'white',
          tabBarLabel: 'MENU',
          tabBarLabelStyle: styles.tabBarLabel,
          headerShown: false,
          tabBarIcon: (props) => (
            <TabItem props={props} icon={require('../assets/menu-icon.png')} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
};

export default BottomTabs;

const styles = StyleSheet.create({
  tabBarLabel: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 10,
    color: COLORS.WHITE,
    textAlign: 'center',
  },
});
