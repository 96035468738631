import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { User } from '../Home/components/User';
import Swiper from 'react-native-web-swiper';
import { Button } from '../../components/Button';
import { STYLE_VARS } from '../../constants/style-vars';
import { COLORS, FONTS } from '../../constants';
import BookingItem from './components/BookingItem';
import { Footer } from '../../components/Footer';
import CampApi from '../../services/api/camp.api';
import { ICamp } from '../../types/camp.types';
import { Loading } from '../../components';
import { BookingListProps } from './types';

const BookingList: FC<BookingListProps> = ({ navigation }) => {
  const isDesktop = useIsDesktop();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [camps, setCamps] = useState<ICamp[]>([]);
  const getCamps = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await CampApi.getCamps();

      setCamps(res.data.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const renderSwiper = useMemo(() => {
    const sliders = [
      {
        id: 1,
        img: require('../../assets/banner-home/banner-1.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 2,
        img: require('../../assets/banner-home/banner-2.png'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 3,
        img: require('../../assets/banner-home/banner-3.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
    ];

    return (
      <Swiper
        controlsProps={{
          prevPos: false,
          nextPos: false,
          DotComponent: ({ index, isActive, onPress }) => (
            <>
              {isDesktop() && (
                <View style={{ marginHorizontal: 2 }}>
                  <View
                    style={[
                      {
                        height: 10,
                        borderRadius: 10,
                      },
                      isActive
                        ? {
                            width: 18,
                            backgroundColor: '#5E5D5D',
                          }
                        : {
                            width: 10,

                            backgroundColor: '#C9C9C9',
                          },
                    ]}
                  ></View>
                </View>
              )}
            </>
          ),
          dotsTouchable: true,
        }}
        timeout={5}
        loop
      >
        {sliders.map((item) => (
          <View key={item.id} style={{ flex: 1, position: 'relative' }}>
            <View>
              <Image
                source={item.img}
                style={{
                  width: '100%',
                  height: isDesktop() ? 460 : 240,
                  resizeMode: 'cover',
                }}
              />
            </View>
            <Text
              style={[
                {
                  color: COLORS.WHITE,
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontFamily: FONTS.Arial,
                  textAlign: 'center',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  letterSpacing: 1.5,
                  top: 15,
                },
                isDesktop()
                  ? {
                      fontSize: 30,
                    }
                  : {
                      fontSize: 20,
                    },
              ]}
            >
              {item.title}
            </Text>
            <Text
              style={[
                {
                  color: COLORS.WHITE,

                  fontFamily: FONTS.AvenirMedium,
                  textAlign: 'center',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                },
                isDesktop()
                  ? {
                      top: 100,
                      fontSize: 24,
                      fontWeight: '800',
                    }
                  : {
                      top: 60,
                      fontSize: 16,
                      fontWeight: '500',
                    },
              ]}
            >
              {item.desc}
            </Text>
            {isDesktop() && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 300,
                  left: 0,
                  right: 0,
                }}
              >
                <Button
                  style={{
                    backgroundColor: COLORS.RED_PRIMARY,
                    paddingVertical: 15,
                    borderRadius: 13,
                    width: 270,
                    marginBottom: 20,
                    overflow: 'hidden',
                  }}
                  className=""
                  textStyle={{
                    color: COLORS.WHITE,
                    fontSize: 19,
                    fontWeight: '800',
                    fontFamily: FONTS.AvenirMedium,
                    textTransform: 'uppercase',
                  }}
                  text="Book Now"
                />
              </View>
            )}
          </View>
        ))}
      </Swiper>
    );
  }, [isDesktop]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getCamps();
    });

    return unsubscribe;
  }, [getCamps, navigation]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
              <User />
            </View>
            <View style={{ width: '70%' }}>
              <View style={{ width: '100%', height: 500 }}>{renderSwiper}</View>
              <Text
                style={{
                  fontFamily: FONTS.Arial,
                  fontSize: 26,
                  fontWeight: '700',
                  color: COLORS.BLACK,
                  letterSpacing: 2,
                  textTransform: 'uppercase',
                  marginBottom: 40,
                }}
              >
                Make A Booking
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  gap: 20,
                  paddingBottom: 50,
                }}
              >
                {isLoading && (
                  <View
                    style={{
                      width: '100%',
                    }}
                  >
                    <Loading />
                  </View>
                )}
                {camps &&
                  camps.map((item) => (
                    <View
                      key={item.id}
                      style={{
                        flex: 0.5,
                        minWidth: 300,
                      }}
                    >
                      <BookingItem item={item} />
                    </View>
                  ))}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={{ width: '100%', height: 240, marginBottom: 15 }}>
        {renderSwiper}
      </View>
      <View
        style={{
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: COLORS.BLACK,
            marginBottom: 20,
          }}
        >
          Make A Booking
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            gap: 30,
            paddingBottom: 50,
            justifyContent: 'space-between',
          }}
        >
          {isLoading && (
            <View
              style={{
                width: '100%',
              }}
            >
              <Loading />
            </View>
          )}
          {!isLoading &&
            camps.length > 0 &&
            camps.map((item) => (
              <View
                key={item.id}
                style={{
                  width: '45%',
                }}
              >
                <BookingItem item={item} />
              </View>
            ))}
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default BookingList;
