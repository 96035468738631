import { FC, useMemo } from 'react';
import { IRoom, IRoomInput } from '../../../types/camp.types';
import { Image, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import DropDown from '../../../components/DropDown';
import { DropDownItem } from '../../../models/DropDownItem';
import RadioButton from '../../../components/RadioButton/RadioButton';
import DropDownSelect from '../../../components/DropDownSelect';

type RoomInputProps = {
  room: IRoom;
  inputData?: IRoomInput;
  onChangeInput: (
    roomId: number,
    name: 'quantity' | 'is_additional_bed',
    value: any
  ) => void;
  zIndex: number;
};

export const RoomInput: FC<RoomInputProps> = ({
  room,
  inputData,
  onChangeInput,
  zIndex,
}) => {
  const isDesktop = useIsDesktop();

  const roomItems = useMemo(() => {
    const arr: DropDownItem[] = [
      {
        label: 'No Room',
        value: 0,
      },
    ];
    for (let i = 1; i <= 100; i++) {
      arr.push({
        label: i + ' ' + 'Room',
        value: i,
      });
    }
    return arr;
  }, []);

  return (
    <View
      style={{
        flexDirection: 'column',
        zIndex: zIndex,
      }}
      key={room.id}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: isDesktop() ? 'flex-start' : 'center',
        }}
      >
        <View
          style={{
            marginBottom: 6,
          }}
        >
          {room.name.capacity === 4 ? (
            <Image
              source={require('../../../assets/four-person.png')}
              style={{
                width: 72,
                height: 38,
                resizeMode: 'contain',
              }}
            />
          ) : (
            <Image
              source={require('../../../assets/two-person.png')}
              style={{
                width: 72,
                height: 38,
                resizeMode: 'contain',
              }}
            />
          )}
        </View>
        <Text
          style={{
            fontSize: 20,
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            color: COLORS.BLACK,
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          {room.name.en}
        </Text>
      </View>
      <View
        style={[
          {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: 10,
            zIndex: 1,
          },
          isDesktop()
            ? {
                width: '50%',
              }
            : {
                width: '100%',
              },
        ]}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontSize: 19,
            color: COLORS.BLACK,
            marginBottom: 10,
          }}
        >
          {'Number of Rooms:'}
        </Text>
        <View
          style={{
            width: '100%',
          }}
        >
          <DropDownSelect
            zIndex={2}
            placeholder={'Select No. Of Room'}
            items={roomItems}
            dropdownStyle={{
              borderColor: '#DDDDE3',
              width: isDesktop() ? 400 : '100%',
              borderRadius: 6,
              padding: 14,
              marginBottom: 5,
            }}
            textStyle={{
              color: COLORS.GREY,
              fontSize: 16,
              fontFamily: FONTS.AvenirMedium,
              letterSpacing: 1,
            }}
            dropDownContainerTextStyle={{
              color: COLORS.DARKER_GREY,
            }}
            buttonStyle={{
              tintColor: COLORS.DARKER_GREY,
            }}
            setValues={(value) => {
              onChangeInput(room.id, 'quantity', value ? Number(value) : '');
            }}
            value={inputData?.quantity}
          />
        </View>
      </View>
      {room.is_additional_bed && (
        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              color: COLORS.BLACK,
              marginBottom: 10,
            }}
          >
            Do you need an additional bed?:
          </Text>
          <RadioButton
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            selectedOption={inputData?.is_additional_bed}
            onSelect={(value) => {
              onChangeInput(room.id, 'is_additional_bed', value);
            }}
            optionStyle={{ marginRight: 100 }}
          />
        </View>
      )}
      <View
        style={{
          width: '90%',
          height: 1,
          backgroundColor: COLORS.LIGHT_GREY,
        }}
      ></View>
    </View>
  );
};
