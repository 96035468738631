import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback } from 'react';
import { PageTitleProps } from './PageTitle.types';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { STYLE_VARS } from '../../constants/style-vars';
import { Image } from '../Image';
import { NavigationService } from '../../services/navigation';
import { SHARE_STYLE } from '../../constants/share-style';
import { COLORS, FONTS } from '../../constants';

const PageTitle: FC<PageTitleProps> = ({
  title,
  icon,
  haveGoBackButton = false,
  handleGoBack,
  style,
  description,
  haveBottomLine = true,
  ...props
}) => {
  const isDesktop = useIsDesktop();

  const handleGoBackButton = useCallback(() => {
    if (handleGoBack) {
      handleGoBack();
    } else {
      NavigationService.pop();
    }
  }, [handleGoBack]);

  if (isDesktop()) {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        {haveGoBackButton && (
          <TouchableOpacity onPress={handleGoBackButton}>
            <Image
              source={require('../../assets/arrow-left.png')}
              style={{
                width: 30,
                height: 30,
                tintColor: '#575757',
                marginRight: 8,
              }}
            />
          </TouchableOpacity>
        )}
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 25,
            textTransform: 'uppercase',
            color: COLORS.BLACK,
            letterSpacing: 1.5,
          }}
        >
          {title}
        </Text>
      </View>
    );
  }
  return (
    <View
      style={{
        backgroundColor: COLORS.WHITE,
        width: '100%',
        flexDirection: 'row',
        paddingVertical: 20,
        paddingHorizontal: 15,
        justifyContent: haveGoBackButton ? 'space-between' : 'center',
        elevation: 4,
        shadowColor: '#0C0C0D1A',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 4,
        alignItems: 'center',
      }}
    >
      {haveGoBackButton && (
        <TouchableOpacity onPress={handleGoBackButton}>
          <Image
            source={require('../../assets/arrow-left.png')}
            style={{
              width: 30,
              height: 30,
              tintColor: COLORS.BLACK,
            }}
          />
        </TouchableOpacity>
      )}
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 20,
          letterSpacing: 1.6,
          color: COLORS.BLACK,
        }}
      >
        {title}
      </Text>
      {haveGoBackButton && <View />}
    </View>
  );
};

export default PageTitle;

const styles = StyleSheet.create({});
