import { BaseApiResponse } from '../../types/api.types';
import instance from './axios';

const ENDPOINTS = {
  NOTICES: '/notices',
  NOTICE_CATEGORIES: '/notice-categories',
};

const getNotices = () => {
  return instance.get<BaseApiResponse>(ENDPOINTS.NOTICES);
};

const getNotice = (id: number) => {
  return instance.get<BaseApiResponse>(ENDPOINTS.NOTICES + '/' + id);
};

const NoticesApi = {
  getNotices,
  getNotice,
};

export default NoticesApi;
