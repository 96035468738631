import { FC, useCallback, useMemo, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import {
  Image,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { TouchableOpacity } from 'react-native';
import { STYLE_VARS } from '../../constants/style-vars';
import PageTitle from '../../components/PageTitle';
import { Footer } from '../../components/Footer';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import { NavigationService } from '../../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux';
import {
  convertToFullPhone,
  searchPhoneCountry,
} from '../../services/utils/phoneConvert';
import dayjs from 'dayjs';

const MyProfile: FC = () => {
  const isDesktop = useIsDesktop();
  const { user } = useSelector((redux: RootState) => redux.authReducer);

  const myProfileData = useMemo(() => {
    return [
      {
        name: 'First Name:',
        value: user?.first_name,
      },
      {
        name: 'Last Name:',
        value: user?.last_name,
      },
      {
        name: 'Email:',
        value: user?.email,
      },
      {
        name: 'Phone:',
        value: convertToFullPhone(user?.phone_country, user?.phone),
      },
      {
        name: 'Mobile Phone:',
        value: convertToFullPhone(user?.mobile_country, user?.mobile_phone),
      },
      {
        name: 'HKID Card No.:',
        value: user?.card_id,
      },
      {
        name: 'Date of Birth:',
        value: user?.birthday
          ? dayjs(new Date(user.birthday)).format('DD/MM/YYYY')
          : '',
      },
      {
        name: 'Disabled:',
        value: user?.is_disabled ? 'Yes' : 'No',
      },
      {
        name: 'Address:',
        value: user?.address,
      },
      {
        name: 'Unit No.:',
        value: user?.unit_no,
      },
      {
        name: 'Floor:',
        value: user?.floor,
      },
      {
        name: 'Building Name and Number:',
        value: user?.building,
      },
      {
        name: 'Street Name and Number:',
        value: user?.street,
      },
      {
        name: 'District:',
        value: user?.district,
      },
      {
        name: 'Region:',
        value: user?.region,
      },
      {
        name: 'Emergency Contact (First Name):',
        value: user?.emergency ? user.emergency.first_name : '',
      },
      {
        name: 'Emergency Contact (Last Name):',
        value: user?.emergency ? user.emergency.last_name : '',
      },
      {
        name: 'Emergency Contact (Phone):',
        value: user?.emergency ? user.emergency.phone : '',
      },
      {
        name: 'Emergency Contact (Email):',
        value: user?.emergency ? user.emergency.email : '',
      },
    ];
  }, [user]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="My Profile" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 25,
                  paddingTop: 25,
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 0.5,
                  shadowRadius: 32,
                  elevation: 24,
                  borderRadius: 18,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <View
                  style={{
                    backgroundColor: COLORS.LIGHT_RED,
                    padding: 25,
                    width: '100%',
                    borderRadius: 18,
                    flexDirection: 'column',
                    marginBottom: 25,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.Arial,
                        fontWeight: '700',
                        fontSize: 18,
                        letterSpacing: 1.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      My Details
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        NavigationService.push('Root', {
                          screen: 'MenuTab',
                          params: {
                            screen: 'EditProfile',
                            params: {
                              name: 'detail',
                            },
                          },
                        });
                      }}
                    >
                      <Image
                        source={require('../../assets/edit-icon.png')}
                        style={{
                          width: 22,
                          height: 22,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: '50%',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ width: '40%' }}>
                        <Text style={styles.contentText}>Username:</Text>
                      </View>
                      <View style={{ width: '60%' }}>
                        <Text style={styles.contentText}>{user?.username}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: '50%',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ width: '40%' }}>
                        <Text style={styles.contentText}>Password:</Text>
                      </View>
                      <View style={{ width: '60%' }}>
                        <Text style={styles.contentText}>************</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: COLORS.LIGHT_RED,
                    padding: 25,
                    width: '100%',
                    borderRadius: 18,
                    flexDirection: 'column',
                    marginBottom: 25,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.Arial,
                        fontWeight: '700',
                        fontSize: 18,
                        letterSpacing: 1.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      My Profile
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        NavigationService.push('Root', {
                          screen: 'MenuTab',
                          params: {
                            screen: 'EditProfile',
                            params: {
                              name: 'profile',
                            },
                          },
                        });
                      }}
                    >
                      <Image
                        source={require('../../assets/edit-icon.png')}
                        style={{
                          width: 22,
                          height: 22,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: 'column' }}>
                    {myProfileData.map((item) => {
                      return (
                        <View
                          key={item.name}
                          style={{ flexDirection: 'row', marginBottom: 15 }}
                        >
                          <Text style={[styles.contentText, { width: '40%' }]}>
                            {item.name}
                          </Text>
                          <Text style={[styles.contentText, { width: '60%' }]}>
                            {item.value}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY PROFILE'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: '#F9F1F1',
        }}
      >
        <View
          style={{
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
            }}
          >
            {`${user?.first_name} ${user?.last_name}`}
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 18,
                letterSpacing: 1.5,
                color: COLORS.BLACK,
              }}
            >
              MY DETAILS
            </Text>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'EditProfile',
                    params: {
                      name: 'detail',
                    },
                  },
                });
              }}
            >
              <Image
                source={require('../../assets/edit-icon.png')}
                style={{
                  width: 22,
                  height: 22,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              <Text style={[styles.contentText, { width: '40%' }]}>
                Username:
              </Text>
              <Text style={[styles.contentText, { width: '60%' }]}>
                {user?.username}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              <Text style={[styles.contentText, { width: '40%' }]}>
                Password:
              </Text>
              <Text style={[styles.contentText, { width: '60%' }]}>
                **********
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 18,
                letterSpacing: 1.5,
                color: COLORS.BLACK,
              }}
            >
              MY PROFILE
            </Text>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'EditProfile',
                    params: {
                      name: 'profile',
                    },
                  },
                });
              }}
            >
              <Image
                source={require('../../assets/edit-icon.png')}
                style={{
                  width: 22,
                  height: 22,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            {myProfileData.map((item) => {
              return (
                <View
                  key={item.name}
                  style={{ flexDirection: 'row', marginBottom: 15 }}
                >
                  <Text style={[styles.contentText, { width: '38%' }]}>
                    {item.name}
                  </Text>
                  <Text style={[styles.contentText, { width: '62%' }]}>
                    {item.value}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MyProfile;

const styles = StyleSheet.create({
  headerText: {
    fontFamily: FONTS.Arial,
    fontWeight: '600',
    fontSize: 19,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    borderBottomWidth: 1,
    paddingBottom: 15,
    marginBottom: 12,
  },
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
