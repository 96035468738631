import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EventsApi, PromotionsApi, NoticesApi } from '../../api';

export const fetchWeather = createAsyncThunk('weather', async (_, thunkAPI) => {
  try {
    const response = await axios.get<[]>(
      'https://api.open-meteo.com/v1/forecast?latitude=22.2793&longitude=114.1628&current_weather=true&hourly=temperature_2m,relativehumidity_2m,windspeed_10m'
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchDate = createAsyncThunk('date', async (_, thunkAPI) => {
  try {
    const response = await axios.get<[]>(
      'https://timeapi.io/api/time/current/zone?timeZone=Asia%2FHong_Kong'
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchNotices = createAsyncThunk(
  'notice/fetchAll',
  async (_, thunkAPI) => {
    // try {
    //   const response = await NoticesApi.getAllNotices();
    //   return response.data;
    // } catch (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
  }
);

export const fetchPromotions = createAsyncThunk(
  'promotion/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await PromotionsApi.getAllPromotions();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchEvents = createAsyncThunk(
  'event/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await EventsApi.getAllEvents();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
