import { FC, useCallback, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { MAX_WIDTH } from '../../constants/platform';
import { NavigationService } from '../../services/navigation';
import { EditProfileProps } from './types';
import MyDetailForm from './components/MyDetailForm';
import { Footer } from '../../components/Footer';
import MyProfileForm from './components/MyProfileForm';
import { AuthApi } from '../../services/api/auth.api';
import { Loading } from '../../components';
import { removeEmptyValues } from '../../services/utils/dataConvert';
import { Alert } from '../../services/alert';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../services/redux/reducers/AuthSlice';
import { convertMobileInputData } from '../../services/utils/phoneConvert';
import { RootState } from '../../services/redux';

const EditProfile: FC<EditProfileProps> = ({ navigation, route }) => {
  const name = route.params.name;
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useSelector((redux: RootState) => redux.authReducer);

  const submitPress = useCallback(
    (data: IFormData) => {
      setIsLoading(true);

      switch (name) {
        case 'detail':
          let submitDetailData: IFormData = removeEmptyValues(data);

          AuthApi.changePassword(submitDetailData)
            .then(async (res) => {
              Alert.alert('Success', res.data.meta.message);
              dispatch(setUserInfo(res.data.data));
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'MyProfile',
                },
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.meta.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case 'profile':
          let submitProfileData: IFormData = removeEmptyValues({
            email: data.email,
            phone: convertMobileInputData(data.phone).number,
            phone_country: convertMobileInputData(data.phone).country,
            mobile_phone: convertMobileInputData(data.mobile).number,
            mobile_country: convertMobileInputData(data.mobile).country,
            birthday: data.birthday,
            is_disabled: data.is_disabled ? 1 : 0,
            address: data.address,
            unit_no: data.unit_no,
            floor: data.floor,
            building: data.building,
            street: data.street,
            district: data.district,
            region: data.region,
            emergency_contact: removeEmptyValues({
              first_name: data.emergency_first_name,
              last_name: data.emergency_last_name,
              phone: convertMobileInputData(data.emergency_phone).number,
              phone_country: convertMobileInputData(data.emergency_phone)
                .country,
              email: data.emergency_email,
            }),
          });

          AuthApi.updateProfile(submitProfileData)
            .then(async (res) => {
              Alert.alert('Success', res.data.meta.message);
              dispatch(setUserInfo(res.data.data));
              NavigationService.push('Root', {
                screen: 'MenuTab',
                params: {
                  screen: 'MyProfile',
                },
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.meta.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        default:
      }
    },
    [dispatch, name]
  );

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Edit My Profile" />
              <View
                style={{
                  paddingHorizontal: 25,
                  paddingTop: 25,
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 0.5,
                  shadowRadius: 32,
                  elevation: 24,
                  borderRadius: 18,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {name === 'detail' && (
                  <MyDetailForm onSubmitHandler={submitPress} />
                )}
                {name === 'profile' && (
                  <MyProfileForm onSubmitHandler={submitPress} />
                )}
              </View>
            </View>
          </View>
        </View>
        <Footer />
        {isLoading && <Loading isFullScreen={true} />}
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'EDIT PROFILE'} haveGoBackButton />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
        }}
      >
        <View
          style={{
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
            }}
          >
            {`${user?.first_name} ${user?.last_name}`}
          </Text>
        </View>
        {name === 'detail' && <MyDetailForm onSubmitHandler={submitPress} />}
        {name === 'profile' && <MyProfileForm onSubmitHandler={submitPress} />}
      </View>
      <Footer />
      {isLoading && <Loading isFullScreen={true} />}
    </ScrollView>
  );
};

export default EditProfile;

const styles = StyleSheet.create({
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
