import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { TextInput } from '../../components/TextInput/TextInput';
import { Button } from '../../components/Button';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';
import ChatRoomApi from '../../services/api/chatroom.api';
import { Alert } from '../../services/alert';
import { IChatRoom } from '../../types/chat.types';
import { MessagesProps } from './types';
import { Loading } from '../../components';
import { showLastSendTime } from '../../services/utils';
import PusherSingleton from '../../services/pusher/PusherSingleton';
import useClickOutside from '../../hooks/useClickOutSide';

const Messages: FC<MessagesProps> = ({ navigation }) => {
  const isDesktop = useIsDesktop();
  const userDropdownRef = useRef<View>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const pusher = PusherSingleton.pusher;
  const [rooms, setRooms] = useState<IChatRoom[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreenLoading, setFullScreenLoading] = useState<boolean>(false);

  const createChatRoom = useCallback(async () => {
    setIsLoading(true);
    ChatRoomApi.postCreateChatRoom()
      .then((res) => {
        setRooms((roomsData) => {
          return [...roomsData, res.data.data];
        });
      })
      .catch((error) => {
        Alert.alert('Error', error.meta.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleCreateSupportStaffChat = useCallback(async () => {
    setFullScreenLoading(true);
    ChatRoomApi.postCreateChatRoom()
      .then((res) => {
        NavigationService.push('MessagesUser', {
          id: res.data.data.id,
        });
      })
      .catch((error) => {
        Alert.alert('Error', error.meta.message);
      })
      .finally(() => {
        setFullScreenLoading(false);
      });
  }, []);

  const getChatRooms = useCallback(async () => {
    setIsLoading(true);
    ChatRoomApi.getChatRoom()
      .then((res) => {
        setRooms(res.data.data.data);
      })
      .catch((error) => {
        Alert.alert('Error', error.meta.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const renderMessagesList = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        {rooms.map((item, index) => {
          return (
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                paddingLeft: 20,
                paddingVertical: 20,
                borderBottomWidth: 1,
                borderBottomColor: '#BCBCBC2E',
                justifyContent: 'space-between',
                paddingRight: 20,
                alignItems: 'center',
              }}
              onPress={() => {
                NavigationService.push('MessagesUser', {
                  id: item.id,
                });
              }}
            >
              <View
                style={{
                  width: isDesktop() ? '50%' : '70%',
                  flexDirection: 'row',
                  gap: 15,
                  alignItems: 'center',
                }}
              >
                <Image
                  source={{ uri: item.avatar }}
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 55,
                  }}
                />
                <View
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '700',
                      fontSize: 19,
                      color: COLORS.BLACK,
                      letterSpacing: 1,
                    }}
                  >
                    {item.name_html}
                  </Text>
                  <View style={{ width: isDesktop() ? 350 : 200 }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 16,
                        color: '#A5A5A5',
                      }}
                      numberOfLines={1}
                    >
                      {item.last_message ? item.last_message.content : ''}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 16,
                    color: '#A5A5A5',
                    marginBottom: 5,
                  }}
                >
                  {item.last_message
                    ? showLastSendTime(item.last_message.created_at)
                    : ''}
                </Text>
                {item.unread_messages > 0 && (
                  <View
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      backgroundColor: COLORS.RED_PRIMARY,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '600',
                        fontSize: 12,
                        color: COLORS.WHITE,
                      }}
                    >
                      {`${item.unread_messages}`}
                    </Text>
                  </View>
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, [isDesktop, rooms]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getChatRooms();
    });

    // const channel = pusher.subscribe('my-channel');
    // channel.bind('my-event', (data: any) => {
    //   console.log(data);
    //   // setRooms((prevRooms) =>
    //   //   prevRooms.map((room) =>
    //   //     room.id === data.room
    //   //       ? { ...room, latestMessage: `${data.username}: ${data.message}`, unread: true }
    //   //       : room
    //   //   )
    //   // );
    // });

    // return () => {
    //   channel.unbind_all();
    //   channel.unsubscribe();
    // };

    return unsubscribe;
  }, [getChatRooms, navigation]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle
                title="YOUR MESSAGES"
                haveGoBackButton={true}
                handleGoBack={() => {
                  NavigationService.push('Root', {
                    screen: 'MenuTab',
                    params: {
                      screen: 'HomePage',
                    },
                  });
                }}
              />
              <View
                style={{
                  flexDirection: 'column',
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  borderRadius: 18,
                  padding: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 20,
                    letterSpacing: 2,
                    textTransform: 'uppercase',
                    marginBottom: 15,
                  }}
                >
                  Chats
                </Text>
                <View
                  style={{
                    position: 'relative',
                    marginBottom: 20,
                  }}
                >
                  <TextInput
                    placeholder="Search"
                    placeholderTextColor={'#888888'}
                    style={{
                      borderRadius: 5,
                      backgroundColor: '#F0F0F0',
                      paddingLeft: 45,
                      paddingVertical: 10,
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 16,
                    }}
                  />
                  <Image
                    source={require('../../assets/search_white.png')}
                    style={{
                      width: 18,
                      height: 18,
                      position: 'absolute',
                      tintColor: '#888888',
                      top: 11,
                      left: 20,
                    }}
                  />
                </View>
                <View
                  style={{
                    marginBottom: 40,
                  }}
                >
                  {renderMessagesList}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginBottom: 30,
                  }}
                >
                  <Button
                    arrowRight={false}
                    text="MESSAGE HELPING HAND SUPPORT STAFF"
                    style={{
                      paddingVertical: 13,
                      paddingHorizontal: 120,
                      backgroundColor: COLORS.LIGHT_RED,
                      borderRadius: 16,
                    }}
                    className=""
                    textStyle={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 12,
                      color: COLORS.RED_PRIMARY,
                      letterSpacing: 1.8,
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setOpenDropdown(false);
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <PageTitle
          title={'YOUR MESSAGES'}
          haveGoBackButton={true}
          handleGoBack={() => {
            NavigationService.push('Root', {
              screen: 'MenuTab',
              params: {
                screen: 'HomePage',
              },
            });
          }}
        />
        <View style={{ paddingTop: 20, paddingHorizontal: 20, zIndex: 2 }}>
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              letterSpacing: 2,
              textTransform: 'uppercase',
              marginBottom: 15,
            }}
          >
            Chats
          </Text>
          <View
            style={{
              position: 'relative',
            }}
          >
            <TextInput
              placeholder="Search"
              placeholderTextColor={'#888888'}
              style={{
                borderRadius: 5,
                backgroundColor: '#F0F0F0',
                paddingLeft: 45,
                height: 46,
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 16,
              }}
              onFocus={() => {
                setOpenDropdown(true);
              }}
              onChangeText={(text) => {
                setOpenDropdown(true);
              }}
            />
            <Image
              source={require('../../assets/search_white.png')}
              style={{
                width: 18,
                height: 18,
                position: 'absolute',
                tintColor: '#888888',
                top: 13,
                left: 20,
              }}
            />
            {openDropdown && (
              <View
                style={{
                  position: 'absolute',
                  top: 50,
                  width: '100%',
                  height: 300,
                  backgroundColor: 'white',
                  borderColor: COLORS.LGREY,
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              ></View>
            )}
          </View>
        </View>
        <View
          style={{
            marginBottom: 20,
          }}
        >
          {isLoading && (
            <View style={{ marginTop: 20 }}>
              <Loading />
            </View>
          )}
          {isLoading === false && rooms.length !== 0 && renderMessagesList}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <Button
            arrowRight={false}
            text="MESSAGE HELPING HAND SUPPORT STAFF"
            style={{
              paddingVertical: 13,
              paddingHorizontal: 30,
              backgroundColor: COLORS.LIGHT_RED,
              borderRadius: 16,
            }}
            className=""
            textStyle={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 11,
              color: COLORS.RED_PRIMARY,
              letterSpacing: 1.8,
            }}
            onPress={() => {
              handleCreateSupportStaffChat();
            }}
          />
        </View>
        <Footer />
        {fullScreenLoading && <Loading isFullScreen />}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

export default Messages;
