import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { IBookingState } from '../types';
import { CampPriceData, ICamp } from '../../../types/camp.types';
import dayjs from 'dayjs';
import { convertPrice } from '../../../services/utils';

type ProductTableProps = {
  state: IBookingState;
  booking: ICamp;
  priceData?: CampPriceData;
  isShowRoom?: boolean;
};
interface ITableItem {
  product: string;
  desc: string;
  price: string;
}

const ProductTable: FC<ProductTableProps> = ({
  state,
  booking,
  isShowRoom = false,
}) => {
  const isDesktop = useIsDesktop();

  const showPrice = (price: string | number) => {
    if (price) {
      return convertPrice(Number(price));
    } else {
      return 'Free';
    }
  };

  const renderTableItemRow = useCallback((item: ITableItem) => {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 15 }}>
        <View style={{ width: '75%' }}>
          <Text style={styles.tableContentText}>{item.product}</Text>
          <Text style={[styles.tableContentText, { fontSize: 14 }]}>
            {item.desc}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={styles.tableContentText}>{item.price}</Text>
        </View>
      </View>
    );
  }, []);

  const renderCamp = useMemo(() => {
    return (
      <View style={{ marginBottom: 10 }}>
        <View style={{ marginBottom: 8 }}>
          <Text style={styles.tableHeaderText}>Camp Type</Text>
        </View>
        {state.price_data?.camp_price &&
          renderTableItemRow({
            product: booking.name,
            desc: '',
            price: showPrice(state.price_data?.camp_price / 100),
          })}
      </View>
    );
  }, [booking.name, renderTableItemRow, state.price_data?.camp_price]);

  const renderRooms = useMemo(() => {
    return (
      state.rooms.length > 0 && (
        <View style={{ marginBottom: 10 }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.tableHeaderText}>Rooms</Text>
          </View>
          <View>
            {state.rooms.map((room) => {
              const roomFind = state.price_data?.rooms.find(
                (item) => item.id === room.room_id
              );

              if (roomFind) {
                return (
                  <>
                    {renderTableItemRow({
                      product: roomFind.name.en,
                      desc: `No. Of Room: ${room.quantity}${
                        room.is_additional_bed ? '\nAddtional bed' : ''
                      }`,
                      price: `${showPrice(roomFind.total / 100)}`,
                    })}
                  </>
                );
              }
            })}
          </View>
        </View>
      )
    );
  }, [renderTableItemRow, state.price_data, state.rooms]);

  const renderMeals = useMemo(() => {
    return (
      <View style={{ marginBottom: 10 }}>
        <View style={{ marginBottom: 8 }}>
          <Text style={styles.tableHeaderText}>Meals</Text>
        </View>
        {booking.included_meal.breakfast &&
          renderTableItemRow({
            product: 'Breakfast',
            desc: booking.included_meal.breakfast.name,
            price: showPrice(booking.included_meal.breakfast.price),
          })}
        {booking.included_meal.lunch &&
          renderTableItemRow({
            product: 'Lunch',
            desc: booking.included_meal.lunch.name,
            price: showPrice(booking.included_meal.lunch.price),
          })}
        {state.dinner_meal &&
          renderTableItemRow({
            product: 'Dinner',
            desc: state.dinner_meal.name,
            price: showPrice(state.dinner_meal.price),
          })}
        {state.extra_dishes && (
          <View>
            {state.extra_dishes.map((item) => {
              return (
                <>
                  {renderTableItemRow({
                    product: 'Extra',
                    desc: `${item.item.name}\nAmount: ${item.amount}`,
                    price: showPrice(item.item.price),
                  })}
                </>
              );
            })}
          </View>
        )}
      </View>
    );
  }, [
    booking.included_meal.breakfast,
    booking.included_meal.lunch,
    renderTableItemRow,
    state.dinner_meal,
    state.extra_dishes,
  ]);

  const renderActivities = useMemo(() => {
    return (
      booking.activities && (
        <View style={{ marginBottom: 10 }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.tableHeaderText}>Included Activities</Text>
          </View>
          {booking.activities.map((item) => {
            return renderTableItemRow({
              product: item.name,
              desc: `${dayjs(new Date(item.start_time)).format(
                'hh:mm a'
              )} - ${dayjs(new Date(item.end_time)).format('hh:mm a')}, ${dayjs(
                new Date(state.start_date)
              ).format('DD.MM.YYYY')}\n Venue: ${item.venue}`,
              price: showPrice(item.price),
            });
          })}
        </View>
      )
    );
  }, [booking.activities, renderTableItemRow, state.start_date]);

  return (
    <View
      style={{
        backgroundColor: '#f9f2f1',
        padding: 16,
        borderRadius: 18,
        flexDirection: 'column',
        marginBottom: 25,
      }}
    >
      <View>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            color: COLORS.BLACK,
            textAlign: 'center',
            marginBottom: 15,
            paddingHorizontal: 10,
            textTransform: 'uppercase',
          }}
        >
          {booking.name}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
          borderBottomWidth: 1,
          marginBottom: 8,
        }}
      >
        <View style={{ width: '75%' }}>
          <Text style={styles.tableHeaderText}>Product</Text>
        </View>
        <View
          style={[
            {
              width: '25%',
            },
            isDesktop()
              ? {
                  flexDirection: 'row',
                  alignItems: 'center',
                }
              : {
                  flexDirection: 'column',
                },
          ]}
        >
          <Text style={styles.tableHeaderText}>Price</Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 12,
              fontWeight: '500',
              marginLeft: 3,
              color: COLORS.GREY,
            }}
          >
            {'(HKD)'}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
          borderBottomWidth: 1,
          marginBottom: 10,
        }}
      >
        {renderCamp}
        {renderRooms}
        {!isShowRoom && (
          <>
            {renderMeals}
            {renderActivities}
          </>
        )}
      </View>
      {!isShowRoom && (
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <View style={{ width: '30%' }}>
            <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
              Total Price:
            </Text>
          </View>
          <View style={{ width: '45%' }}>
            <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
              Subsidised booking rate applied
            </Text>
          </View>
          <View style={{ width: '25%' }}>
            <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
              {state.price_data ? showPrice(state.price_data.total / 100) : ''}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default ProductTable;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.BLACK,
  },
  tableContentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
