import { FC, useCallback, useEffect, useState } from 'react';
import {
  Image,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Footer } from '../../components/Footer';
import { ActivitiesDetailScreenProps } from './types';
import { IActivity } from '../../types/activities.types';
import ActivityApi from '../../services/api/activity.api';
import { Loading } from '../../components';
import dayjs from 'dayjs';

const ActivitiesDetail: FC<ActivitiesDetailScreenProps> = ({
  route,
  navigation,
}) => {
  const activityId = route.params.id;
  const [activity, setActivity] = useState<IActivity>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isDesktop = useIsDesktop();
  const { height: screenHeight } = useWindowDimensions();

  const getActivity = useCallback(async () => {
    setIsLoading(true);
    ActivityApi.getActivity(activityId)
      .then((res) => {
        setActivity(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [activityId]);

  useEffect(() => {
    getActivity();
  }, [getActivity]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="My Activities" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  paddingBottom: 35,
                  elevation: 20,
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : activity ? (
                  <View>
                    <Text
                      style={{
                        fontFamily: FONTS.Arial,
                        fontWeight: '700',
                        fontSize: 20,
                        letterSpacing: 1.7,
                        color: COLORS.BLACK,
                        textTransform: 'uppercase',
                        borderBottomWidth: 1,
                        borderBottomColor: COLORS.GREY,
                        paddingBottom: 15,
                        marginBottom: 20,
                      }}
                    >
                      {activity.camp.name}
                    </Text>
                    <Image
                      source={
                        activity.image
                          ? {
                              uri: activity.image,
                            }
                          : require('../../assets/activities-image.png')
                      }
                      style={{
                        width: '100%',
                        height: 400,
                        borderRadius: 18,
                        marginBottom: 20,
                      }}
                    />
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ width: '20%' }}>
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirMedium,
                            fontWeight: '800',
                            fontSize: 19,
                            letterSpacing: 1,
                            marginBottom: 10,
                            color: COLORS.BLACK,
                          }}
                        >
                          {activity?.name}
                        </Text>
                        <View
                          style={{
                            width: 58,
                            height: 2,
                            backgroundColor: COLORS.RED_PRIMARY,
                            marginBottom: 10,
                          }}
                        />
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirMedium,
                            fontWeight: '500',
                            fontSize: 13,
                            color: COLORS.GREY,
                            letterSpacing: 1,
                            marginBottom: 10,
                          }}
                        >
                          {`Start: ${dayjs(
                            new Date(activity.start_time)
                          ).format('YYYY-MM-DD HH:mm a')}`}
                        </Text>
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirMedium,
                            fontWeight: '500',
                            fontSize: 13,
                            color: COLORS.GREY,
                            letterSpacing: 1,
                            marginBottom: 10,
                          }}
                        >
                          {`End: ${dayjs(new Date(activity.end_time)).format(
                            'YYYY-MM-DD HH:mm a'
                          )}`}
                        </Text>
                        {/* <View
                          style={{
                            borderRadius: 18,
                            paddingVertical: 5,
                            paddingHorizontal: 5,
                            backgroundColor: COLORS.LIGHT_GREY,
                            flexDirection: 'row',
                            width: 120,
                            marginBottom: 10,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: FONTS.AvenirMedium,
                              fontWeight: '500',
                              fontSize: 12,
                              textAlign: 'center',
                              color: COLORS.BLACK,
                              textTransform: 'uppercase',
                              letterSpacing: 1,
                            }}
                          >
                            {`${dayjs(new Date(activity.start_time)).format(
                              'HH:mm a'
                            )} ~ ${dayjs(new Date(activity.end_time)).format(
                              'HH:mm a'
                            )}`}
                          </Text>
                        </View> */}
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirMedium,
                            fontWeight: '500',
                            fontSize: 14,
                            color: COLORS.GREY,
                            letterSpacing: 1,
                            marginBottom: 10,
                          }}
                        >
                          {activity.venue}
                        </Text>
                      </View>
                      <View style={{ width: '80%' }}>
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirRegular,
                            fontSize: 18,
                            color: COLORS.GREY,
                            fontWeight: '500',
                          }}
                        >
                          {activity.description}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY UPCOMING ACTIVITIES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: '#F9F1F1',
          minHeight: screenHeight * 0.8,
        }}
      >
        {isLoading ? (
          <Loading />
        ) : activity ? (
          <View>
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
                letterSpacing: 1.7,
                color: COLORS.BLACK,
                textTransform: 'uppercase',
                borderBottomWidth: 1,
                borderBottomColor: COLORS.GREY,
                paddingBottom: 15,
                marginBottom: 20,
              }}
            >
              {activity.camp.name}
            </Text>
            {/* <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 16,
                color: COLORS.GREY,
                marginBottom: 10,
              }}
            >
              22 June 2024
            </Text> */}
            <View style={{ flexDirection: 'row', gap: 15, marginBottom: 15 }}>
              <Image
                source={
                  activity.image
                    ? {
                        uri: activity.image,
                      }
                    : require('../../assets/activities-image.png')
                }
                style={{
                  width: 175,
                  height: 175,
                  borderRadius: 18,
                }}
              />
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '800',
                    fontSize: 19,
                    letterSpacing: 1,
                    marginBottom: 10,
                  }}
                >
                  {activity.name}
                </Text>
                <View
                  style={{
                    width: 58,
                    height: 2,
                    backgroundColor: COLORS.RED_PRIMARY,
                    marginBottom: 10,
                  }}
                />
                {/* <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 13,
                    color: COLORS.GREY,
                    letterSpacing: 1,
                    marginBottom: 10,
                  }}
                >
                  Every Thursday
                </Text> */}
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 13,
                    color: COLORS.GREY,
                    letterSpacing: 1,
                    marginBottom: 10,
                  }}
                >
                  {`Start: ${dayjs(new Date(activity.start_time)).format(
                    'YYYY-MM-DD HH:mm a'
                  )}`}
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 13,
                    color: COLORS.GREY,
                    letterSpacing: 1,
                    marginBottom: 10,
                  }}
                >
                  {`End: ${dayjs(new Date(activity.end_time)).format(
                    'YYYY-MM-DD HH:mm a'
                  )}`}
                </Text>
                {/* <View
                  style={{
                    borderRadius: 18,
                    paddingVertical: 2,
                    paddingHorizontal: 5,
                    backgroundColor: COLORS.LIGHT_GREY,
                    flexDirection: 'row',
                    width: 82,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 12,
                      textAlign: 'center',
                      color: COLORS.BLACK,
                    }}
                  >
                    10:00 a.m. ~ 4:15 p.m.
                  </Text>
                </View> */}
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 14,
                    color: COLORS.GREY,
                    letterSpacing: 1,
                    marginBottom: 10,
                  }}
                >
                  {activity.venue}
                </Text>
              </View>
            </View>
            <Text
              style={{
                fontFamily: FONTS.AvenirRegular,
                fontSize: 18,
                color: COLORS.GREY,
                fontWeight: '500',
              }}
            >
              {activity.description}
            </Text>
          </View>
        ) : (
          <></>
        )}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default ActivitiesDetail;
