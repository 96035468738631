import phoneCode from '../../phone-code.json';

export const searchPhoneCountry = (code: string) => {
  var result = phoneCode.find((obj) => {
    return obj.code === code;
  });

  return result;
};

export const convertToFullPhone = (
  code: string | undefined,
  phone: string | undefined
) => {
  let dialCode = '';
  let phoneNumber = '';

  if (code && phone) {
    var searchPhoneCode = phoneCode.find((obj) => {
      return obj.code === code;
    });
    dialCode = searchPhoneCode ? searchPhoneCode.dial_code : '';
    phoneNumber = phone;
  }

  return `${dialCode} ${phoneNumber}`;
};

export const convertMobileInputData = (phone: string) => {
  if (phone.length) {
    let phoneArray = phone.split(',');

    return {
      number: phoneArray[1],
      country: phoneArray[0],
    };
  } else {
    return {
      number: '',
      country: '',
    };
  }
};
