import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import {
  Image,
  KeyboardAvoidingView,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { IS_IOS, MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { TextInput } from '../../components/TextInput/TextInput';
import ReceiveMessage from './components/ReceiveMessage';
import SendMessage from './components/SendMessage';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';
import { MessagesUserScreenProps } from './types';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux';
import usePusherChannel from '../../hooks/usePusherChannel';
import { IChatRoom, IMessage } from '../../types/chat.types';
import ChatRoomApi from '../../services/api/chatroom.api';
import { Alert } from '../../services/alert';
import { Loading } from '../../components';
import dayjs from 'dayjs';
import createPusherClient from '../../services/utils/pusher';

const MessagesUser: FC<MessagesUserScreenProps> = ({ navigation, route }) => {
  const roomId = route.params.id;
  const [chatRoom, setChatRoom] = useState<IChatRoom>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const chatRoomRef = useRef<ScrollView>(null);
  const contentHeight = useRef<number>(0);

  const { access_token, user } = useSelector(
    (redux: RootState) => redux.authReducer
  );
  const isDesktop = useIsDesktop();
  const { height: screenHeight } = useWindowDimensions();

  const markAsRead = async () => {
    ChatRoomApi.postChatRoomRead(roomId);
  };

  const getChatRoom = async () => {
    setIsLoading(true);
    ChatRoomApi.getChatRoomDetail(roomId)
      .then((res) => {
        setChatRoom(res.data.data);
      })
      .catch((error) => {
        Alert.alert('Error', error.meta.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMessages = useCallback(
    async (pageNum: number) => {
      setIsLoadingMessages(true);
      ChatRoomApi.getChatRoomMessages(roomId, pageNum)
        .then((res) => {
          const newMessages: IMessage[] = res.data.data.data;
          setMessages((prevMessages) => [
            ...newMessages.reverse(),
            ...prevMessages,
          ]);
          setTotal(res.data.data.total);
        })
        .catch((error) => {
          Alert.alert('Error', error.meta.message);
        })
        .finally(() => {
          setIsLoadingMessages(false);
        });
    },
    [roomId]
  );

  useEffect(() => {
    //markAsRead();
    getChatRoom();
    getMessages(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatRoomRef.current && page === 1) {
      chatRoomRef.current.scrollToEnd({ animated: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { nativeEvent } = event;
      if (
        nativeEvent.contentOffset.y <= 0 &&
        messages.length < total &&
        !isLoadingMessages
      ) {
        getMessages(page + 1);
        setPage(page + 1);
      }
    },
    [getMessages, isLoadingMessages, messages, page, total]
  );

  const handleContentSizeChange = (
    contentWidth: number,
    contentHeights: number
  ) => {
    if (contentHeights > contentHeight.current) {
      if (chatRoomRef.current) {
        chatRoomRef.current.scrollTo({
          y: contentHeights - contentHeight.current,
          animated: false,
        });
      }
    }
    contentHeight.current = contentHeights;
  };

  const renderChatbox = useMemo(() => {
    return (
      <ScrollView
        style={{
          height: isDesktop() ? 450 : screenHeight - 212 - (IS_IOS ? 80 : 0),
          flexDirection: 'column',
        }}
        ref={chatRoomRef}
        onScroll={handleScroll}
        scrollEventThrottle={16}
        onContentSizeChange={handleContentSizeChange}
      >
        {isLoadingMessages && <Loading />}
        <View
          style={{
            flexDirection: 'column',
            minHeight: screenHeight - 100,
          }}
        >
          {messages.map((message, index) => (
            <>
              {message.user_id != user?.id ? (
                <ReceiveMessage
                  content={message.content}
                  time={dayjs(new Date(message.created_at)).format('HH:mm')}
                />
              ) : (
                <SendMessage
                  content={message.content}
                  time={dayjs(new Date(message.created_at)).format('HH:mm')}
                />
              )}
            </>
          ))}
        </View>
      </ScrollView>
    );
  }, [
    screenHeight,
    isDesktop,
    messages,
    handleScroll,
    isLoadingMessages,
    user?.id,
    ,
  ]);

  // useEffect(() => {
  //   const pusher = createPusherClient(access_token ? access_token : '');
  //   const channel = pusher.subscribe(`presence-App.Rooms.${roomId}`);

  //   channel.bind('pusher:subscription_succeeded', () => {
  //     console.log(
  //       `Successfully subscribed to channel: ${`presence-App.Rooms.${roomId}`}`
  //     );
  //   });

  //   channel.bind('pusher:subscription_error', (status: any) => {
  //     console.error(
  //       `Subscription error on ${`presence-App.Rooms.${roomId}`}:`,
  //       status
  //     );
  //   });
  //   channel.bind(
  //     'room-message-sent',
  //     (data: { message: IMessage; room: IChatRoom }) => {
  //       setMessages((prevMessages) => [...prevMessages, data.message]);
  //     }
  //   );

  //   return () => {
  //     channel.unbind_all();
  //     channel.unsubscribe();
  //   };
  // }, []);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    NavigationService.push('Root', {
                      screen: 'Messages',
                    });
                  }}
                >
                  <Image
                    source={require('../../assets/arrow-left.png')}
                    style={{
                      width: 30,
                      height: 30,
                      tintColor: '#575757',
                      marginRight: 8,
                    }}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 15,
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../assets/users/image-1.png')}
                    style={{
                      width: 55,
                      height: 55,
                      borderRadius: 55,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '800',
                        fontSize: 19,
                        letterSpacing: 1,
                        color: COLORS.BLACK,
                      }}
                    >
                      Dianne
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 16,
                        color: COLORS.GREY,
                      }}
                    >
                      Online
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderRadius: 18,
                  backgroundColor: COLORS.LIGHT_RED,
                  paddingHorizontal: 30,
                  paddingVertical: 40,
                  flexDirection: 'column',
                }}
              >
                {renderChatbox}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 20,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      padding: 10,
                      backgroundColor: COLORS.WHITE,
                      borderWidth: 0.5,
                      borderColor: '#00000080',
                      borderRadius: 5,
                      width: '80%',
                      flex: 1,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <Image
                        source={require('../../assets/camera.png')}
                        style={{
                          width: 30,
                          height: 25,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                    <TextInput
                      placeholder="Type your messages"
                      placeholderTextColor={COLORS.GREY}
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 16,
                        flex: 1,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      paddingRight: 50,
                    }}
                  >
                    <TouchableOpacity>
                      <Image
                        source={require('../../assets/send-icon.png')}
                        style={{ width: 30, height: 30, resizeMode: 'contain' }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <View>
      {isLoading ? (
        <View style={{ marginVertical: 30 }}>
          <Loading />
        </View>
      ) : (
        <View
          style={{
            height: screenHeight - 80,
            flexDirection: 'column',
          }}
        >
          <View
            style={{
              backgroundColor: COLORS.WHITE,
              width: '100%',
              flexDirection: 'row',
              paddingVertical: 8,
              paddingHorizontal: 15,
              justifyContent: 'flex-start',
              elevation: 4,
              shadowColor: '#0C0C0D1A',
              shadowOffset: { width: 0, height: 4 },
              shadowOpacity: 1,
              shadowRadius: 4,
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              style={{
                marginRight: 10,
              }}
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'Messages',
                });
              }}
            >
              <Image
                source={require('../../assets/arrow-left.png')}
                style={{
                  width: 30,
                  height: 30,
                  tintColor: COLORS.BLACK,
                }}
              />
            </TouchableOpacity>
            <View
              style={{
                flexDirection: 'row',
                gap: 15,
                alignItems: 'center',
              }}
            >
              <Image
                source={{ uri: chatRoom?.avatar }}
                style={{
                  width: 55,
                  height: 55,
                  borderRadius: 55,
                }}
              />
              <View>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '800',
                    fontSize: 19,
                    letterSpacing: 1,
                    color: COLORS.BLACK,
                  }}
                >
                  {chatRoom?.name_html}
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 16,
                    color: COLORS.GREY,
                  }}
                >
                  Online
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: COLORS.LIGHT_RED,
              flexDirection: 'column',
            }}
          >
            {renderChatbox}
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
              paddingBottom: 5,
              backgroundColor: COLORS.LIGHT_RED,
              paddingHorizontal: 5,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: COLORS.WHITE,
                borderWidth: 0.5,
                borderColor: '#00000080',
                borderRadius: 5,
                width: '80%',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TouchableOpacity
                style={{
                  marginRight: 5,
                  marginLeft: 5,
                }}
              >
                <Image
                  source={require('../../assets/camera.png')}
                  style={{
                    width: 30,
                    height: 25,
                    resizeMode: 'contain',
                  }}
                />
              </TouchableOpacity>
              <TextInput
                placeholder="Type your messages"
                placeholderTextColor={COLORS.GREY}
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  flex: 1,
                }}
              />
            </View>
            <View
              style={{
                paddingRight: 10,
              }}
            >
              <TouchableOpacity>
                <Image
                  source={require('../../assets/send-icon.png')}
                  style={{ width: 30, height: 30, resizeMode: 'contain' }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default MessagesUser;
