import { FC } from 'react';
import {
  Image,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Footer } from '../../components/Footer';

const MyActivitiesDetail: FC = () => {
  const isDesktop = useIsDesktop();
  const { height: screenHeight } = useWindowDimensions();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="My Activities" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  paddingBottom: 35,
                  elevation: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 20,
                    letterSpacing: 1.7,
                    color: COLORS.BLACK,
                    textTransform: 'uppercase',
                    borderBottomWidth: 1,
                    borderBottomColor: COLORS.GREY,
                    paddingBottom: 15,
                    marginBottom: 20,
                  }}
                >
                  {'Day Camp'}
                </Text>
                <Image
                  source={require('../../assets/activities_image/image-1.png')}
                  style={{
                    width: '100%',
                    height: 400,
                    borderRadius: 18,
                    marginBottom: 20,
                  }}
                />
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '20%' }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '800',
                        fontSize: 19,
                        letterSpacing: 1,
                        marginBottom: 10,
                        color: COLORS.BLACK,
                      }}
                    >
                      Capoeira
                    </Text>
                    <View
                      style={{
                        width: 58,
                        height: 2,
                        backgroundColor: COLORS.RED_PRIMARY,
                        marginBottom: 10,
                      }}
                    />
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 14,
                        color: COLORS.GREY,
                        letterSpacing: 1,
                        marginBottom: 10,
                      }}
                    >
                      {'Every Thursday'}
                    </Text>
                    <View
                      style={{
                        borderRadius: 18,
                        paddingVertical: 5,
                        paddingHorizontal: 5,
                        backgroundColor: COLORS.LIGHT_GREY,
                        flexDirection: 'row',
                        width: 120,
                        marginBottom: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: FONTS.AvenirMedium,
                          fontWeight: '500',
                          fontSize: 12,
                          textAlign: 'center',
                          color: COLORS.BLACK,
                          textTransform: 'uppercase',
                          letterSpacing: 1,
                        }}
                      >
                        {'10:00 a.m. ~ 4:15 p.m.'}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 14,
                        color: COLORS.GREY,
                        letterSpacing: 1,
                        marginBottom: 10,
                      }}
                    >
                      {'Activity Room 1'}
                    </Text>
                  </View>
                  <View style={{ width: '80%' }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirRegular,
                        fontSize: 18,
                        color: COLORS.GREY,
                        fontWeight: '500',
                      }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY UPCOMING ACTIVITIES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: '#F9F1F1',
          minHeight: screenHeight * 0.8,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            letterSpacing: 1.7,
            color: COLORS.BLACK,
            textTransform: 'uppercase',
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            paddingBottom: 15,
            marginBottom: 20,
          }}
        >
          {'Day Camp'}
        </Text>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
            marginBottom: 10,
          }}
        >
          22 June 2024
        </Text>
        <View style={{ flexDirection: 'row', gap: 15, marginBottom: 15 }}>
          <Image
            source={require('../../assets/activities_image/image-1.png')}
            style={{
              width: 175,
              height: 175,
              borderRadius: 18,
            }}
          />
          <View
            style={{
              flex: 1,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '800',
                fontSize: 19,
                letterSpacing: 1,
                marginBottom: 10,
              }}
            >
              Capoeira
            </Text>
            <View
              style={{
                width: 58,
                height: 2,
                backgroundColor: COLORS.RED_PRIMARY,
                marginBottom: 10,
              }}
            />
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 14,
                color: COLORS.GREY,
                letterSpacing: 1,
                marginBottom: 10,
              }}
            >
              Every Thursday
            </Text>
            <View
              style={{
                borderRadius: 18,
                paddingVertical: 2,
                paddingHorizontal: 5,
                backgroundColor: COLORS.LIGHT_GREY,
                flexDirection: 'row',
                width: 82,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 12,
                  textAlign: 'center',
                  color: COLORS.BLACK,
                }}
              >
                10:00 a.m. ~ 4:15 p.m.
              </Text>
            </View>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 14,
                color: COLORS.GREY,
                letterSpacing: 1,
                marginBottom: 10,
              }}
            >
              Activity Room 1
            </Text>
          </View>
        </View>
        <Text
          style={{
            fontFamily: FONTS.AvenirRegular,
            fontSize: 18,
            color: COLORS.GREY,
            fontWeight: '500',
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Text>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MyActivitiesDetail;
