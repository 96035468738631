import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Animated, Image, Text, TouchableOpacity, View } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import dayjs from 'dayjs';
import { Calendar } from '../Calendar';
import { StyleSheet } from 'react-native';

const BookingTimePicker: FC<{
  checkInDay: string;
  setCheckInDay: (date: string) => void;
  checkOutDay: string;
  setCheckOutDay: (date: string) => void;
  isOvernight?: boolean;
}> = ({
  checkInDay,
  setCheckInDay,
  checkOutDay,
  setCheckOutDay,
  isOvernight = false,
}) => {
  const isDesktop = useIsDesktop();
  const [showCalendarCheckout, setShowCalendarCheckout] =
    useState<boolean>(false);
  const [showCalendarCheckIn, setShowCalendarCheckIn] =
    useState<boolean>(false);
  const [isHoverCheckIn, setIsHoverCheckIn] = useState<boolean>(false);
  const [isHoverCheckOut, setIsHoverCheckOut] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;

  const handleMouseCheckInEnter = () => {
    setIsHoverCheckIn(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckInLeave = () => {
    setIsHoverCheckIn(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckOutEnter = () => {
    setIsHoverCheckOut(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseCheckOutLeave = () => {
    setIsHoverCheckOut(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const handleChangeCheckInDate = useCallback(
    (date: string) => {
      setCheckInDay(date);

      if (isOvernight) {
        let checkout = new Date(date);
        checkout.setDate(checkout.getDate() + 1);
        setCheckOutDay(dayjs(checkout).format('YYYY-MM-DD'));
      } else {
        setCheckOutDay(date);
      }
    },
    [setCheckInDay, setCheckOutDay, isOvernight]
  );

  const handleChangeCheckOutDate = useCallback(
    (date: string) => {
      if (isOvernight) {
        let checkin = new Date(checkInDay);
        let checkout = new Date(date);

        if (checkout > checkin) {
          setCheckOutDay(date);
        } else {
          setCheckOutDay(date);
          checkin.setDate(checkout.getDate() - 1);
          setCheckInDay(dayjs(checkin).format('YYYY-MM-DD'));
        }
      } else {
        setCheckOutDay(date);
        setCheckInDay(date);
      }
    },
    [setCheckInDay, setCheckOutDay, isOvernight, checkInDay]
  );

  useEffect(() => {
    if (isOvernight) {
      let checkout = new Date(checkInDay);
      checkout.setDate(checkout.getDate() + 1);
      setCheckOutDay(dayjs(checkout).format('YYYY-MM-DD'));
    } else {
      setCheckOutDay(checkInDay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInDay, isOvernight]);

  return (
    <View
      style={[
        {
          padding: 5,
          borderWidth: 1,
          borderColor: COLORS.GREY,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 16,
          marginBottom: 10,
          backgroundColor: COLORS.WHITE,
          justifyContent: 'space-between',
          marginEnd: isDesktop() ? 10 : 0,
          zIndex: 1,
        },
        !isDesktop() && {
          width: '100%',
        },
        !isOvernight && {
          justifyContent: 'center',
        },
      ]}
    >
      <View
        style={{
          width: isDesktop() ? 170 : isOvernight ? '49%' : '100%',
        }}
      >
        {isDesktop() ? (
          <div
            onMouseEnter={handleMouseCheckInEnter}
            onMouseLeave={handleMouseCheckInLeave}
            style={{ width: '100%' }}
          >
            <TouchableOpacity
              style={[
                styles.pickCalendarWrapper,
                isDesktop()
                  ? {
                      paddingVertical: 0,
                    }
                  : {},
              ]}
              onPress={() => {
                setShowCalendarCheckIn(!showCalendarCheckIn);
              }}
            >
              <Image
                source={require('../../assets/check-in-icon.png')}
                style={styles.pickCalendarIcon}
              />
              <View>
                <Text
                  style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}
                >
                  {dayjs(new Date(checkInDay)).format('ddd, MMM DD')}
                </Text>
                <Text
                  style={{
                    fontSize: isDesktop() ? 16 : 14,
                    color: isHoverCheckIn ? COLORS.RED_PRIMARY : COLORS.GREY,
                    fontFamily: FONTS.AvenirMedium,
                  }}
                >
                  Check in
                </Text>
              </View>
            </TouchableOpacity>
          </div>
        ) : (
          <View style={{ width: '100%' }}>
            <TouchableOpacity
              style={[
                styles.pickCalendarWrapper,
                isDesktop()
                  ? {
                      paddingVertical: 0,
                    }
                  : {
                      flexDirection: 'row',
                      justifyContent: 'center',
                    },
              ]}
              onPress={() => {
                setShowCalendarCheckIn(!showCalendarCheckIn);
              }}
            >
              <Image
                source={require('../../assets/check-in-icon.png')}
                style={styles.pickCalendarIcon}
              />
              <View>
                <Text
                  style={[
                    styles.dateText,
                    !isDesktop() && { fontSize: 14, textAlign: 'center' },
                  ]}
                >
                  {dayjs(new Date(checkInDay)).format('ddd, MMM DD')}
                </Text>
                <Text
                  style={{
                    fontSize: isDesktop() ? 16 : 14,
                    color: isHoverCheckIn ? COLORS.RED_PRIMARY : COLORS.GREY,
                    fontFamily: FONTS.AvenirMedium,
                    textAlign: 'center',
                  }}
                >
                  Check in
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
        {showCalendarCheckIn && (
          <View
            style={[
              styles.calendarWrapper,
              isDesktop()
                ? {
                    right: 0,
                  }
                : {
                    left: 0,
                  },
            ]}
          >
            <Calendar
              initDate={checkInDay}
              onChangeDate={(date) => {
                handleChangeCheckInDate(date);
                setIsHoverCheckIn(false);
              }}
              setShowCalendar={setShowCalendarCheckIn}
              showCalendar={showCalendarCheckIn}
            />
          </View>
        )}
      </View>
      {isOvernight && (
        <View
          style={{
            width: 1,
            height: 35,
            backgroundColor: COLORS.BLACK,
          }}
        ></View>
      )}
      {isOvernight && (
        <View
          style={{
            width: isDesktop() ? 170 : '49%',
          }}
        >
          {isDesktop() ? (
            <div
              onMouseEnter={handleMouseCheckOutEnter}
              onMouseLeave={handleMouseCheckOutLeave}
              style={{ width: '100%' }}
            >
              <TouchableOpacity
                style={[
                  styles.pickCalendarWrapper,
                  isDesktop()
                    ? {
                        paddingVertical: 0,
                      }
                    : {},
                ]}
                onPress={() => {
                  setShowCalendarCheckout(!showCalendarCheckout);
                }}
              >
                <Image
                  source={require('../../assets/check-out-icon.png')}
                  style={styles.pickCalendarIcon}
                />
                <View>
                  <Text
                    style={[styles.dateText, !isDesktop() && { fontSize: 14 }]}
                  >
                    {dayjs(new Date(checkOutDay)).format('ddd, MMM DD')}
                  </Text>
                  <Text
                    style={{
                      fontSize: isDesktop() ? 16 : 14,
                      color: isHoverCheckOut ? COLORS.RED_PRIMARY : COLORS.GREY,
                      fontFamily: FONTS.AvenirMedium,
                    }}
                  >
                    Check out
                  </Text>
                </View>
              </TouchableOpacity>
            </div>
          ) : (
            <View style={{ width: '100%' }}>
              <TouchableOpacity
                style={[
                  styles.pickCalendarWrapper,
                  isDesktop()
                    ? {
                        paddingVertical: 0,
                      }
                    : {
                        flexDirection: 'row',
                        justifyContent: 'center',
                      },
                ]}
                onPress={() => {
                  setShowCalendarCheckout(!showCalendarCheckout);
                }}
              >
                <Image
                  source={require('../../assets/check-out-icon.png')}
                  style={styles.pickCalendarIcon}
                />
                <View>
                  <Text
                    style={[
                      styles.dateText,
                      !isDesktop() && { fontSize: 14, textAlign: 'center' },
                    ]}
                  >
                    {dayjs(new Date(checkOutDay)).format('ddd, MMM DD')}
                  </Text>
                  <Text
                    style={{
                      fontSize: isDesktop() ? 16 : 14,
                      color: isHoverCheckOut ? COLORS.RED_PRIMARY : COLORS.GREY,
                      fontFamily: FONTS.AvenirMedium,
                      textAlign: 'center',
                    }}
                  >
                    Check out
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {showCalendarCheckout && (
            <View
              style={[
                styles.calendarWrapper,
                isDesktop()
                  ? {
                      left: 0,
                    }
                  : {
                      right: 0,
                    },
              ]}
            >
              <Calendar
                initDate={checkOutDay}
                onChangeDate={(date) => {
                  handleChangeCheckOutDate(date);
                  setIsHoverCheckOut(false);
                }}
                setShowCalendar={setShowCalendarCheckout}
                showCalendar={showCalendarCheckout}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default BookingTimePicker;

const styles = StyleSheet.create({
  pickCalendarWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 6,
    position: 'relative',
    paddingVertical: 5,
  },
  pickCalendarIcon: {
    width: 24,
    height: 20,
    marginRight: 5,
    resizeMode: 'contain',
  },
  dateText: {
    fontSize: 16,
    fontFamily: FONTS.AvenirMedium,
    color: COLORS.RED_PRIMARY,
    textTransform: 'uppercase',
  },
  calendarWrapper: {
    zIndex: 100000,
    width: 300,
    position: 'absolute',
    backgroundColor: COLORS.WHITE,
    top: 70,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 3,
    padding: 20,
    borderRadius: 8,
  },
});
