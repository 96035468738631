import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { BaseApiResponse } from '../../types/api.types';
import { ICamp } from '../../types/camp.types';
import instance from './axios';

const ENDPOINTS = {
  CAMP_TYPES: '/camp-types',
  CAMPS: '/camps',
  DISHES: '/dishes',
  CALCULATE_PRICE: '/booking/camp-calculate-price',
  CREATE_INTENT: '/booking/create-intent',
  PAYMENT_METHOD: '/payment-methods',
  BOOKING_CAMP: 'booking/camp',
  BOOKING: 'booking',
  CHECK_ROOM_AVAILABLE: 'booking/room-available',
};

const getCampTypes = () => {
  return instance.get(ENDPOINTS.CAMP_TYPES);
};

const getCamps = () => {
  return instance.get(ENDPOINTS.CAMPS);
};

const getCamp = (id: number) => {
  return instance.get<{ data: ICamp }>(ENDPOINTS.CAMPS + '/' + id);
};

const getDishes = () => {
  return instance.get<BaseApiResponse>(ENDPOINTS.DISHES);
};

const postCalculatePrice = (data: IFormData) => {
  return instance.post<BaseApiResponse>(ENDPOINTS.CALCULATE_PRICE, data);
};

const postCreateIntent = () => {
  return instance.post<BaseApiResponse>(ENDPOINTS.CREATE_INTENT);
};

const getPaymentMethods = () => {
  return instance.get<BaseApiResponse>(ENDPOINTS.PAYMENT_METHOD);
};

const postCreateCampBooking = (data: IFormData) => {
  return instance.post<BaseApiResponse>(ENDPOINTS.BOOKING_CAMP, data);
};

const getBookingDetail = (id: number) => {
  return instance.get<BaseApiResponse>(ENDPOINTS.BOOKING + '/' + id);
};

const postCheckRoomAvailable = (data: IFormData) => {
  return instance.post<BaseApiResponse>(ENDPOINTS.CHECK_ROOM_AVAILABLE, data);
};

const CampApi = {
  getCampTypes,
  getCamps,
  getCamp,
  getDishes,
  postCalculatePrice,
  postCreateIntent,
  getPaymentMethods,
  postCreateCampBooking,
  getBookingDetail,
  postCheckRoomAvailable,
};

export default CampApi;
