import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import {
  Animated,
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { Text } from 'react-native';
import { Calendar } from '../../../components/Calendar';
import GuestPicker from '../../../components/GuestPicker/GuestPicker';
import dayjs from 'dayjs';
import { NavigationService } from '../../../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import { ICamp } from '../../../types/camp.types';
import CampApi from '../../../services/api/camp.api';
import BookingTimePicker from '../../../components/BookingTimePicker/BookingTimePicker';
import { Alert } from '../../../services/alert';

const BookingPeoplePicker: FC<{
  totalGuest: number;
  handleSetTotalGuest: (total: number) => void;
  handleSetElderlyGuest: (number: number) => void;
  handleSetAdultGuest: (number: number) => void;
}> = ({
  totalGuest,
  handleSetTotalGuest,
  handleSetElderlyGuest,
  handleSetAdultGuest,
}) => {
  const [showGuestPick, setGuestPick] = useState<boolean>(false);
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;

  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const handleCloseGuestModal = () => {
    setGuestPick(false);
  };

  if (isDesktop()) {
    return (
      <div
        style={{ position: 'relative' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <TouchableOpacity
          style={[
            {
              padding: 15,
              borderWidth: 1,
              borderColor: COLORS.GREY,
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 16,
              backgroundColor: COLORS.WHITE,
              marginBottom: 10,
            },
            !isDesktop() && { width: '100%' },
          ]}
          onPress={() => {
            setGuestPick(!showGuestPick);
          }}
        >
          <Image
            source={require('../../../assets/user-icon.png')}
            style={[
              {
                marginRight: 20,
              },
              isDesktop()
                ? {
                    width: 25,
                    height: 25,
                  }
                : {
                    width: 20,
                    height: 20,
                  },
            ]}
          />
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: COLORS.RED_PRIMARY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {totalGuest}
            </Text>
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: isHover ? COLORS.RED_PRIMARY : COLORS.GREY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {' '}
              Adults
            </Text>
          </View>
        </TouchableOpacity>
        {showGuestPick && (
          <View
            style={{
              position: 'absolute',
              left: -70,
              top: 70,
            }}
          >
            <GuestPicker
              setTotalGuest={handleSetTotalGuest}
              setTotalElderlyGuest={handleSetElderlyGuest}
              setAdultGuest={handleSetAdultGuest}
              handleCancel={handleCloseGuestModal}
            />
          </View>
        )}
      </div>
    );
  } else {
    return (
      <View style={{ position: 'relative' }}>
        <TouchableOpacity
          style={[
            {
              padding: 15,
              borderWidth: 1,
              borderColor: COLORS.GREY,
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 16,
              backgroundColor: COLORS.WHITE,
              marginBottom: 10,
            },
            !isDesktop() && { width: '100%' },
          ]}
          onPress={() => {
            setGuestPick(!showGuestPick);
          }}
        >
          <Image
            source={require('../../../assets/user-icon.png')}
            style={[
              {
                marginRight: 20,
              },
              isDesktop()
                ? {
                    width: 25,
                    height: 25,
                  }
                : {
                    width: 20,
                    height: 20,
                  },
            ]}
          />
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: COLORS.RED_PRIMARY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {totalGuest}
            </Text>
            <Text
              style={{
                fontSize: isDesktop() ? 19 : 16,
                color: isHover ? COLORS.RED_PRIMARY : COLORS.GREY,
                fontFamily: FONTS.AvenirMedium,
                letterSpacing: 1,
              }}
            >
              {' '}
              Adults
            </Text>
          </View>
        </TouchableOpacity>
        {showGuestPick && (
          <View
            style={{
              position: 'absolute',
              left: -70,
              top: 70,
            }}
          >
            <GuestPicker
              setTotalGuest={handleSetTotalGuest}
              setTotalElderlyGuest={handleSetElderlyGuest}
              setAdultGuest={handleSetAdultGuest}
              handleCancel={handleCloseGuestModal}
            />
          </View>
        )}
      </View>
    );
  }
};

const BookingTypeItem: FC<{
  active?: boolean;
  onPress?: () => void;
  camp: ICamp;
  setSelected: (camp: ICamp) => void;
}> = memo(({ active = false, camp, setSelected }) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;

  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  if (isDesktop()) {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ width: !isDesktop() ? '48%' : 'none' }}
      >
        <TouchableOpacity
          style={[
            {
              backgroundColor:
                isHover || active ? COLORS.RED_PRIMARY : COLORS.WHITE,

              borderWidth: 1,
              borderColor: isHover || active ? COLORS.RED_PRIMARY : COLORS.GREY,
              opacity: 100,
            },
            isDesktop()
              ? {
                  marginHorizontal: 5,
                  paddingHorizontal: 22,
                  paddingVertical: 10,
                  borderRadius: 16,
                }
              : {
                  paddingVertical: 5,
                  marginBottom: 10,
                  borderRadius: 12,
                },
          ]}
          onPress={() => {
            setSelected(camp);
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 14,
              color: isHover || active ? COLORS.WHITE : COLORS.GREY,
              letterSpacing: 1,
              textAlign: 'center',
            }}
          >
            {camp.name}
          </Text>
        </TouchableOpacity>
      </div>
    );
  }

  return (
    <View style={{ width: !isDesktop() ? '48%' : 'none' }}>
      <TouchableOpacity
        style={[
          {
            backgroundColor:
              isHover || active ? COLORS.RED_PRIMARY : COLORS.WHITE,

            borderWidth: 1,
            borderColor: isHover || active ? COLORS.RED_PRIMARY : COLORS.GREY,
            opacity: 100,
          },
          isDesktop()
            ? {
                marginHorizontal: 5,
                paddingHorizontal: 22,
                paddingVertical: 10,
                borderRadius: 16,
              }
            : {
                paddingVertical: 5,
                marginBottom: 10,
                borderRadius: 12,
              },
        ]}
        onPress={() => {
          setSelected(camp);
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontSize: 14,
            color: isHover || active ? COLORS.WHITE : COLORS.GREY,
            letterSpacing: 1,
            textAlign: 'center',
          }}
        >
          {camp.name}
        </Text>
      </TouchableOpacity>
    </View>
  );
});

export const BookACamp: FC = () => {
  const isDesktop = useIsDesktop();
  const { user, loggedin } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const [camps, setCamps] = useState<ICamp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [typeBook, setTypeBook] = useState<string>('');
  const screenWidth = Dimensions.get('window').width;
  const [checkInDay, setCheckInDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );
  const [checkOutDay, setCheckOutDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );
  const [selectedCamp, setSelectedCamp] = useState<ICamp>();
  const [totalGuest, setTotalGuest] = useState<number>(0);
  const [elderlyGuest, setElderlyGuest] = useState<number>(0);
  const [adultGuest, setAdultGuest] = useState<number>(0);

  const handleSetTotalGuest = (total: number) => {
    setTotalGuest(total);
  };

  const handleSetElderlyGuest = (number: number) => {
    setElderlyGuest(number);
  };

  const handleSetAdultGuest = (number: number) => {
    setAdultGuest(number);
  };

  const getCamps = async () => {
    setIsLoading(true);
    CampApi.getCamps()
      .then((res) => {
        setCamps(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSetSelected = (camp: ICamp) => {
    setSelectedCamp(camp);
  };

  const handlePressBook = useCallback(() => {
    if (loggedin) {
      if (!selectedCamp) {
        Alert.alert('Error', 'Please select camp');
      } else if (totalGuest === 0) {
        Alert.alert('Error', 'Please select guest');
      } else {
        NavigationService.push('Root', {
          screen: 'MenuTab',
          params: {
            screen: 'BookingDetail',
            params: {
              id: selectedCamp.id,
              data: {
                checkin: checkInDay,
                checkout: checkOutDay,
                adult: adultGuest,
                elderly: elderlyGuest,
              },
            },
          },
        });
      }
    } else {
      NavigationService.push('Auth');
    }
  }, [
    selectedCamp,
    loggedin,
    totalGuest,
    adultGuest,
    checkInDay,
    checkOutDay,
    elderlyGuest,
  ]);

  const bookingTypeRender = useMemo(() => {
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
            flexWrap: 'wrap',
          },
          isDesktop()
            ? {
                justifyContent: 'center',
                gap: 10,
              }
            : {
                justifyContent: 'center',
                columnGap: 10,
                paddingHorizontal: 20,
              },
        ]}
      >
        {camps.map((camp) => (
          <BookingTypeItem
            active={selectedCamp?.id === camp.id}
            setSelected={handleSetSelected}
            camp={camp}
          />
        ))}
      </View>
    );
  }, [isDesktop, selectedCamp, camps]);

  useEffect(() => {
    getCamps();
  }, []);

  return (
    <View
      style={[
        {
          paddingVertical: 30,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isDesktop() ? 60 : 0,
          zIndex: 1,
        },
        isDesktop()
          ? {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              borderRadius: 18,
            }
          : {
              backgroundColor: COLORS.LIGHT_GREY,
              borderRadius: 10,
            },
      ]}
    >
      {!isDesktop() && (
        <Text
          style={{
            textTransform: 'uppercase',
            width: '100%',
            paddingHorizontal: 30,
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 18,
            marginBottom: 12,
            letterSpacing: 1,
            color: COLORS.BLACK,
          }}
        >
          Book a camp
        </Text>
      )}
      {bookingTypeRender}
      <View
        style={[
          {
            zIndex: 1,
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 30,
          },
          !isDesktop() && {
            paddingHorizontal: 20,
            width: '100%',
            flexDirection: 'column',
          },
        ]}
      >
        <BookingTimePicker
          checkInDay={checkInDay}
          checkOutDay={checkOutDay}
          setCheckInDay={setCheckInDay}
          setCheckOutDay={setCheckOutDay}
          isOvernight={selectedCamp?.room_availability}
        />
        <BookingPeoplePicker
          totalGuest={totalGuest}
          handleSetTotalGuest={handleSetTotalGuest}
          handleSetElderlyGuest={handleSetElderlyGuest}
          handleSetAdultGuest={handleSetAdultGuest}
        />
      </View>
      <View
        style={[
          {
            paddingHorizontal: 20,
          },
          !isDesktop() && {
            width: '100%',
          },
        ]}
      >
        <TouchableOpacity
          style={[
            {
              paddingHorizontal: 60,
              paddingVertical: 15,
              backgroundColor: COLORS.RED_PRIMARY,
              borderRadius: 16,
            },
            isDesktop()
              ? {
                  marginBottom: -60,
                }
              : {},
          ]}
          onPress={handlePressBook}
        >
          <Text
            style={{
              textTransform: 'uppercase',
              fontSize: 19,
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.WHITE,
              textAlign: 'center',
            }}
          >
            BOOK NOW
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pickCalendarWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
    position: 'relative',
    paddingVertical: 10,
  },
  pickCalendarIcon: {
    width: 29,
    height: 22,
    marginRight: 10,
    resizeMode: 'contain',
  },
  dateText: {
    fontSize: 16,
    fontFamily: FONTS.AvenirMedium,
    color: COLORS.RED_PRIMARY,
    textTransform: 'uppercase',
  },
});
