import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  Animated,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
//import { FontAwesome } from '@expo/vector-icons'; // Assuming you use FontAwesome icons, you may need to install it

const ArrowButton: FC<{
  active: boolean;
  arrow?: 'left' | 'right';
  onPress: () => void;
}> = ({ arrow = 'left', onPress, active = false }) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const renderContent = useMemo(() => {
    return (
      <TouchableOpacity
        onPress={() => {
          onPress();
        }}
      >
        <Image
          source={
            arrow == 'left'
              ? require('../../assets/left-arrow.png')
              : require('../../assets/right-arrow.png')
          }
          style={[
            {
              width: 14,
              height: 15,
              tintColor: isDesktop()
                ? isHover
                  ? COLORS.RED_PRIMARY
                  : COLORS.GREY
                : COLORS.BLACK,
              marginHorizontal: isDesktop() ? 20 : 6,
            },
            active && {
              tintColor: COLORS.WHITE,
            },
          ]}
        />
      </TouchableOpacity>
    );
  }, [arrow, isDesktop, isHover, onPress, active]);

  if (isDesktop()) {
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {renderContent}
      </div>
    );
  }

  return <>{renderContent}</>;
};

const MonthSwitcher: FC<{
  onChangeDate: (date: Date) => void;
  active: boolean;
}> = ({ onChangeDate, active = false }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const isDesktop = useIsDesktop();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      const prevMonth = newDate.getMonth() - 1;
      newDate.setMonth(prevMonth);
      onChangeDate(new Date(newDate));
      return newDate;
    });
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      const nextMonth = newDate.getMonth() + 1;
      newDate.setMonth(nextMonth);
      onChangeDate(new Date(newDate));
      return newDate;
    });
  };

  return (
    <View
      style={[
        styles.container,
        !isDesktop() && { backgroundColor: COLORS.WHITE },
        active && {
          backgroundColor: COLORS.RED_PRIMARY,
        },
      ]}
    >
      <ArrowButton
        active={active}
        onPress={() => {
          goToPreviousMonth();
        }}
      />
      <TouchableOpacity
        onPress={() => {
          onChangeDate(currentDate);
        }}
      >
        <Text
          style={[
            styles.monthYearText,
            !isDesktop() && { color: COLORS.BLACK },
            active && { color: COLORS.WHITE },
          ]}
        >{`${month} ${year}`}</Text>
      </TouchableOpacity>
      <ArrowButton
        active={active}
        onPress={() => {
          goToNextMonth();
        }}
        arrow="right"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: COLORS.LIGHT_GREY,
    borderRadius: 8,
  },
  monthYearText: {
    fontSize: 16,
    color: COLORS.GREY,
    fontFamily: FONTS.AvenirMedium,
  },
});

export default MonthSwitcher;
