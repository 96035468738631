import { FC, useCallback } from 'react';
import { IActivities } from '../../../types/activities.types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { NavigationService } from '../../../services/navigation';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Button } from '../../../components/Button';

interface ActivityItemProps {
  item: IActivities;
}

const ActivityItem: FC<ActivityItemProps> = ({ item }) => {
  const isDesktop = useIsDesktop();

  const handleRedirectDetail = useCallback(() => {
    NavigationService.push('Root', {
      screen: 'MenuTab',
      params: {
        screen: 'MyActivitiesDetail',
      },
    });
  }, []);

  return (
    <View>
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 20,
          letterSpacing: 1.7,
          color: COLORS.BLACK,
          textTransform: 'uppercase',
          borderBottomWidth: 1,
          borderBottomColor: COLORS.GREY,
          paddingBottom: 15,
          marginBottom: 15,
        }}
        onPress={() => {
          handleRedirectDetail();
        }}
      >
        {item.title}
      </Text>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '500',
          fontSize: 16,
          color: COLORS.GREY,
          marginBottom: 10,
        }}
      >
        {item.date}
      </Text>
      <View style={{ flexDirection: isDesktop() ? 'row' : 'column', gap: 15 }}>
        <TouchableOpacity
          onPress={() => {
            handleRedirectDetail();
          }}
        >
          <Image
            source={item.img}
            style={{
              width: isDesktop() ? 312 : '100%',
              height: 175,
              borderRadius: 18,
            }}
          />
        </TouchableOpacity>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '800',
              fontSize: 19,
              letterSpacing: 1,
              marginBottom: 10,
              color: COLORS.BLACK,
            }}
            onPress={() => {
              handleRedirectDetail();
            }}
          >
            {item.name}
          </Text>
          <View
            style={{
              width: 58,
              height: 2,
              backgroundColor: COLORS.RED_PRIMARY,
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 15,
              color: COLORS.GREY,
              letterSpacing: 1,
              marginBottom: 10,
            }}
          >
            {item.other}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                borderRadius: 18,
                paddingVertical: 2,
                paddingHorizontal: 5,
                backgroundColor: COLORS.LIGHT_GREY,
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={[
                  {
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: isDesktop() ? 15 : 12,
                    textAlign: 'center',
                    color: COLORS.BLACK,
                  },
                  !isDesktop() && {},
                ]}
              >
                {item.hour}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 15,
              color: COLORS.GREY,
              letterSpacing: 1,
              marginBottom: 10,
            }}
          >
            {item.place}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: isDesktop() ? 15 : 8,
              alignItems: 'center',
            }}
          >
            <Button
              text="Read more"
              className=""
              style={{
                borderRadius: 12,
                paddingHorizontal: isDesktop() ? 40 : 30,
                paddingVertical: isDesktop() ? 7 : 8,
                backgroundColor: COLORS.RED_PRIMARY,
              }}
              textStyle={{
                textTransform: 'uppercase',
                fontFamily: FONTS.AvenirMedium,
                fontWeight: isDesktop() ? '800' : '500',
                fontSize: isDesktop() ? 16 : 16,
                textAlign: 'center',
                color: COLORS.WHITE,
                letterSpacing: 2,
              }}
              arrowRight={false}
              onPress={() => {
                handleRedirectDetail();
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ActivityItem;
