import { BaseApiResponse } from '../../types/api.types';
import { IChatRoom } from '../../types/chat.types';
import instance from './axios';

const ENDPOINTS = {
  CHAT_ROOM: '/chat-rooms',
};

const getChatRoom = () => {
  return instance.get<BaseApiResponse>(ENDPOINTS.CHAT_ROOM);
};

const getChatRoomDetail = (id: number) => {
  return instance.get<{ data: IChatRoom }>(ENDPOINTS.CHAT_ROOM + '/' + id);
};

const getChatRoomMessages = (id: number, page: number = 1) => {
  return instance.get<BaseApiResponse>(
    ENDPOINTS.CHAT_ROOM + '/' + id + '/messages',
    {
      params: {
        page,
      },
    }
  );
};

const postChatRoomRead = (id: number) => {
  return instance.post<BaseApiResponse>(
    ENDPOINTS.CHAT_ROOM + '/' + id + '/read'
  );
};

const postCreateChatRoom = () => {
  return instance.post<BaseApiResponse>(ENDPOINTS.CHAT_ROOM);
};

const ChatRoomApi = {
  getChatRoom,
  getChatRoomDetail,
  getChatRoomMessages,
  postChatRoomRead,
  postCreateChatRoom,
};

export default ChatRoomApi;
