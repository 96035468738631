import { FC } from 'react';
import { Text, View } from 'react-native';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { COLORS, FONTS } from '../../../constants';
import { IBookingState } from '../types';
import dayjs from 'dayjs';
import { convertToAmPm } from '../../../services/utils';
import { ICamp } from '../../../types/camp.types';

type DatetimeInfoProps = {
  state: IBookingState;
  booking: ICamp;
};

const DatetimeInfo: FC<DatetimeInfoProps> = ({ state, booking }) => {
  const isDesktop = useIsDesktop();
  return (
    <View
      style={[
        {
          backgroundColor: '#f9f2f1',
          borderRadius: 18,
          marginBottom: 30,
        },
        isDesktop()
          ? {
              flexDirection: 'row',
              paddingHorizontal: 30,
              paddingTop: 20,
              paddingBottom: 20,
            }
          : {
              flexDirection: 'column',
              gap: 20,
              padding: 16,
            },
      ]}
    >
      <View
        style={[
          isDesktop() && {
            flex: 0.333333,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            letterSpacing: 1,
            marginBottom: 15,
            color: COLORS.BLACK,
          }}
        >
          Check In:
        </Text>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
            marginBottom: 13,
          }}
        >
          {dayjs(new Date(state.start_date)).format('ddd DD MMMM YYYY')}
        </Text>

        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              paddingHorizontal: 13,
              paddingVertical: 8,
              borderRadius: 18,
              backgroundColor: COLORS.LIGHT_GREY,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 14,
                fontWeight: '500',
                color: COLORS.BLACK,
              }}
            >
              {booking.start_time}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[
          isDesktop() && {
            flex: 0.333333,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            letterSpacing: 1,
            marginBottom: 15,
            color: COLORS.BLACK,
          }}
        >
          Check Out:
        </Text>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
            marginBottom: 13,
          }}
        >
          {dayjs(new Date(state.end_date)).format('ddd DD MMMM YYYY')}
        </Text>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              paddingHorizontal: 13,
              paddingVertical: 8,
              borderRadius: 18,
              backgroundColor: COLORS.LIGHT_GREY,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 14,
                fontWeight: '500',
                color: COLORS.BLACK,
              }}
            >
              {booking.end_time}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[
          isDesktop() && {
            flex: 0.333333,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            letterSpacing: 1,
            marginBottom: 15,
            color: COLORS.BLACK,
          }}
        >
          Number of People:
        </Text>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
            marginBottom: 13,
          }}
        >
          {state.total_guest} People
        </Text>
      </View>
    </View>
  );
};

export default DatetimeInfo;
