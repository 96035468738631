import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as Keychain from '../../services/keychain';
import { IS_ANDROID, IS_WEB, MAX_WIDTH } from '../../constants/platform';
import { RootState } from '../../services/redux/store';
import { loginUser, updateUser } from '../../services/redux/user';
import { post } from '../../services/api/http';
import { API_URI } from '../../constants/env';
import { AuthProps, IAuthState } from './types';
import { Alert } from '../../services/alert';
import { ScrollView, View } from 'react-native';
import { Footer } from '../../components/Footer';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { AuthApi } from '../../services/api/auth.api';
import { logout, setCredential } from '../../services/redux/reducers/AuthSlice';
import { NavigationService } from '../../services/navigation';
import { updateCart } from '../../services/redux/reducers/CartSlice';
import { setHeaderConfigAxios } from '../../services/api/axios';
import { setIsShowStart } from '../../services/redux/reducers/SwitchECardSlice';
import CreateAccount from './components/CreateAccount';
import { IFormData } from '../../components/FormHelper/FormHelper.types';
import VerifyCode from './components/VerifyCode';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { Loading } from '../../components';
import { ForgotUsername } from './components/ForgotUsername';

const initialAuthState: IAuthState = {
  mode: 'login',
  email: '',
  mobile: '',
};

export const Auth: FC<AuthProps> = ({ navigation, route }) => {
  const [state, setState] = useState(initialAuthState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const updateState = useCallback(
    (state: Partial<IAuthState>) => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({
          y: 0,
          animated: false,
        });
      }
      setState((prevState) => ({ ...prevState, ...state }));
    },
    [setState]
  );
  const isDesktop = useIsDesktop();

  const onSubmitForm = useCallback(
    async (data: IFormData) => {
      if (state.mode == 'login') {
        if (data.email && data.password) {
          setIsLoading(true);
          var loginData: IFormData = {
            email: data.email,
            password: data.password,
          };
          AuthApi.login(loginData)
            .then(async (authData) => {
              if (authData.data.meta.success == true) {
                setIsLoading(false);

                dispatch(
                  setCredential({
                    access_token: authData.data.data.access_token,
                    user: authData.data.data.user,
                  })
                );
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'HomePage',
                  },
                });
              }
            })
            .catch((error) => {
              Alert.alert('Error', error.message ?? error.meta.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else if (state.mode === 'create') {
        let phone = data.phone.split(',');

        data = {
          ...data,
          phone: phone.length ? phone[1] : '',
          phone_country: phone.length ? phone[0] : '',
        };

        setIsLoading(true);
        AuthApi.register(data)
          .then((value) => {
            updateState({
              mode: data.otp_type === 'email' ? 'verifyEmail' : 'verifySms',
              email: data.email,
              mobile: data.sms,
            });
          })
          .catch((error) => {
            Alert.alert('Error', error.meta.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (state.mode == 'forgotPassword') {
        if (data.email !== '') {
          setIsLoading(true);
          AuthApi.forgotPassword({ email: data.email })
            .then((value) => {
              updateState({
                mode: 'resetPassword',
                email: data.email,
              });
            })
            .catch((error) => {
              console.log(error);
              Alert.alert('Error', error.meta.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else if (state.mode === 'forgotUsername') {
        if (data.email !== '') {
          setIsLoading(true);
          AuthApi.forgotUsername({ email: data.email })
            .then((value) => {
              Alert.alert('Success', value.data.meta.message);
              updateState({
                mode: 'login',
                email: data.email,
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.meta.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else if (
        state.mode == 'resetPassword' ||
        state.mode == 'verifySms' ||
        state.mode == 'verifyEmail'
      ) {
        if (!data.password || data.password === '') {
          Alert.alert('Error', 'Password is empty');
        } else if (data.password !== data.password_confirmation) {
          Alert.alert('Error', "Passwords don't match");
        } else if (data.password.length < 8) {
          Alert.alert('Error', 'Password must be at least 8 characters long');
        } else if (!/[A-Z]/.test(data.password)) {
          Alert.alert(
            'Error',
            'At least one capital letter is required in the password'
          );
        } else if (!/[0-9\W_]/.test(data.password)) {
          Alert.alert(
            'Error',
            'At least one number or special character is required in the password'
          );
        } else {
          setIsLoading(true);
          if (state.mode == 'resetPassword') {
            AuthApi.resetPassword(data)
              .then((value) => {
                Alert.alert(
                  'Success',
                  'The password has been successfully updated. You may now log in.'
                );
                updateState({
                  mode: 'login',
                });
              })
              .catch((error) => {
                Alert.alert('Error', error.meta.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            AuthApi.registerValidation(data)
              .then((value) => {
                Alert.alert(
                  'Success',
                  'The password has been successfully updated. You may now log in.'
                );
                updateState({
                  mode: 'login',
                });
              })
              .catch((error) => {
                Alert.alert('Error', error.meta.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      }
    },
    [state, updateState, dispatch]
  );

  useEffect(() => {
    if (route?.params?.screen) {
      updateState({ mode: route.params.screen });
    }
  }, [route.params, updateState]);

  return (
    <ScrollView ref={scrollViewRef}>
      <View
        style={[
          {
            paddingTop: isDesktop() ? 40 : 0,
            paddingHorizontal: isDesktop() ? '5%' : 25,
            minHeight: 700,
          },
          isDesktop() && {
            maxWidth: MAX_WIDTH,
            width: '100%',
            alignSelf: 'center',
          },
        ]}
      >
        {state.mode === 'login' && (
          <Login
            state={state}
            setState={updateState}
            onSubmitHandler={onSubmitForm}
          />
        )}
        {state.mode === 'create' && (
          <CreateAccount
            state={state}
            setState={updateState}
            onSubmitHandler={onSubmitForm}
          />
        )}
        {(state.mode === 'resetPassword' ||
          state.mode === 'verifyEmail' ||
          state.mode === 'verifySms') && (
          <VerifyCode
            state={state}
            setState={updateState}
            onSubmitHandler={onSubmitForm}
          />
        )}
        {state.mode === 'forgotPassword' && (
          <ForgotPassword
            state={state}
            setState={updateState}
            onSubmitHandler={onSubmitForm}
          />
        )}
        {state.mode === 'forgotUsername' && (
          <ForgotUsername
            state={state}
            setState={updateState}
            onSubmitHandler={onSubmitForm}
          />
        )}
      </View>
      <Footer />
      {isLoading && <Loading isFullScreen={true} />}
    </ScrollView>
  );
};
