import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Calendar } from '../../../components/Calendar';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { ICamp, IRoom, IRoomInput } from '../../../types/camp.types';
import { IBookingState } from '../types';
import BookingTimePicker from '../../../components/BookingTimePicker/BookingTimePicker';
import { Alert } from '../../../services/alert';
import dayjs from 'dayjs';
import { DropDownItem } from '../../../models/DropDownItem';
import DropDown from '../../../components/DropDown';
import { authStyles } from '../../Auth/styles';
import CampApi from '../../../services/api/camp.api';
import { Loading } from '../../../components';
import { RoomInput } from './RoomInput';
import DropDownSelect from '../../../components/DropDownSelect';

type Step1Props = {
  onSubmit?: () => void;
  booking: ICamp;
  state: IBookingState;
  setState: (state: Partial<IBookingState>) => void;
};

const Step1: FC<Step1Props> = ({ onSubmit, booking, state, setState }) => {
  const isDesktop = useIsDesktop();
  const [checkInDay, setCheckInDay] = useState(
    dayjs(new Date(state.start_date)).format('YYYY-MM-DD')
  );
  const [checkOutDay, setCheckOutDay] = useState(
    dayjs(new Date(state.end_date)).format('YYYY-MM-DD')
  );
  const [elderly, setElderly] = useState<number>(
    state.elderly !== undefined ? state.elderly : 0
  );
  const [adult, setAdult] = useState<number>(
    state.adult !== undefined ? state.adult : 0
  );
  const [roomData, setRoomData] = useState<IRoomInput[]>(
    booking.rooms.map((item) => ({
      room_id: item.id,
      quantity: 0,
      is_additional_bed: false,
    }))
  );
  const [isLoadingChecking, setIsLoadingChecking] = useState<boolean>(false);

  const totalGuest = useMemo(() => {
    let elderlyNum = elderly ? Number(elderly) : 0;
    let adultNum = elderly ? Number(adult) : 0;

    return elderlyNum + adultNum;
  }, [elderly, adult]);

  const peopleSelect = useMemo(() => {
    const arr: DropDownItem[] = [
      {
        label: 'No People',
        value: 0,
      },
    ];
    for (let i = 1; i <= 100; i++) {
      arr.push({
        label: i + ' ' + 'People',
        value: i,
      });
    }
    return arr;
  }, []);

  const handleSubmit = useCallback(() => {
    const startDate = new Date(checkInDay);
    const endDate = new Date(checkOutDay);

    if (endDate < startDate) {
      Alert.alert(
        'Error',
        'Please select an end date greater than or equal to the start date.'
      );
    } else if (elderly === undefined) {
      Alert.alert(
        'Error',
        'Please enter number of guests 55 years of age or above, or with disabilities.'
      );
    } else if (adult === undefined) {
      Alert.alert('Error', 'Please enter number of all other guests.');
    } else {
      setIsLoadingChecking(true);
      CampApi.postCheckRoomAvailable({
        rooms: roomData.filter((room) => room.quantity > 0),
        start_time: checkInDay,
        end_time: checkOutDay,
        adult: Number(adult),
        elderly: Number(elderly),
      })
        .then((res) => {
          setState({
            start_date: checkInDay,
            end_date: checkOutDay,
            rooms: roomData.filter((room) => room.quantity > 0),
            adult: Number(adult),
            elderly: Number(elderly),
            total_guest: totalGuest.toString(),
          });

          if (onSubmit) {
            onSubmit();
          }
        })
        .catch((error) => {
          if (error.data && error.data.length > 1) {
            let errorMessage = `You selected ${totalGuest} people on your booking. Please select room by one of these options:`;
            let options = error.data;

            options.forEach((option: any) => {
              errorMessage = errorMessage + `\n- ${option.description}`;
            });

            Alert.alert('Error', errorMessage);
          } else {
            Alert.alert('Error', error.meta.message);
          }
        })
        .finally(() => {
          setIsLoadingChecking(false);
        });
    }
  }, [
    adult,
    checkInDay,
    checkOutDay,
    elderly,
    totalGuest,
    setState,
    onSubmit,
    roomData,
  ]);

  const handleChangeRoomInput = (
    roomId: number,
    name: 'quantity' | 'is_additional_bed',
    value: any
  ) => {
    setRoomData((prevData) =>
      prevData.map((data) => {
        if (data.room_id === roomId) {
          return {
            ...data,
            [name]: value,
          };
        } else {
          return data;
        }
      })
    );
  };

  useEffect(() => {
    if (state.rooms && state.rooms.length > 0) {
      setRoomData(
        booking.rooms.map((room) => {
          const roomFind = state.rooms.find((item) => item.room_id === room.id);

          return {
            room_id: room.id,
            quantity: roomFind?.quantity ?? 0,
            is_additional_bed: roomFind?.is_additional_bed ?? false,
          };
        })
      );
    }
  }, [setRoomData, state.rooms, booking.rooms]);

  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 40,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          gap: 20,
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: 50,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flex: isDesktop() ? 0.48 : 1,
          }}
        >
          <View>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '800',
                fontSize: 19,
                color: COLORS.BLACK,
                textTransform: 'uppercase',
                letterSpacing: 1.5,
                textAlign: 'left',
                marginBottom: 12,
              }}
            >
              {booking.name}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  paddingHorizontal: 11,
                  paddingVertical: 7,
                  borderRadius: 18,
                  backgroundColor: COLORS.LIGHT_GREY,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 14,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  {`${booking.start_time} ~ ${booking.end_time}`}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  width: 120,
                  height: 2,
                  backgroundColor: COLORS.RED_PRIMARY,
                  marginBottom: 12,
                }}
              />
            </View>
            <Text
              style={{
                fontFamily: FONTS.AvenirRegular,
                fontSize: isDesktop() ? 20 : 18,
                fontWeight: 'normal',
                color: COLORS.BLACK,
                lineHeight: 26,
              }}
            >
              {booking.description}
            </Text>
          </View>
        </View>
        {isDesktop() && booking.image && (
          <View
            style={{
              flex: 0.48,
            }}
          >
            <Image
              source={{ uri: booking.image }}
              style={{ width: '100%', height: 267, borderRadius: 18 }}
            />
          </View>
        )}
      </View>
      <View>
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../../assets/calendar-white.png')}
            style={{
              width: 20,
              height: 21,
              marginRight: 8,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
            }}
          >
            {state.is_redirect_from_home
              ? 'Selected Booking Date:'
              : 'Select Booking Date:'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginBottom: 20, zIndex: 5 }}>
          <BookingTimePicker
            checkInDay={checkInDay}
            checkOutDay={checkOutDay}
            setCheckInDay={setCheckInDay}
            setCheckOutDay={setCheckOutDay}
            isOvernight={booking.room_availability}
          />
        </View>
        {booking.room_availability && (
          <View style={{ marginBottom: 20, zIndex: 4 }}>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 19,
                fontWeight: '800',
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 15,
              }}
            >
              Select Room:
            </Text>
            <View
              style={{
                flexDirection: 'column',
                gap: 20,
                flexWrap: 'wrap',
              }}
            >
              {booking.rooms &&
                booking.rooms.map((item, index) => {
                  const roomFind = roomData.find(
                    (room) => room.room_id === item.id
                  );

                  return (
                    <RoomInput
                      zIndex={booking.rooms.length - index}
                      room={item}
                      inputData={roomFind}
                      onChangeInput={handleChangeRoomInput}
                    />
                  );
                })}
            </View>
          </View>
        )}

        <View style={{ marginBottom: 20, zIndex: 1 }}>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
              marginBottom: 20,
            }}
          >
            Number of Guests 55 years of age or above, or with disabilities:
          </Text>
          <DropDownSelect
            zIndex={2}
            placeholder={'Select No. Of People'}
            items={peopleSelect}
            dropdownStyle={{
              borderColor: '#DDDDE3',
              width: isDesktop() ? 400 : '100%',
              borderRadius: 6,
              padding: 14,
              marginBottom: 15,
            }}
            textStyle={{
              color: COLORS.GREY,
              fontSize: 16,
              fontFamily: FONTS.AvenirMedium,
              letterSpacing: 1,
            }}
            dropDownContainerTextStyle={{
              color: COLORS.DARKER_GREY,
            }}
            buttonStyle={{
              tintColor: COLORS.DARKER_GREY,
            }}
            setValues={(value) => {
              setElderly(Number(value));
            }}
            value={elderly}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirRegular,
              fontSize: 15,
              fontWeight: 'normal',
              color: COLORS.GREY,
              letterSpacing: 1,
            }}
          >
            {
              '(Anyone aged 55 or above, or holding a "Registration Card for People with Disabilities" can apply. Each eligible person can bring up to four relatives, friends or non-disabled persons under the age of 55 to participate. All Campers must be Hong Kong residents. (based on 1:4 ratio)'
            }
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: isDesktop() ? 'row' : 'column',
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 19,
                fontWeight: '800',
                color: COLORS.BLACK,
                letterSpacing: 1,
              }}
            >
              {'Number of all other Guests'}
            </Text>
          </View>
          <DropDownSelect
            zIndex={1}
            placeholder={'Select No. Of People'}
            items={peopleSelect}
            dropdownStyle={{
              borderColor: '#DDDDE3',
              width: isDesktop() ? 400 : '100%',
              borderRadius: 6,
              padding: 14,
              marginBottom: 15,
            }}
            textStyle={{
              color: COLORS.GREY,
              fontSize: 16,
              fontFamily: FONTS.AvenirMedium,
              letterSpacing: 1,
            }}
            dropDownContainerTextStyle={{
              color: COLORS.DARKER_GREY,
            }}
            buttonStyle={{
              tintColor: COLORS.DARKER_GREY,
            }}
            setValues={(value) => {
              setAdult(Number(value));
            }}
            value={adult}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 15,
              fontWeight: 'normal',
              color: COLORS.GREY,
              letterSpacing: 1,
            }}
          >
            {' (3 years above and below 55):'}
          </Text>
        </View>
        <View
          style={[
            isDesktop()
              ? {
                  marginBottom: 70,
                }
              : {
                  marginBottom: 20,
                  borderBottomWidth: 1,
                  borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
                },
          ]}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
              marginBottom: 20,
            }}
          >
            Total Number Of Guests: {totalGuest}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <Button
            style={[
              styles.buttonStyle,
              !isDesktop() && { width: '100%', paddingVertical: 30 },
            ]}
            className=""
            arrowRight={false}
            textStyle={[
              styles.textButtonStyle,
              !isDesktop() && { fontSize: 19 },
            ]}
            text="Next Step"
            onPress={() => {
              handleSubmit();
            }}
          />
        </View>
      </View>
      {isLoadingChecking && <Loading isFullScreen />}
    </View>
  );
};

export default Step1;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
