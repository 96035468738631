import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { BaseApiResponse } from '../../types/api.types';
import { ICamp } from '../../types/camp.types';
import instance from './axios';

type ActivitiesProps = {
  camp_id?: number;
  start_time?: string;
  end_time?: string;
};

const ENDPOINTS = {
  ACTIVITIES: '/activities',
};

const getActivities = (props: ActivitiesProps) => {
  return instance.get<BaseApiResponse>(ENDPOINTS.ACTIVITIES, {
    params: props,
  });
};

const getActivity = (id: number) => {
  return instance.get<BaseApiResponse>(ENDPOINTS.ACTIVITIES + '/' + id);
};

const ActivityApi = {
  getActivities,
  getActivity,
};

export default ActivityApi;
