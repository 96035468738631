import { FC } from 'react';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { TouchableOpacity } from 'react-native';

type StepBarProps = {
  step: number;
  setStep: (step: number) => void;
  onSelectStep1?: () => void;
  onSelectStep2?: () => void;
  onSelectStep3?: () => void;
};

const StepBar: FC<StepBarProps> = ({
  step,
  setStep,
  onSelectStep1,
  onSelectStep2,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <View
      style={{
        flexDirection: 'row',
        borderRadius: 8,
        backgroundColor: COLORS.LIGHT_GREY,
        marginBottom: 30,
      }}
    >
      <TouchableOpacity
        style={[
          {
            width: '33%',
            borderRadius: 8,
            paddingVertical: 10,
          },
          step === 1 && {
            backgroundColor: COLORS.RED_PRIMARY,
          },
        ]}
        onPress={() => {
          if (step > 1) {
            setStep(1);
            if (onSelectStep1) {
              onSelectStep1();
            }
          }
        }}
      >
        <Text
          style={[
            {
              textAlign: 'center',
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              letterSpacing: 0.7,
              color: COLORS.GREY,
            },
            step === 1 && {
              color: COLORS.WHITE,
            },
          ]}
        >
          Step 1
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          {
            width: '33%',
            borderRadius: 8,
            paddingVertical: 10,
          },
          step === 2 && {
            backgroundColor: COLORS.RED_PRIMARY,
          },
        ]}
        onPress={() => {
          if (step > 2) {
            setStep(2);
            if (onSelectStep2) {
              onSelectStep2();
            }
          }
        }}
      >
        <Text
          style={[
            {
              textAlign: 'center',
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              letterSpacing: 0.7,
              color: COLORS.GREY,
            },
            step === 2 && {
              color: COLORS.WHITE,
            },
          ]}
        >
          Step 2
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          {
            width: '34%',
            borderRadius: 8,
            paddingVertical: 10,
          },
          step === 3 && {
            backgroundColor: COLORS.RED_PRIMARY,
          },
        ]}
      >
        <Text
          style={[
            {
              textAlign: 'center',
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              letterSpacing: 0.7,
              color: COLORS.GREY,
            },
            step === 3 && {
              color: COLORS.WHITE,
            },
          ]}
        >
          Step 3
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default StepBar;
