import { FC } from 'react';
import { IDish } from '../../../types/dishes.types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { NavigationService } from '../../../services/navigation';
import { COLORS, FONTS } from '../../../constants';
import { Button } from '../../../components/Button';
import { convertPrice } from '../../../services/utils';

interface ExtraDishItemProps {
  item: IDish;
  onAddToCart?: (item: IDish) => void;
}

const ExtraDishItem: FC<ExtraDishItemProps> = ({ item, onAddToCart }) => {
  return (
    <View
      style={{
        width: '100%',
      }}
      key={item.id}
    >
      <TouchableOpacity
        style={{
          marginBottom: 7,
        }}
        onPress={() => {
          if (onAddToCart) {
            onAddToCart(item);
          }
        }}
      >
        <Image
          source={
            item.image
              ? {
                  uri: item.image,
                }
              : require('../../../assets/dish-image.png')
          }
          style={[
            {
              width: '100%',
              borderRadius: 18,
              height: 170,
            },
          ]}
        />
      </TouchableOpacity>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '800',
          fontSize: 19,
          color: COLORS.BLACK,
          marginBottom: 8,
        }}
      >
        {item.name}
      </Text>
      <View
        style={{
          width: 60,
          height: 2,
          marginBottom: 8,
          backgroundColor: COLORS.RED_PRIMARY,
        }}
      />
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontSize: 16,
          fontWeight: '500',
          color: COLORS.GREY,
          marginBottom: 10,
        }}
      >
        {item.description}
      </Text>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '800',
          fontSize: 19,
          color: COLORS.RED_PRIMARY,
          marginBottom: 8,
        }}
      >
        {`HKD ${convertPrice(item.price)}`}
      </Text>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Button
          style={{
            paddingHorizontal: 20,
            paddingVertical: 10,
            backgroundColor: COLORS.RED_PRIMARY,
            borderRadius: 16,
          }}
          className=""
          text="Add to Cart"
          arrowRight={false}
          textStyle={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.WHITE,
          }}
          onPress={() => {
            if (onAddToCart) {
              onAddToCart(item);
            }
          }}
        />
      </View>
    </View>
  );
};

export default ExtraDishItem;
