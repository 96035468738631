import { FC, useCallback, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import ProductTable from './ProductTable';
import { StyleSheet } from 'react-native';
import { Button } from '../../../components/Button';
import { NavigationService } from '../../../services/navigation';
import { ICamp } from '../../../types/camp.types';
import { IBookingState } from '../types';
import dayjs from 'dayjs';
import DatetimeInfo from './DatetimeInfo';
import { convertToFullPhone } from '../../../services/utils/phoneConvert';
import { ICampBooking } from '../../../types/bookings.types';
import CampApi from '../../../services/api/camp.api';
import { Loading } from '../../../components';
import { RootState } from '../../../services/redux';
import { useSelector } from 'react-redux';

type ConfirmationBookingProps = {
  booking: ICamp;
  state: IBookingState;
};

const ConfirmationBooking: FC<ConfirmationBookingProps> = ({
  booking,
  state,
}) => {
  const isDesktop = useIsDesktop();
  const { user } = useSelector((redux: RootState) => redux.authReducer);
  const [campBooking, setCampBooking] = useState<ICampBooking>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getBookingDetail = useCallback(async () => {
    setIsLoading(true);
    if (state.booking_id) {
      CampApi.getBookingDetail(state.booking_id)
        .then((res) => {
          setCampBooking(res.data.data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [state.booking_id]);

  useEffect(() => {
    getBookingDetail();
  }, [getBookingDetail]);

  return (
    <View
      style={{
        paddingHorizontal: 40,
        paddingTop: 20,
        backgroundColor: '#fdfdfd',
      }}
    >
      {!isLoading && campBooking && (
        <View>
          <View
            style={{
              borderBottomColor: '#EBEBF0',
              borderBottomWidth: 1,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
                color: COLORS.BLACK,
                textTransform: 'uppercase',
                textAlign: 'center',
                marginBottom: 10,
                letterSpacing: 2,
              }}
            >
              Thanks for your camp booking
            </Text>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 20,
                color: COLORS.BLACK,
                textAlign: 'center',
                marginBottom: 30,
              }}
            >
              Your transaction was successful!
            </Text>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 30,
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  Date of Payment
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  {dayjs(new Date(campBooking.created_at)).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  Order #
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  {state.booking_id}
                </Text>
              </View>
            </View>
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
                textTransform: 'uppercase',
                color: COLORS.BLACK,
                letterSpacing: 2,
                marginBottom: 15,
              }}
            >
              {booking.name}
            </Text>
          </View>
          <View
            style={[
              {
                borderRadius: 18,
                width: '100%',
              },
              isDesktop()
                ? {
                    elevation: 5,
                    shadowColor: '#F0F0F0',
                    shadowOffset: { width: 0, height: 12 },
                    shadowOpacity: 1,
                    shadowRadius: 32,
                    paddingTop: 25,
                    paddingHorizontal: 25,
                    marginBottom: 150,
                  }
                : {
                    backgroundColor: COLORS.WHITE,
                    paddingTop: 20,
                  },
            ]}
          >
            <DatetimeInfo state={state} booking={booking} />
            <ProductTable state={state} booking={booking} />
            <View
              style={[
                {
                  backgroundColor: '#f9f2f1',
                  borderRadius: 18,
                  marginBottom: 30,
                },
                isDesktop()
                  ? {
                      flexDirection: 'row',
                      padding: 20,
                      flexWrap: 'wrap',
                      gap: 15,
                    }
                  : {
                      flexDirection: 'column',
                      gap: 20,
                      padding: 16,
                    },
              ]}
            >
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Emergency Contact</Text>
                <Text style={styles.contentText}>{user?.email}</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Contact Number:</Text>
                <Text
                  style={styles.contentText}
                >{`Phone Number: ${convertToFullPhone(
                  user?.phone_country,
                  user?.phone
                )}`}</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Time of Payment</Text>
                <Text style={styles.contentText}>
                  {' '}
                  {dayjs(new Date(campBooking.created_at)).format('HH:mm A')}
                </Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Date of Payment</Text>
                <Text style={styles.contentText}>
                  {dayjs(new Date(campBooking.created_at)).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Amount Paid</Text>
                <Text style={styles.contentText}>{`$${
                  campBooking.total_in_cents / 100
                } HKD`}</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Booking ID</Text>
                <Text style={styles.contentText}>{state.booking_id}</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Payment Method</Text>
                <Text style={styles.contentText}>
                  {campBooking.payment_method.name}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 30,
              }}
            >
              <Button
                style={[
                  styles.buttonStyle,
                  !isDesktop() && { width: '100%', paddingVertical: 30 },
                ]}
                className=""
                arrowRight={false}
                textStyle={[
                  styles.textButtonStyle,
                  !isDesktop() && { fontSize: 19 },
                ]}
                text="Continue to home"
                onPress={() => {
                  NavigationService.push('Root', {
                    screen: 'MenuTab',
                    params: {
                      screen: 'HomePage',
                    },
                  });
                }}
              />
            </View>
          </View>
        </View>
      )}

      {isLoading && (
        <View style={{ marginBottom: 25 }}>
          <Loading />
        </View>
      )}
    </View>
  );
};

export default ConfirmationBooking;

const styles = StyleSheet.create({
  headerText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    letterSpacing: 1,
    marginBottom: 15,
    color: COLORS.BLACK,
  },
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
