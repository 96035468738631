import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  TextInput as TextInputRN,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Text } from 'react-native';
import { IDish } from '../../../types/dishes.types';
import ExtraDishItem from './ExtraDishItem';
import Swiper from 'react-native-web-swiper';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button';
import DatetimeInfo from './DatetimeInfo';
import { STYLE_VARS } from '../../../constants/style-vars';
import DropDown from '../../../components/DropDown';
import { ICamp, IMeal } from '../../../types/camp.types';
import { IBookingState } from '../types';
import { generateTimeArray } from '../../../services/utils';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import * as yup from 'yup';
import {
  FormComponent,
  FormStructure,
} from '../../../components/FormHelper/FormHelper.types';
import CampApi from '../../../services/api/camp.api';
import { Alert } from '../../../services/alert';
import MobileInput from '../../../components/MobileInput/MobileInput';
import { Loading } from '../../../components';
import dayjs from 'dayjs';
import RenderHTML from 'react-native-render-html';
import { convertMobileInputData } from '../../../services/utils/phoneConvert';
import ProductTable from './ProductTable';
import RoomInfor from './RoomInfor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import {
  addToCart,
  updateDishItems,
} from '../../../services/redux/reducers/CartSlice';

type Step2Props = {
  onSubmit?: () => void;
  booking: ICamp;
  state: IBookingState;
  setState: (state: Partial<IBookingState>) => void;
  scrollRef?: RefObject<ScrollView>;
  handleSetStep: (step: number) => void;
};

type PositionProps = {
  y: number;
  height: number;
};

const InCludedMealItem: FC<{
  name: string;
  meal: IMeal;
  mealWrapperStyle?: ViewStyle;
  mealTextStyle?: TextStyle;
  zIndex: number;
}> = ({ name, meal, mealWrapperStyle, mealTextStyle, zIndex }) => {
  const isDesktop = useIsDesktop();

  return (
    <View
      style={[
        styles.mealContainer,
        isDesktop()
          ? {
              width: 320,
            }
          : {
              width: '100%',
            },
      ]}
    >
      <View style={[styles.mealWrapper, mealWrapperStyle]}>
        <Text
          style={[
            styles.mealText,
            mealTextStyle,
            !isDesktop() && { fontSize: 17 },
          ]}
        >
          {name}
        </Text>
      </View>
      <View style={{ width: '60%' }}>
        <MealItem meal={meal} />
      </View>
    </View>
  );
};

const MealItem: FC<{ meal: IMeal }> = ({ meal }) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  return (
    <View
      style={{
        position: 'relative',
      }}
    >
      <TouchableOpacity
        style={{
          borderColor: '#DDDDE3',
          paddingHorizontal: 15,
          paddingVertical: 9,
          borderRadius: 16,
          borderWidth: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onPress={() => {
          setShowContent(!showContent);
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
          }}
        >
          {meal.name}
        </Text>
        <Image
          source={require('../../../assets/right-arrow.png')}
          style={{
            width: 11,
            height: 11,
            tintColor: COLORS.DARK_GREY,
          }}
        />
      </TouchableOpacity>
      {showContent && meal.content && (
        <View
          style={{
            position: 'absolute',
            right: 0,
            top: 50,
          }}
        >
          <View
            style={{
              padding: 10,
              paddingTop: 0,
              paddingBottom: 0,
              shadowColor: '#000000',
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowOpacity: 0.5,
              shadowRadius: 3,
              elevation: 3,
              backgroundColor: '#ffffff',
              borderRadius: 5,
              width: 300,
              flexDirection: 'column',
            }}
          >
            <RenderHTML
              contentWidth={300}
              source={{ html: meal.content }}
              tagsStyles={{
                body: {
                  color: COLORS.BLACK,
                  fontFamily: FONTS.AvenirMedium,
                },
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
};

const Step2: FC<Step2Props> = ({
  onSubmit,
  booking,
  setState,
  state,
  scrollRef,
  handleSetStep,
}) => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const [dinnerMeal, setDinnerMeal] = useState<IMeal>();
  const [dishes, setDishes] = useState<IDish[]>([]);
  const [selectedDishes, setSelectedDishes] = useState<
    {
      item: IDish;
      amount: number;
    }[]
  >([]);
  const [isLoadingDishes, setIsLoadingDishes] = useState<boolean>(false);

  const { extraDishes } = useSelector((redux: RootState) => redux.cartReducer);

  const [bookingTimeYP, setBookingTimeYP] = useState<number>(0);
  const [bookingTimeHeight, setBookingTimeHeight] = useState<number>(0);
  const [bookingTimePosition, setBookingTimePosition] = useState<PositionProps>(
    { y: 0, height: 0 }
  );
  const [dinnerMealPosition, setDinnerMealPosition] = useState<PositionProps>({
    y: 0,
    height: 0,
  });
  const [vegetarianPosition, setVegetarianPosition] = useState<PositionProps>({
    y: 0,
    height: 0,
  });
  const [emerPosition, setEmerPosition] = useState<PositionProps>({
    y: 0,
    height: 0,
  });

  const handleScroll = (position: PositionProps) => {
    scrollRef?.current?.scrollTo({ y: position.y + position.height });
  };

  const initData = useMemo(() => {
    return {
      checkin_time: '',
      checkout_time: '',
      dinner_meal: state.dinner_meal,
      is_vegetarian: state.is_vegetarian,
      emer_first_name: state.emergency_first_name,
      emer_last_name: state.emergency_last_name,
      emer_phone: `${state.emergency_phone_country},${state.emergency_phone_number}`,
      emer_relationship: state.emergency_relationship,
    };
  }, [state]);

  const addOrUpdateDish = useCallback(
    (newDish: IDish) => {
      const existingDish = extraDishes.find((dish) => dish.id === newDish.id);

      if (existingDish) {
        let updatedDishes = extraDishes.map((item) => {
          if (item.id === existingDish.id) {
            return {
              ...item,
              amount: Number(item.amount) + 1,
            };
          } else {
            return item;
          }
        });
        dispatch(updateDishItems(updatedDishes));
      } else {
        dispatch(
          updateDishItems([
            ...extraDishes,
            {
              ...newDish,
              amount: 1,
            },
          ])
        );
      }
    },
    [dispatch, extraDishes]
  );

  const getDishes = useCallback(async () => {
    setIsLoadingDishes(true);
    CampApi.getDishes()
      .then((res) => {
        setDishes(res.data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingDishes(false);
      });
  }, []);

  const renderForm = useCallback((component: FormComponent) => {
    switch (component.type) {
      case 'dropdown':
        return (
          <View
            key={component.name}
            style={{ marginBottom: 10, zIndex: component.zIndex }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '800',
                fontSize: 15,
                color: COLORS.LIGHT_BLACK,
                letterSpacing: 1,
                marginBottom: 5,
              }}
            >
              {component.label}:
            </Text>
            <Field name={component.name}>
              {({ field, form }: FieldProps) => (
                <View style={{ width: 180 }}>
                  <DropDown
                    items={component.items || []}
                    placeholder={'Select time'}
                    dropdownStyle={{
                      borderColor: '#DDDDE3',
                      paddingVertical: 6,
                      borderRadius: 8,
                    }}
                    textStyle={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 16,
                      color: COLORS.GREY,
                    }}
                    dropDownContainerStyled={{
                      top: 36,
                      minHeight: 10,
                    }}
                    dropDownContainerTextStyle={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 14,
                      color: COLORS.GREY,
                    }}
                    setValues={(value) => {
                      form.setFieldValue(field.name, value.value);
                    }}
                  />
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          </View>
        );
      case 'mobilePhone':
        return (
          <Field name={component.name}>
            {({ field }: FieldProps) => (
              <View style={[{ width: '100%', marginBottom: 5 }]}>
                <MobileInput
                  setValues={(value) => {
                    field.onChange(field.name)(value);
                  }}
                  dropdownStyle={{
                    width: 70,
                    padding: 5,
                    paddingLeft: 5,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingEnd: 5,
                    borderRadius: 5,
                  }}
                  dropdownTextStyle={{
                    fontSize: 13,
                    marginRight: 0,
                  }}
                  buttonStyle={{
                    width: 10,
                    height: 6,
                  }}
                  textInputStyle={{
                    width: '100%',
                    paddingVertical: 6,
                    paddingHorizontal: 6,
                    height: 'auto',
                    fontSize: 14,
                  }}
                  initValue={field.value}
                />
                <ErrorMessage
                  name={field.name}
                  render={(errorMessage: string) => (
                    <Text style={{ color: 'red', marginTop: 5 }}>
                      {errorMessage}
                    </Text>
                  )}
                />
              </View>
            )}
          </Field>
        );
      default:
        return (
          <Field name={component.name}>
            {({ field }: FieldProps) => (
              <View>
                <TextInput
                  style={[
                    STYLE_VARS.textInputMini as TextStyle,
                    { marginBottom: 5, fontSize: 14 },
                  ]}
                  placeholder=""
                  value={field.value}
                  onChangeText={(text) => {
                    field.onChange(field.name)(text);
                  }}
                />
                <ErrorMessage
                  name={field.name}
                  render={(errorMessage: string) => (
                    <Text style={{ color: 'red', marginTop: 5 }}>
                      {errorMessage}
                    </Text>
                  )}
                />
              </View>
            )}
          </Field>
        );
    }
  }, []);

  const openPopupDish = useCallback(
    (dish: IDish) => {
      const alertTitle = `Do you want to add ${dish.name} to your booking?`;
      const alertMessage = `Name: ${dish.name}, Price: $${dish.price}`;
      Alert.alert(alertTitle, alertMessage, [
        {
          text: 'Yes',
          onPress: () => {
            addOrUpdateDish(dish);
            Alert.alert('Dish added to your booking!', '', [
              {
                text: 'Continue booking',
                onPress: () => {},
              },
            ]);
          },
        },
        {
          text: 'Cancel',
          onPress: () => {},
        },
      ]);
    },
    [addOrUpdateDish]
  );

  const timeSequence = useMemo(() => {
    let timeArray: any[] = generateTimeArray(
      {
        start: booking.start_time,
        end: booking.end_time,
      },
      15
    );

    return timeArray.map((time) => {
      return {
        label: time.label,
        value: time.value,
      };
    });
  }, [booking.start_time, booking.end_time]);

  const renderBookingTime = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          name: 'checkin_time',
          type: 'dropdown',
          label: 'Check In Time',
          items: timeSequence,
          titleDropdown: 'Select Time',
          placeholder: 'Select Time',
          isRequire: true,
          zIndex: 3,
        },
        {
          name: 'checkout_time',
          type: 'dropdown',
          label: 'Check Out Time',
          items: timeSequence,
          titleDropdown: 'Select Time',
          placeholder: 'Select Time',
          isRequire: true,
          zIndex: 2,
        },
      ],
    };
    return (
      <View
        style={{
          marginBottom: 10,
          zIndex: 3,
        }}
        onLayout={(event) => {
          const { layout } = event.nativeEvent;
          const { height, y } = layout;

          setBookingTimePosition({ y: y, height: height });
        }}
      >
        <Text style={styles.itemTitle}>Select Booking Time:</Text>
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          {structure.components.map((item) => {
            return renderForm(item);
          })}
        </View>
      </View>
    );
  }, [timeSequence, renderForm]);

  const renderSwiper = useCallback(() => {
    // const resul: React.JSX.Element[] = [];
    // for (let i = 0; i < products.length; i += 2) {}
    var allGroup: React.JSX.Element[] = [];
    var group: React.JSX.Element[] = [];
    dishes.map((dish, index) => {
      group.push(
        <View
          key={dish.id}
          style={{
            flex: 0.25,
            //minWidth: 250,
          }}
        >
          <ExtraDishItem item={dish} onAddToCart={openPopupDish} />
        </View>
      );
      if (
        ((index + 1) % 4 === 0 && index !== 0) ||
        index === dishes.length - 1
      ) {
        allGroup.push(
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              gap: 20,
            }}
          >
            {group}
          </View>
        );
        group = [];
      }
    });

    return (
      <Swiper
        controlsProps={{
          prevPos: false,
          nextPos: false,
          DotComponent: ({ index, isActive, onPress }) => (
            <>
              <View style={{ marginHorizontal: 2 }}>
                <View
                  style={[
                    {
                      height: 10,
                      borderRadius: 10,
                    },
                    isActive
                      ? {
                          width: 18,
                          backgroundColor: '#5E5D5D',
                        }
                      : {
                          width: 10,

                          backgroundColor: '#C9C9C9',
                        },
                  ]}
                ></View>
              </View>
            </>
          ),
          dotsTouchable: true,
        }}
        timeout={5}
        loop
        from={1}
        //ref={swiperRef}
      >
        {allGroup}
      </Swiper>
    );
  }, [dishes, openPopupDish]);

  const renderDinnerMeal = useMemo(() => {
    return (
      <View
        style={{
          marginBottom: 20,
          zIndex: 2,
        }}
        onLayout={(event) => {
          const { layout } = event.nativeEvent;
          const { height, y } = layout;

          setDinnerMealPosition({ y: y, height: height });
        }}
      >
        <Text style={styles.itemTitle}>Select Dinner Meal Option:</Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 20,
              marginRight: isDesktop() ? 20 : 10,
            }}
          >
            <View
              style={[styles.mealWrapper, { backgroundColor: '#1A94FF4F' }]}
            >
              <Text
                style={[
                  styles.mealText,
                  { color: '#1A94FF' },
                  !isDesktop() && { fontSize: 17 },
                ]}
              >
                Dinner
              </Text>
            </View>
            {isDesktop() && (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 16,
                  color: COLORS.GREY,
                }}
              >
                Select Meal Option:
              </Text>
            )}
          </View>
          {booking.dinner_meal && booking.dinner_meal.length > 0 && (
            <Field
              name={'dinner_meal'}
              validate={(value: any) => {
                let error;
                if (booking.dinner_meal.length > 0 && !value) {
                  error = 'Please select dinner meal';
                }

                return error;
              }}
            >
              {({ field, form }: FieldProps) => (
                <View>
                  <View
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    {booking.dinner_meal &&
                      booking.dinner_meal.map((item, index) => {
                        const zIndex = booking.dinner_meal.length - index;

                        return (
                          <View
                            style={[
                              styles.dinnerOption,
                              {
                                marginBottom: 15,
                                gap: isDesktop() ? 20 : 10,
                                zIndex: zIndex,
                              },
                            ]}
                          >
                            <TouchableOpacity
                              style={[
                                styles.checkbox,
                                field.value?.name === item.name &&
                                  styles.checkedCheckbox,
                              ]}
                              onPress={() => {
                                form.setFieldValue('dinner_meal', item);
                              }}
                            >
                              {field.value?.name === item.name && (
                                <Text style={styles.checkIcon}>✓</Text>
                              )}
                            </TouchableOpacity>
                            <View style={{ width: 180 }}>
                              <MealItem meal={item} />
                            </View>
                          </View>
                        );
                      })}
                  </View>
                  <ErrorMessage
                    name={field.name}
                    render={(errorMessage: string) => (
                      <Text style={{ color: 'red', marginTop: 5 }}>
                        {errorMessage}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Field>
          )}
        </View>
      </View>
    );
  }, [booking.dinner_meal, isDesktop]);

  const renderEmergencyContact = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          label: 'First Name',
          name: 'emer_first_name',
          type: 'text',
        },
        {
          label: 'Last Name',
          name: 'emer_last_name',
          type: 'text',
        },
        {
          label: 'Phone Number',
          name: 'emer_phone',
          type: 'mobilePhone',
          zIndex: 2,
        },
        {
          label: 'Relationship',
          name: 'emer_relationship',
          type: 'text',
        },
      ],
    };

    return (
      <View
        style={{
          marginBottom: 20,
        }}
        onLayout={(event) => {
          const { layout } = event.nativeEvent;
          const { height, y } = layout;

          setEmerPosition({ y: y, height: height });
        }}
      >
        <Text style={styles.itemTitle}>Add Emergency Contact</Text>
        <View
          style={[
            isDesktop()
              ? { flexDirection: 'row', flexWrap: 'wrap' }
              : { flexDirection: 'column' },
          ]}
        >
          {structure.components.map((item, index) => {
            return (
              <View
                style={{
                  width: isDesktop() ? '50%' : '100%',
                  flexDirection: 'row',
                  marginBottom: 5,
                }}
                key={index}
              >
                <Text style={[styles.contactText, { width: '40%' }]}>
                  {item.label}:
                </Text>
                <View style={{ width: '55%' }}>{renderForm(item)}</View>
              </View>
            );
          })}
        </View>
      </View>
    );
  }, [isDesktop, renderForm]);

  useEffect(() => {
    getDishes();
  }, [getDishes]);

  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 25,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      <Formik
        initialValues={initData}
        onSubmit={(formValues) => {
          const emerPhone = convertMobileInputData(formValues.emer_phone);
          const startDate = new Date(
            `${state.start_time} ${formValues.checkin_time}`
          );
          const endDate = new Date(
            `${state.end_time} ${formValues.checkout_time}`
          );

          if (endDate < startDate) {
            Alert.alert(
              'Error',
              'Please select an end date greater than or equal to the start date.'
            );
          } else {
            setState({
              start_time: formValues.checkin_time,
              end_time: formValues.checkout_time,
              dinner_meal: formValues.dinner_meal,
              is_vegetarian: Boolean(formValues.is_vegetarian),
              emergency_first_name: formValues.emer_first_name,
              emergency_last_name: formValues.emer_last_name,
              emergency_phone_number: emerPhone.number,
              emergency_phone_country: emerPhone.country,
              emergency_relationship: formValues.emer_relationship,
              extra_dishes: selectedDishes,
            });

            if (onSubmit) {
              onSubmit();
            }
          }
        }}
        validationSchema={yup.object().shape({
          // checkin_time: yup.string().required('Please select a check in time.'),
          // checkout_time: yup
          //   .string()
          //   .required('Please select a check out time.'),
          is_vegetarian: yup
            .string()
            .required('Please select are you vegetarian.'),
          emer_first_name: yup
            .string()
            .required('Please enter emergency first name.'),
          emer_last_name: yup
            .string()
            .required('Please enter emergency last name.'),
          emer_phone: yup
            .string()
            .required('Please enter emergency phone.')
            .test(
              'phone-length',
              'Please enter emergency with 8 digits',
              (value: string) => {
                const emerPhone = convertMobileInputData(value);

                return emerPhone.number.length == 8;
              }
            ),
          emer_relationship: yup
            .string()
            .required('Please enter emergency relationship.'),
        })}
      >
        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => {
          {
            /* Disable on website */
          }
          // useEffect(() => {
          //   const errorKeys = Object.keys(errors);
          //   if (isSubmitting) {
          //     if (
          //       ['checkin_time', 'checkout_time'].some((key) =>
          //         errorKeys.includes(key)
          //       )
          //     ) {
          //       handleScroll(bookingTimePosition);
          //     } else if (
          //       ['dinner_meal'].some((key) => errorKeys.includes(key))
          //     ) {
          //       handleScroll(dinnerMealPosition);
          //     } else if (
          //       ['is_vegetarian'].some((key) => errorKeys.includes(key))
          //     ) {
          //       handleScroll(vegetarianPosition);
          //     } else if (
          //       [
          //         'emer_first_name',
          //         'emer_last_name',
          //         'emer_phone',
          //         'emer_relationship',
          //       ].some((key) => errorKeys.includes(key))
          //     ) {
          //       handleScroll(emerPosition);
          //     }
          //   }
          // }, [errors, isSubmitting]);

          return (
            <>
              <DatetimeInfo state={state} booking={booking} />
              {booking.room_availability && (
                <RoomInfor state={state} booking={booking} />
              )}
              {booking.included_meal &&
                (booking.included_meal.breakfast ||
                  booking.included_meal.lunch) && (
                  <View
                    style={{
                      marginBottom: 10,
                      zIndex: 3,
                    }}
                  >
                    <Text style={styles.itemTitle}>
                      Included Standard Meals:
                    </Text>
                    <View
                      style={{
                        flexDirection: 'column',
                      }}
                    >
                      {booking.included_meal.breakfast && (
                        <InCludedMealItem
                          name={'Breakfast'}
                          meal={booking.included_meal.breakfast}
                          mealWrapperStyle={{ backgroundColor: '#FFF5C7' }}
                          mealTextStyle={{ color: '#CC8100' }}
                          zIndex={2}
                        />
                      )}
                      {booking.included_meal.lunch && (
                        <InCludedMealItem
                          name={'Lunch'}
                          meal={booking.included_meal.lunch}
                          mealWrapperStyle={{ backgroundColor: '#D7FAE0' }}
                          mealTextStyle={{ color: '#007D3A' }}
                          zIndex={1}
                        />
                      )}
                    </View>
                  </View>
                )}
              {booking.dinner_meal &&
                booking.dinner_meal.length > 0 &&
                renderDinnerMeal}
              {isLoadingDishes === false && dishes.length !== 0 && (
                <View
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Text style={styles.itemTitle}>Select Extra Dishes</Text>
                  {isDesktop() ? (
                    <View
                      style={{
                        height: 400,
                      }}
                    >
                      {renderSwiper()}
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      {dishes.map((dish, index) => {
                        return (
                          <View
                            style={{
                              width: '48%',
                            }}
                          >
                            <ExtraDishItem
                              item={dish}
                              onAddToCart={openPopupDish}
                            />
                          </View>
                        );
                      })}
                    </View>
                  )}
                </View>
              )}
              {isLoadingDishes && (
                <View style={{ marginVertical: 10 }}>
                  <Loading />
                </View>
              )}

              <Field name={'is_vegetarian'}>
                {({ field, form }: FieldProps) => (
                  <View
                    onLayout={(event) => {
                      const { layout } = event.nativeEvent;
                      const { height, y } = layout;

                      setVegetarianPosition({ y: y, height: height });
                    }}
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <Text style={styles.itemTitle}>Are you a vegetarian?</Text>
                    <RadioButton
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                        },
                        {
                          label: 'No',
                          value: false,
                        },
                      ]}
                      selectedOption={field.value}
                      onSelect={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      optionStyle={{ marginRight: isDesktop() ? 200 : 90 }}
                    />
                    <ErrorMessage
                      name={field.name}
                      render={(errorMessage: string) => (
                        <Text style={{ color: 'red', marginTop: 5 }}>
                          {errorMessage}
                        </Text>
                      )}
                    />
                  </View>
                )}
              </Field>
              {renderEmergencyContact}
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <Button
                  style={[styles.buttonStyle, !isDesktop() && { width: '48%' }]}
                  className=""
                  arrowRight={false}
                  textStyle={[
                    styles.textButtonStyle,
                    !isDesktop() && { fontSize: 14 },
                  ]}
                  text="Previous Step"
                  onPress={() => {
                    handleSetStep(1);
                  }}
                />
                <Button
                  style={[styles.buttonStyle, !isDesktop() && { width: '48%' }]}
                  className=""
                  arrowRight={false}
                  textStyle={[
                    styles.textButtonStyle,
                    !isDesktop() && { fontSize: 14 },
                  ]}
                  text="Next Step"
                  onPress={() => {
                    handleSubmit();
                  }}
                />
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};
export default Step2;

const styles = StyleSheet.create({
  itemTitle: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.LIGHT_BLACK,
    letterSpacing: 1,
    marginBottom: 20,
  },
  mealContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  mealWrapper: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 16,
  },
  mealText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 20,

    lineHeight: 31,
  },
  typeMealWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 25,
    paddingVertical: 6,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderRadius: 16,
    gap: 10,
  },
  typeMealText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.LIGHT_GREY,
  },
  checkedCheckbox: {
    backgroundColor: COLORS.RED_PRIMARY,
  },
  checkIcon: {
    color: '#fff',
  },
  dinnerOption: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  contactText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  contactInput: {
    marginBottom: 20,
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    paddingHorizontal: 2,
    letterSpacing: 1.2,
    borderRadius: 4,
    fontSize: 16,
    paddingVertical: 5,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
