import { FC, useCallback, useMemo } from 'react';
import React from 'react';
import {
  FormComponent,
  FormStructure,
} from '../../../components/FormHelper/FormHelper.types';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { TextInput } from '../../../components/TextInput/TextInput';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Button } from '../../../components/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import * as yup from 'yup';
import { IEditProfileProps } from '../types';

const MyDetailForm: FC<IEditProfileProps> = ({ onSubmitHandler }) => {
  const isDesktop = useIsDesktop();
  const { user } = useSelector((redux: RootState) => redux.authReducer);

  const initData = useMemo(() => {
    return {
      username: user?.username,
      current_password: '',
      password: '',
      password_confirmation: '',
    };
  }, [user]);

  const editDetailStructure = useMemo(() => {
    const structure: FormStructure = {
      components: [
        {
          label: 'Username',
          name: 'username',
          type: 'text',
          placeholder: 'Username',
        },
        {
          label: 'Current Password',
          name: 'current_password',
          type: 'text',
          placeholder: 'Enter Current Password',
          secureTextEntry: true,
        },
        {
          label: 'New Password',
          name: 'password',
          type: 'text',
          placeholder: 'Enter New Password',
          secureTextEntry: true,
        },
        {
          label: 'Re-Enter New Password',
          name: 'password_confirmation',
          type: 'text',
          placeholder: 'Re-Enter New Password',
          secureTextEntry: true,
        },
      ],
    };

    return structure;
  }, []);

  const renderForm = useCallback((component: FormComponent) => {
    return (
      <Field name={component.name}>
        {({ field }: FieldProps) => (
          <View
            style={{
              width: '100%',
            }}
          >
            <TextInput
              style={{
                width: '100%',
                borderWidth: 1,
                borderColor: '#DDDDE3',
                borderStyle: 'solid',
                padding: 12,
                letterSpacing: 1.2,
                borderRadius: 6,
                fontSize: 16,
                backgroundColor: component.disabled
                  ? COLORS.LIGHT_GREY
                  : 'white',
                fontFamily: FONTS.AvenirMedium,
                color: COLORS.BLACK,
              }}
              value={field.value}
              onChangeText={(text) => {
                field.onChange(field.name)(text);
              }}
              placeholder={component.placeholder}
              placeholderTextColor={COLORS.GREY}
              editable={!component.disabled}
              secureTextEntry={component.secureTextEntry}
            />
            <ErrorMessage
              name={field.name}
              render={(errorMessage: string) => (
                <Text style={{ color: 'red', marginTop: 5 }}>
                  {errorMessage}
                </Text>
              )}
            />
          </View>
        )}
      </Field>
    );
  }, []);

  return (
    <View
      style={{
        backgroundColor: COLORS.LIGHT_RED,
        padding: isDesktop() ? 25 : 0,
        width: '100%',
        borderRadius: 18,
        flexDirection: 'column',
        marginBottom: 25,
      }}
    >
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 18,
          letterSpacing: 1.5,
          textTransform: 'uppercase',
          marginBottom: 15,
          color: COLORS.BLACK,
        }}
      >
        My Details
      </Text>
      {isDesktop() && (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 20,
          }}
        >
          <View
            style={{
              width: '50%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '40%' }}>
              <Text style={styles.contentText}>Username:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.contentText}>{user?.username}</Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '40%' }}>
              <Text style={styles.contentText}>Password:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.contentText}>************</Text>
            </View>
          </View>
        </View>
      )}

      <Formik
        initialValues={initData}
        onSubmit={(value) => {
          if (onSubmitHandler) {
            onSubmitHandler(value);
          }
        }}
        validationSchema={yup.object().shape({
          username: yup.string().required('Please enter user name.'),
          current_password: yup
            .string()
            .test(
              'current-password-required',
              'The current password field is required when password is present.',
              function (value) {
                const { password } = this.parent;
                return !password || !!value;
              }
            ),
          password: yup
            .string()
            .matches(
              /^(?=.*[A-Z])(?=.*[0-9!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,}$/,
              'Password must be at least 8 characters long, contain at least one uppercase letter, and include a number or special character.'
            ),
          password_confirmation: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords must match'),
        })}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {editDetailStructure.components.map((component) => (
                <View
                  style={[
                    {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginBottom: 20,
                      zIndex: component.zIndex ? component.zIndex : 0,
                    },
                    isDesktop()
                      ? {
                          width: '48%',
                        }
                      : {
                          width: '100%',
                        },
                  ]}
                >
                  {renderForm(component)}
                </View>
              ))}
            </View>
            <Button
              style={{
                backgroundColor: COLORS.RED_PRIMARY,
                paddingVertical: 15,
                borderRadius: 16,
                width: isDesktop() ? 270 : '100%',
                overflow: 'hidden',
              }}
              className=""
              textStyle={{
                color: COLORS.WHITE,
                fontSize: 19,
                fontWeight: '800',
                fontFamily: FONTS.AvenirMedium,
                textTransform: 'uppercase',
              }}
              text="Save"
              arrowRight={false}
              onPress={() => {
                handleSubmit();
              }}
            />
          </>
        )}
      </Formik>
    </View>
  );
};

export default MyDetailForm;

const styles = StyleSheet.create({
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
