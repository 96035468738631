import { Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { CalendarProps } from './Calendar.types';
import { styles } from './Calendar.styled';
import { Day } from './components';
import { Image } from '../Image';
import dayjs from 'dayjs';
import { COLORS } from '../../constants';

const Calendar: FC<CalendarProps> = ({
  initDate,
  onChangeDate,
  setShowCalendar,
  showCalendar = false,
  onCancel,
}) => {
  const [selectedDate, setSelectedDate] = useState(initDate);

  const [currentDate, setCurrentDate] = useState<Date>(
    new Date(initDate || dayjs(new Date()).format('YYYY-MM-DD'))
  );
  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    return lastDay;
  };

  const handleDateSelect = useCallback(
    (date: string) => {
      // console.log(date);
      setSelectedDate(date);
      if (setShowCalendar) setShowCalendar(!showCalendar);
      if (onChangeDate) onChangeDate(date);
    },
    [onChangeDate, setShowCalendar, showCalendar]
  );

  const renderCalendar = useMemo(() => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const startingDayOfWeek = firstDayOfMonth.getDay();

    const calendarDays = [];
    let weekDays = [];

    for (let i = 0; i < startingDayOfWeek; i++) {
      weekDays.push(<Day emptyDate key={`empty-day-${i}`} />);
    }

    for (let i = 1; i <= 7 - startingDayOfWeek; i++) {
      weekDays.push(
        <Day
          key={`calendar-day-${i}`}
          onPress={handleDateSelect}
          date={i}
          initDate={dayjs(selectedDate).format('YYYY-MM-DD')}
          current={currentDate}
        />
      );
    }
    calendarDays.push(
      <View style={styles.calendarRow} key={`row-${0}`}>
        {weekDays}
      </View>
    );
    weekDays = [];
    let count = 0;
    for (let i = 7 - startingDayOfWeek + 1; i <= daysInMonth; i++) {
      weekDays.push(
        <Day
          key={`calendar-day-${i}`}
          onPress={handleDateSelect}
          date={i}
          current={currentDate}
          initDate={dayjs(selectedDate).format('YYYY-MM-DD')}
        />
      );
      count++;
      if (count === 7) {
        calendarDays.push(
          <View style={styles.calendarRow} key={`row-${i}`}>
            {weekDays}
          </View>
        );
        weekDays = [];
        count = 0;
      }
    }
    if (weekDays.length > 0) {
      const length = 7 - weekDays.length;
      for (let i = 0; i < length; i++) {
        weekDays.push(<Day emptyDate key={`empty-day-${i}-end`} />);
      }
      calendarDays.push(
        <View style={styles.calendarRow} key={`row-end`}>
          {weekDays}
        </View>
      );
    }
    return <View style={styles.calendarContainer}>{calendarDays}</View>;
  }, [currentDate, selectedDate, handleDateSelect]);

  const handlePreviousMonth = () => {
    const previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1
    );
    setCurrentDate(previousMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1
    );
    setCurrentDate(nextMonth);
  };

  const handlePreviousYear = () => {
    const previousYear = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth()
    );
    setCurrentDate(previousYear);
  };

  const handleNextYear = () => {
    const nextYear = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth()
    );
    setCurrentDate(nextYear);
  };

  const daysOfWeek = useMemo(() => {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }, []);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        onPress={() => {
          if (setShowCalendar) {
            setShowCalendar(false);
          }
        }}
      >
        <Image
          style={{
            width: 18,
            height: 18,
            tintColor: COLORS.BLACK,
          }}
          source={require('../../assets/close-icon.png')}
        />
      </TouchableOpacity>
      <View style={styles.selectWrapper}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 3,
          }}
        >
          <TouchableOpacity
            style={[styles.arrowButton]}
            onPress={handlePreviousYear}
          >
            <Image
              source={require('../../assets/left-arrow.png')}
              style={styles.arrowStyle}
            />
          </TouchableOpacity>
          <Text style={styles.currentYear}>
            {dayjs(currentDate).format('YYYY')}
          </Text>
          <TouchableOpacity style={styles.arrowButton} onPress={handleNextYear}>
            <Image
              source={require('../../assets/right-arrow.png')}
              style={styles.arrowStyle}
            />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
            style={[styles.arrowButton]}
            onPress={handlePreviousMonth}
          >
            <Image
              source={require('../../assets/left-arrow.png')}
              style={styles.arrowStyle}
            />
          </TouchableOpacity>
          <Text style={styles.currentDate}>
            {dayjs(currentDate).format('MMMM')}
          </Text>
          <TouchableOpacity
            style={styles.arrowButton}
            onPress={handleNextMonth}
          >
            <Image
              source={require('../../assets/right-arrow.png')}
              style={styles.arrowStyle}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{ flexDirection: 'row', paddingVertical: 10, width: '100%' }}
      >
        {daysOfWeek.map((day) => (
          <View style={{ flex: 1 }} key={day}>
            <Text
              style={[
                styles.dayTitle,
                (day == 'Sun' || day == 'Sat') && { color: '#F36A6A' },
              ]}
            >
              {day}
            </Text>
          </View>
        ))}
      </View>
      <View style={{ width: '100%' }}>{renderCalendar}</View>
    </View>
  );
};

export default Calendar;
