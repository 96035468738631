import React, { FC } from 'react';
import { Image, View, useWindowDimensions, ScrollView } from 'react-native';
import { Notices } from './components/Notices';
import { Text } from '../../components/Text';
import { Footer } from '../../components/Footer';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { CorporateEvent } from './components/CorporateEvent';
import { Activities } from './components/Activities';
import { User } from './components/User';
import { BookACamp } from './components/BookACamp';
import { MAX_WIDTH } from '../../constants/platform';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux';

const Home: FC = () => {
  const { user, loggedin } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const { width: screenWidth } = useWindowDimensions();
  const isDesktop = useIsDesktop();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      height: '100%',
      width: '76%',
      resizeMode: 'cover',
      justifyContent: 'flex-start',
    },
    backgroundImage: {
      width: isDesktop() ? undefined : screenWidth,
      height: (isDesktop() ? 550 : screenWidth) * (1346 / 1796),
    },
  });

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            position: 'relative',
          }}
        >
          <Image
            source={require('../../assets/home-page-banner.jpeg')}
            style={{
              height: 190,
              resizeMode: 'cover',
            }}
          />
        </View>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            marginTop: -140,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
              <User />
              <View
                style={{
                  height: 1,
                  backgroundColor: '#EBEBF0',
                  marginBottom: 20,
                }}
              ></View>
              {loggedin && <CorporateEvent />}
            </View>
            <View style={{ width: '75%' }}>
              <Text
                style={{
                  textTransform: 'uppercase',
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 26,
                  letterSpacing: 2,
                  color: COLORS.BLACK,
                  textAlign: 'center',
                  marginBottom: 70,
                }}
              >
                BOOK A CAMP AT HELPING HAND HOLIDAY CENTER
              </Text>
              <BookACamp />
              <Notices />
              <Activities />
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }
  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            position: 'relative',
          }}
        >
          <Image
            source={require('../../assets/home-page-banner.jpeg')}
            style={{
              width: '100%',
              height: 200,
            }}
          />
          <Text
            style={{
              textTransform: 'uppercase',
              fontFamily: FONTS.AvenirMedium,
              fontSize: 20,
              letterSpacing: 1,
              color: COLORS.BLACK,
              textAlign: 'center',
              fontWeight: '700',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 40,
            }}
          >
            {'BOOK A CAMP AT HELPING \nHAND HOLIDAY CENTRE'}
          </Text>
        </View>
        <View
          style={[
            {
              paddingLeft: 20,
              paddingRight: 20,
              flexDirection: 'row',
            },
            isDesktop() && {
              maxWidth: MAX_WIDTH,
              width: '100%',
              alignSelf: 'center',
            },
          ]}
        >
          <View style={styles.container}>
            <User />
            <Notices />
          </View>
        </View>
        <View>
          <BookACamp />
          <Activities />
        </View>
        <Footer />
      </ScrollView>
    </>
  );
};

export default Home;
